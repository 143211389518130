import styled from "styled-components";

export const JSMobileTabsContainer = styled.div ``;

export const JSTabs = styled.div `
  /* background-color: #d8d8d8; */
  border: none;
  outline: none;
  cursor: pointer;
  padding: 11px 15px;
  transition: 0.3s;
  width: 50%;
  border-top-left-radius: ${({ LeftRadius }) => (LeftRadius ? "20px" : "0px")};
  border-top-right-radius: ${({ RightRadius }) =>
    RightRadius ? "20px" : "0px"};
  align-self: center;
`;

export const JSTabsTitle = styled.h5 `
  font-size: 17px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 0px;
`;

export const CountContainer = styled.div ``;

export const ContsDiv = styled.div `
  border: 1px solid #dfdfdf;
  border-radius: 25px;
  padding: 5px 10px;
  width: 150px;
`;

export const JobsCount = styled.p `
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 0px;
  color: ${({ theme }) => (theme ? "#0073db" : "#000")};
`;

export const TableHead = styled.div `
  background-color: #dfdfdf;
  align-items: center;
`;

export const TableHeadTitle = styled.h6 `
  color: #000;
  margin: 0;
  font-weight: 700;
  font-size: medium;
`;

export const TableContent = styled.p `
  color: ${({ orgcolor}) => (orgcolor ? "#FFA500" : "#000")};
  margin: 0;
  font-weight: 700;
  font-size: 15px;
  text-decoration: ${({ Decoration }) => (Decoration ? "underline" : "none")};;
`;