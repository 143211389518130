import React from "react";
import { LogoSize, Logolink, Dropdown, DropdownContent, DropBtn, Menus, Icons, DIcons } from './userheader.style';
import Logo from "../../../assets/brand/logo.svg";
import { ChevronDown } from 'react-feather'
import ProfileName from "../../../assets/header/dropdown-icon/profile-icn.png";
import Profile from "../../../assets/header/drpdown-icon/Group 2134.svg";
import JobSearch from "../../../assets/header/drpdown-icon/Group 2135.svg";
import OurService from "../../../assets/header/drpdown-icon/Group 2140.svg";
import CV from "../../../assets/header/drpdown-icon/Group 2138.svg";
import PlatinumMem from "../../../assets/header/drpdown-icon/Group 2136.svg";
import Logout from "../../../assets/header/drpdown-icon/Group 2139.svg";

const Header = () => {
  return (

    <nav className=" d-md-block navbar navbar-expand-md navbar-light bg-white">
      <div className="container align-items-center justify-content-between">
        <div className="col-md-3">
          <Logolink className="navbar-brand" to="/">
            <LogoSize src={Logo} alt="" />
          </Logolink>
        </div>

        {/* <select value="Shreyash">
          <option value="Shreyash">Shreyash</option>
          <option value="Job Search">Job Search</option>
          <option value="Our Service">Our Service</option>
          <option value="CV Development">CV Development</option>
          <option value="Platinum Membership">Platinum Membership</option>
          <option value="Log Out">Log Out</option>
        </select> */}

        <Dropdown>
          <DropBtn>
            <Icons src={ProfileName} alt="" />
            Shreyash
            <ChevronDown color="#0073db" size={25} strokeWidth="2" style={{ marginLeft: '10px' }} />
          </DropBtn>
          <DropdownContent>
            <Menus to="#"><DIcons src={Profile} alt="" />Job Search</Menus>
            <Menus to="#"><DIcons src={JobSearch} alt="" />Job Search</Menus>
            <Menus to="/ourservice"><DIcons src={OurService} alt="" />Our Service</Menus>
            <Menus to="#"><DIcons src={CV} alt="" />CV Development</Menus>
            <Menus to="#"><DIcons src={PlatinumMem} alt="" />Platinum Membership</Menus>
            <Menus Borderb to="#"><DIcons src={Logout} alt="" />Log Out</Menus>
          </DropdownContent>
        </Dropdown>


      </div>
    </nav>
  );
};

export default Header;
