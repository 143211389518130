/* eslint-disable array-callback-return */
import React, { Component, forwardRef } from 'react';
import MaterialTable from 'material-table';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import MockData from './data.json';
import './table.css'

export default class Table extends Component {


  render() {
    const tableIcons = {
      Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
      Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
      Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
      Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
      DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
      Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
      Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
      Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
      FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
      LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
      NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
      PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
      ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
      Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
      SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
      ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
      ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
    };

    let a = [    ]
    
    return (
      <div style={{ maxWidth: '100%' }}>
        {MockData.map(value => {
          let b = {
            jobTitle: value.jobTitle,
            industry: value.industry,
            jobPostedOn: value.jobPostedOn,
            location: value.location,
            empType: value.empType,
            salary: value.salary
          }
          a.push(b);
        })}

        
        
        <MaterialTable
          title=" "
          data={a}
          icons={tableIcons}
          options={{
            sorting: false,
            search: false,
            rowStyle: {
              backgroundColor: '#f8f9fa',
              borderBottom: '1px solid #f8f9fa',
            },
            headerStyle: {
              backgroundColor: '#0073DB',
              color: '#FFF',
              textAlign: 'left',
              fontWeight: '700',
              fontSize: '14px'
            }
          }}

          columns={[
            {
              title: 'Job Title', field: 'jobTitle',
              cellStyle: {
                textAlign: 'left',
              },
            },
            {
              title: 'Industry', field: 'industry',
              cellStyle: {
                textAlign: 'left'
              },
            },
            {
              title: 'Job Posted On', field: 'jobPostedOn', type: 'numeric',

              cellStyle: {
                textAlign: 'left'
              },
            },
            {
              title: 'Location', field: 'location',
              cellStyle: {
                textAlign: 'left'
              },
            },
            {
              title: 'Employee Type', field: 'empType',
              cellStyle: {
                textAlign: 'left'
              },
            },
            {
              title: 'Salary', field: 'salary', type: 'numeric',
              cellStyle: {
                textAlign: 'left'
              },
            },

          ]}

        />
      </div>
    )
  }
}



// ReactDOM.render(<App />, document.getElementById('react-div'));