import { Link } from "react-router-dom";
import styled from "styled-components";
import { color } from "../../../../styles/variable.styles";

export const FormDiv = styled.div `
  padding: 48px;
  @media (max-width: 1024px) {
    padding: 25px 12px;
    width: 100%;
  }
`;

export const BtnContainer = styled.div `
  margin-top: 75px;
  margin-bottom: 30px;
  @media (max-width: 426px) {
    margin-top: 30px;
    margin-bottom: 30px;
  }
`;
export const BasicButton = styled(Link)
`
  border: 1px solid ${color.theme};
  background-color: ${color.regularfontwhite};
  color: ${color.theme};
  border-radius: 50px;
  padding: 6px 0px;
  font-size: 13px;
  font-weight: 500;
  margin: 15px 5px;
  transition: 0.3s;
  &:hover {
    background-color: ${color.theme};
    color: ${color.regularfontwhite};
  }
`;
export const Logo = styled.img `
  width: 50px;
  margin-right: 0px;
  z-index: 999;
  border-bottom: 1px solid #bfbfbf;
`;
export const Text = styled.p `
  font-size: 16px;
  font-weight: 600;
  margin-top: 15px;
  text-transform: uppercase;
`;

export const GoldButton = styled(Link)
`
  background-color: ${color.regularfontwhite};
  border: 1px solid ${color.goldButton};
  color: ${color.goldButton};
  border-radius: 50px;
  padding: 6px 0px;
  font-size: 13px;
  font-weight: 500;
  margin: 15px 5px;
  transition: 0.3s;
  &:hover {
    background-color: ${color.goldButton};
    color: ${color.regularfontwhite};
  }
`;

export const PlatinumButton = styled(Link)
`
  background-color: ${color.regularfontwhite};
  border: 1px solid ${color.platinumButton};
  color: ${color.platinumButton};
  border-radius: 50px;
  padding: 6px 0px;
  font-size: 13px;
  font-weight: 500;
  margin: 15px 5px;
  transition: 0.3s;
  &:hover {
    background-color: ${color.platinumButton};
    color: ${color.regularfontwhite};
  }
`;