import styled from "styled-components";
import { color } from "../../../../styles/variable.styles";



export const TextDiv = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`;

export const FormDiv = styled.div `
  padding: 48px;
  @media (max-width: 1024px) {
    padding: 25px 12px;
    width: 100%;
  }
`;


export const TextBox = styled.input `
  border: 0;
  border-bottom: 1px solid #ced4da !important;
  border-radius: 0;
  width: 100%;
  font-weight: 600;
  padding: 7px 7px;
  &:focus {
    outline: none !important;
    border-color: #fff;
    box-shadow: none;
  }
`;

export const RadioSpan = styled.span `
  font-size: 14px;
  font-weight: 400;
`;

export const RadioButton = styled.input ``;

export const CheckboxTermCondspan = styled.span `
  font-size: 0.875em;
  font-weight: 400;
  margin: 10px 0;
`;

export const Buttons = styled.button `
  border: 1px solid ${color.theme} !important;
  background-color: ${color.theme};
  color: ${color.regularfontwhite};
  border-radius: 50px;
  text-transform: uppercase;
  padding: 0.5rem 1.5rem;
  font-size: 13px;
  font-weight: 500;
  margin: 10px 0;
  transition: 0.3s;
  border: none;
`;

export const ButtonDiv = styled.div `
  display: flex;
`;