import styled from "styled-components";
import { color } from "../../../styles/variable.styles";

export const OfferCardDiv = styled.div `
margin-bottom: 60px;
`;

export const OfferCardBody = styled.div `
  border: 1px solid rgba(112, 112, 112, 0.3);
  border-radius: 20px;
  overflow: hidden;
  padding: 0;
`;

export const OfferCardImg = styled.div `
  padding: 0 0;
  max-height: 100%;
  align-items: center;
`;

export const OfferCardImgSize = styled.img `
  width: -webkit-fill-available;
`;
export const OfferCardTextArea = styled.div `
  margin: auto;
  padding: 60px 50px;
  @media (max-width: 1200px) {
    padding: 50px 60px;
  }
  @media (max-width: 1024px) {
    padding: 50px 30px;
  }
  @media (max-width: 769px) {
    padding: 50px 15px;
  }
  @media (max-width: 500px) {
    padding: 20px 15px;
  }
`;

export const OfferCardTitle = styled.h3 `
  font-size: 40px;
  font-weight: 900;
  letter-spacing: 0.5px;
  line-height: 1.2;
  margin-left: 10px;
  text-transform: uppercase;
  @media (max-width: 960px) {
    font-size: 32px;
  }
`;

export const OffersUlList = styled.ul `
  margin: 0px 0 30px 0;
  padding: 0px 10px;
`;

export const OffersLiList = styled.li `
  font-size: 16px;
  letter-spacing: 0.4px;
  font-weight: 600;
  color: ${color.theme};
  padding: 3px 0;
  line-height: 1.5;
  text-align: justify;
`;

export const OfferCardRegisterButton = styled.button `
  border: 1px solid ${color.theme};
  background-color: ${color.theme};
  color: ${color.regularfontwhite};
  border-radius: 50px;
  text-transform: uppercase;
  padding: 10px 18px;
  font-size: 13px;
  font-weight: 500;
  margin: 10px 0;
  transition: 0.3s;
  &:hover {
    background-color: ${color.regularfontwhite};
  color: ${color.theme};
  }
`;