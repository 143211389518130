import React from "react";
import { Container } from "react-bootstrap";
import { OfferCardDiv, OfferCardBody, OfferCardImg, OfferCardImgSize, OfferCardTextArea, OfferCardTitle, OffersUlList, OffersLiList, OfferCardRegisterButton } from "./JobOfferCard.style";
import Offercard from '../../../assets/body/offer-card/1.png'

const JobOfferCard = () => {
  return (
    <div>
      <Container>
        <OfferCardDiv className="row p-lg-2 p-2">
            <OfferCardBody className="d-flex flex-wrap">
                <OfferCardImg className="col-12 col-md-6">
                    <OfferCardImgSize src={Offercard} alt="" />
                </OfferCardImg>
                <OfferCardTextArea className="col col-md-6">
                    <OfferCardTitle>Job Enhancer <br/> Offers You To</OfferCardTitle>
                    <OffersUlList>
                      <OffersLiList>Use our expertise to optimise your job search.</OffersLiList>
                      <OffersLiList>Find jobs that are otherwise difficult to find.</OffersLiList>
                      <OffersLiList>Make your job search convenient using our tools.</OffersLiList>
                      <OffersLiList>Multiple job channels monitored daily to get you the best of what is available.</OffersLiList>
                      <OffersLiList>Expert career advice to get you the edge you need.</OffersLiList>
                    </OffersUlList>
                    <OfferCardRegisterButton>Register Now</OfferCardRegisterButton>
                </OfferCardTextArea>
            </OfferCardBody>
        </OfferCardDiv>
      </Container>
    </div>
  );
};

export default JobOfferCard;
