import styled from "styled-components";

export const AbBackgroundColor = styled.div `
  background-color: #e7f3fe;
`;

export const MainContainer = styled.div `
  padding: 15px;
  margin-bottom: 60px;
`;
export const TextContainer = styled.div ``;

export const TextDiv = styled.div `
  width: 100%;
`;

export const Title = styled.h1 `
  font-size: 24px;
  font-weight: 900;
  text-transform: uppercase;
`;

export const Paragraph = styled.p `
    font-size: 16px;
    font-weight: 400;
    text-align: justify;
    margin-right: 20px;
`;
export const ImgContainer = styled.div ``;

export const AbImage = styled.img `
width: 100%;
`;