import React from 'react';
import { CardWidth, Card, CradBody, CardTitle, CardJobData, CardDataTable, CardDataTableBody, CardDataTableTr, CardDataTableTd, CardButton} from './card.style'


const card = () => {
    return (
        <div>
            <CardWidth>
                <Card>
                    <CradBody>
                        <CardTitle>
                            Sales & Business Developer Director
                        </CardTitle>
                        <CardJobData>
                            <CardDataTable>
                                <CardDataTableBody>
                                    <CardDataTableTr>
                                        <CardDataTableTd>
                                            Career Level
                                        </CardDataTableTd>
                                        <CardDataTableTd>
                                            :
                                        </CardDataTableTd>
                                        <CardDataTableTd orgcolor>
                                            Specialists & Professionals
                                        </CardDataTableTd>
                                    </CardDataTableTr>

                                    <CardDataTableTr>
                                        <CardDataTableTd>
                                            Location
                                        </CardDataTableTd>
                                        <CardDataTableTd>
                                            :
                                        </CardDataTableTd>
                                        <CardDataTableTd orgcolor>
                                            Mumbai
                                        </CardDataTableTd>
                                    </CardDataTableTr>

                                    <CardDataTableTr>
                                        <CardDataTableTd>
                                            Salary
                                        </CardDataTableTd>
                                        <CardDataTableTd>
                                            :
                                        </CardDataTableTd>
                                        <CardDataTableTd orgcolor>
                                            Available After Registration
                                        </CardDataTableTd>
                                    </CardDataTableTr>

                                </CardDataTableBody>
                            </CardDataTable>
                            <CardButton>Apply Now</CardButton>

                        </CardJobData>
                    </CradBody>
                </Card>
            </CardWidth>

        </div>
    )
}

export default card
