import React, { useState } from 'react'
import UserHeader from '../Header/UserHeader/userheader';
import Firstdiv from '../basicJobDetail/firstdivflex/firstdiv';
import {
    SerachBarMobile,
    FindJobBtn,
    MobileViewSerchBox,
    MobileFullScreen,
    InputHolderJobTitle,
    InputHolderJobLocation,
    ButtonHolder,
    DeskSearchBox,
    RefineBy,
    Categories,
    Title,
    SideBar,
    ResultSection,
    TitleContainer,
    IMGContainer,
    IMG,
    SearchNotFoundText,
    RefineByMobile,
    FilterButton,
    FilterButtonText,
    MobileFilter,
    FlexForTitle,
    Closesign,
    TabNameContainer,
    TabsContainer,
    TabName,
    SearchBar
} from './SearchNotFound.style'
import { Container } from 'react-bootstrap'
import '../../styles/scrollbar.css'
import serchnotfound from '../../assets/body/search-not-found/search-not-found.svg'
import { X, Search } from "react-feather";
import '../basicUserDashboard/tabs.css'


const SearchNotFound = () => {
    const [opensearchBox, setopensearchBox] = useState(false);
    const [openFilter, setopenFilter] = useState(false);

    const changeBtn = () => {
        // console.log('hiii');
        setopensearchBox(!opensearchBox)
    }
    const FilterBtn = () => {
        // console.log('hiii');
        setopenFilter(!openFilter)
    }
    const [toggleState, setToggleState] = useState(1);

    const toggleTab = (index) => {
        setToggleState(index);
    };
    return (
        <>
            <UserHeader />
            <SerachBarMobile>
                <div
                    className="p-0"
                    id="mobile"
                    onClick={changeBtn}
                >
                    <div>
                        <FindJobBtn>Find Jobs</FindJobBtn>
                    </div>
                </div>
                {opensearchBox ? (
                    <MobileViewSerchBox>
                        <MobileFullScreen className="col-md-6">
                            <div className="d-md-flex flex-column jutsity-content-center align-items-center text-white contentright">
                                <div className="col-md">

                                    <div id="cross" className="mt-4" style={{ textAlign: 'right', float: 'none', color: 'white', paddingRight: '10px' }}>
                                        <X onClick={changeBtn} width="30" />
                                    </div>

                                    <div className="m-4">
                                        <div>
                                            <form method="POST" action="">
                                                <div className="d-md-flex flex-wrap justify-content-center text-center">
                                                    <div className="col">
                                                        <div className="d-flex justify-content-left">
                                                            <label
                                                                className="fw-bold txt-left mb-1"
                                                                >
                                                                Search By Keywords
                                                            </label>
                                                        </div>

                                                        <InputHolderJobTitle
                                                            type="text"
                                                            className="d-md-flex form-control"
                                                            placeholder="Manager"
                                                            required
                                                        />
                                                    </div>

                                                    <div className="col">
                                                        <div className="d-flex justify-content-left">
                                                            <label
                                                                className="txt-right fw-bold mb-1"
                                                                >
                                                                Job Location
                                                            </label>
                                                        </div>
                                                        <InputHolderJobLocation
                                                            type="text"
                                                            className="d-md-flex form-control"
                                                            placeholder="Mumbai"
                                                            required
                                                        />
                                                    </div>
                                                    <div className="">
                                                        <div className="d-none d-md-block">
                                                            <div className="d-flex justify-content-left mb-1">
                                                                <label>&nbsp;</label>
                                                            </div>
                                                        </div>
                                                        <ButtonHolder
                                                            type="submit"
                                                            className="d-md-block my-4 my-md-0 job-search-btn job-btn-padding bar-find-btn basic-padding">
                                                            Find Jobs
                                                        </ButtonHolder>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>



                                </div>
                            </div>
                        </MobileFullScreen>
                    </MobileViewSerchBox>
                ) : null}

            </SerachBarMobile>
            <DeskSearchBox>
                <Firstdiv />
            </DeskSearchBox>

            <RefineBy>
                <Container className="d-flex">
                    <Categories className="col-md-2">
                        <Title className="mt-4 mb-2">Refine By</Title>
                        <SideBar className="my-2 mb-5 side-scrollbar">
                            <div className="form-check">
                                <label className="form-check-label">
                                    <input type="radio" className="form-check-input mt-2" name="filter" />All Functions
                                </label>
                            </div>
                            <div className="form-check">
                                <label className="form-check-label">
                                    <input type="radio" className="form-check-input mt-2" name="filter" />Human Resources (123)
                                </label>
                            </div>
                            <div className="form-check">
                                <label className="form-check-label">
                                    <input type="radio" className="form-check-input mt-2" name="filter" />Operations (45)
                                </label>
                            </div>
                            <div className="form-check">
                                <label className="form-check-label">
                                    <input type="radio" className="form-check-input mt-2" name="filter" />Information Technology (99)
                                </label>
                            </div>
                            <div className="form-check">
                                <label className="form-check-label">
                                    <input type="radio" className="form-check-input mt-2" name="filter" />Marketing & PR (56)
                                </label>
                            </div>
                            <div className="form-check">
                                <label className="form-check-label">
                                    <input type="radio" className="form-check-input mt-2" name="filter" />Sales (78)
                                </label>
                            </div>
                            <div className="form-check">
                                <label className="form-check-label">
                                    <input type="radio" className="form-check-input mt-2" name="filter" />Finance (128)
                                </label>
                            </div>
                            <div className="form-check">
                                <label className="form-check-label">
                                    <input type="radio" className="form-check-input mt-2" name="filter" />Legal (166)
                                </label>
                            </div>
                        </SideBar>
                        <Title className="mt-4 mb-2">Job By Location</Title>
                        <SideBar className="my-2 side-scrollbar">
                            <div className="form-check">
                                <label className="form-check-label">
                                    <input type="radio" className="form-check-input mt-2" name="filter" />Delhi
                                </label>
                            </div>
                            <div className="form-check">
                                <label className="form-check-label">
                                    <input type="radio" className="form-check-input mt-2" name="filter" />Mumbai
                                </label>
                            </div>
                            <div className="form-check">
                                <label className="form-check-label">
                                    <input type="radio" className="form-check-input mt-2" name="filter" />Pune
                                </label>
                            </div>
                            <div className="form-check">
                                <label className="form-check-label">
                                    <input type="radio" className="form-check-input mt-2" name="filter" />Nagpur
                                </label>
                            </div>
                            <div className="form-check">
                                <label className="form-check-label">
                                    <input type="radio" className="form-check-input mt-2" name="filter" />Nashik
                                </label>
                            </div>
                            <div className="form-check">
                                <label className="form-check-label">
                                    <input type="radio" className="form-check-input mt-2" name="filter" />Jalgaon
                                </label>
                            </div>
                            <div className="form-check">
                                <label className="form-check-label">
                                    <input type="radio" className="form-check-input mt-2" name="filter" />Kolhapur
                                </label>
                            </div>
                            <div className="form-check">
                                <label className="form-check-label">
                                    <input type="radio" className="form-check-input mt-2" name="filter" />Indore
                                </label>
                            </div>
                        </SideBar>
                    </Categories>
                    <ResultSection className="col-md-10">
                        <TitleContainer className=" d-flex">
                            <Title className="m-3 ">Refine By</Title>
                            <Title blue className="m-3 ">0</Title>
                        </TitleContainer>
                        <IMGContainer className="mt-5 text-center">
                            <IMG src={serchnotfound} alt="" />
                        </IMGContainer>
                        <SearchNotFoundText>No Search Found</SearchNotFoundText>
                    </ResultSection>
                </Container>
            </RefineBy>

            <RefineByMobile>

                <div className="d-flex justify-content-center align-center flex-column" style={{height: '90%'}}>
                    <div className="d-flex justify-content-center align-center flex-column">
                        <div className="text-center mt-5">
                            <img style={{width: '40%'}} src={serchnotfound} alt="" />
                        </div>
                        <h5 className="text-center fw-bold">No Search Found</h5>
                    </div>
                </div>

                <FilterButton id="mobile" onClick={FilterBtn}>
                    <FilterButtonText className="text-center mb-0 p-2" >Filter</FilterButtonText>
                </FilterButton>
                {openFilter ? (
                    <MobileFilter>
                        <div className="mt-4">
                            <FlexForTitle className="d-flex justify-content-between">
                                <p className="text-center fw-bold ">&nbsp;</p>
                                <p className="text-center fw-bold " style={{ fontSize: '22px' }}>Filter</p>
                                <Closesign onClick={FilterBtn}>×</Closesign>
                            </FlexForTitle>
                            <div className="d-flex">
                                <TabNameContainer className="col-4">
                                    <TabsContainer className={toggleState === 1 ? "tabs active-tabs" : "tabs"} onClick={() => toggleTab(1)}>
                                        <TabName>Refine By</TabName>
                                    </TabsContainer>
                                    <TabsContainer className={toggleState === 2 ? "tabs active-tabs" : "tabs"} onClick={() => toggleTab(2)}>
                                        <TabName>Job By Location</TabName>
                                    </TabsContainer>
                                </TabNameContainer>
                                <div className="col-8 p-0">
                                    <div className={toggleState === 1 ? "content  active-content" : "content"}>
                                        <div className="d-flex m-2" style={{ borderRadius: '20px', border: '2px solid #dfdfdf', padding: '5px 7px' }}>
                                            <Search color="#0073db" />
                                            <SearchBar type="text" placeholder="Search Location" />
                                        </div>
                                        <SideBar className="my-2 mb-5 side-scrollbar">
                                            <div className="form-check">
                                                <label className="form-check-label">
                                                    <input type="radio" className="form-check-input mt-2" name="filter" />All Functions
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <label className="form-check-label">
                                                    <input type="radio" className="form-check-input mt-2" name="filter" />Human Resources (123)
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <label className="form-check-label">
                                                    <input type="radio" className="form-check-input mt-2" name="filter" />Operations (45)
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <label className="form-check-label">
                                                    <input type="radio" className="form-check-input mt-2" name="filter" />Information Technology (99)
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <label className="form-check-label">
                                                    <input type="radio" className="form-check-input mt-2" name="filter" />Marketing & PR (56)
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <label className="form-check-label">
                                                    <input type="radio" className="form-check-input mt-2" name="filter" />Sales (78)
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <label className="form-check-label">
                                                    <input type="radio" className="form-check-input mt-2" name="filter" />Finance (128)
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <label className="form-check-label">
                                                    <input type="radio" className="form-check-input mt-2" name="filter" />Legal (166)
                                                </label>
                                            </div>
                                        </SideBar>
                                    </div>
                                    <div className={toggleState === 2 ? "content  active-content" : "content"}>
                                        <div className="d-flex m-2" style={{ borderRadius: '20px', border: '2px solid #dfdfdf', padding: '5px 7px' }}>
                                            <Search color="#0073db" />
                                            <SearchBar type="text" placeholder="Search Location" />
                                        </div>
                                        <SideBar className="my-2 mb-5 side-scrollbar">
                                            <div className="form-check">
                                                <label className="form-check-label">
                                                    <input type="radio" className="form-check-input mt-2" name="filter" />Delhi
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <label className="form-check-label">
                                                    <input type="radio" className="form-check-input mt-2" name="filter" />Mumbai
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <label className="form-check-label">
                                                    <input type="radio" className="form-check-input mt-2" name="filter" />Pune
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <label className="form-check-label">
                                                    <input type="radio" className="form-check-input mt-2" name="filter" />Nagpur
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <label className="form-check-label">
                                                    <input type="radio" className="form-check-input mt-2" name="filter" />Nashik
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <label className="form-check-label">
                                                    <input type="radio" className="form-check-input mt-2" name="filter" />Jalgaon
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <label className="form-check-label">
                                                    <input type="radio" className="form-check-input mt-2" name="filter" />Kolhapur
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <label className="form-check-label">
                                                    <input type="radio" className="form-check-input mt-2" name="filter" />Indore
                                                </label>
                                            </div>
                                        </SideBar>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </MobileFilter>

                ) : null}
            </RefineByMobile>


        </>
    )
}

export default SearchNotFound
