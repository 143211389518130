import React from 'react'
import { Container } from 'react-bootstrap'
import { JSJobLocationDiv, JSJobLocationTitle, JSJobLocationViewAll, JSJobLocationCart, BackgroundImgDiv, LocationNameDiv, LocationName, Jobs } from './JobLocation.style'
import DelhiImg from '../../../assets/body/location/1.png'
import MumbaiImg from '../../../assets/body/location/2.png'
import PuneImg from '../../../assets/body/location/3.png'
import BangloreImg from '../../../assets/body/location/4.png'
import HyderabadImg from '../../../assets/body/location/5.png'

const JobLocation = () => {
    return (
        <div>
            <Container>
                <JSJobLocationDiv className="row my-3 mb-4 align-items-center justify-content-between">
                    <div className="col-8">
                        <JSJobLocationTitle>JOB BY POPULAR LOCATIONS</JSJobLocationTitle>
                    </div>
                    <div className="col-4 text-end">
                        <JSJobLocationViewAll to="#" >View All</JSJobLocationViewAll>
                    </div>
                </JSJobLocationDiv>
                <JSJobLocationCart>
                    <div className="row gx-lg-3 mb-4">
                        <div className="col-lg-4 ">
                            <div className="row gx-lgk-3 mb-3">
                                <div className="col-12">
                                    <BackgroundImgDiv style={{ backgroundImage: `url(${DelhiImg})` }}>
                                        <LocationNameDiv>
                                            <LocationName>Delhi</LocationName>
                                            <Jobs>2,500 Jobs</Jobs>
                                        </LocationNameDiv>
                                    </BackgroundImgDiv>
                                </div>
                            </div>
                            <div className="row gx-lgk-3 mb-3">
                                <div className="col-12">
                                    <BackgroundImgDiv style={{ backgroundImage: `url(${MumbaiImg})` }}>
                                        <LocationNameDiv>
                                            <LocationName>Mumbai</LocationName>
                                            <Jobs>2,500 Jobs</Jobs>
                                        </LocationNameDiv>
                                    </BackgroundImgDiv>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="row gx-lgk-3 mb-3">
                                <div className="col-12">
                                    <BackgroundImgDiv style={{ backgroundImage: `url(${PuneImg})` }}>
                                        <LocationNameDiv>
                                            <LocationName>Pune</LocationName>
                                            <Jobs>2,500 Jobs</Jobs>
                                        </LocationNameDiv>
                                    </BackgroundImgDiv>
                                </div>
                            </div>
                            <div className="row gx-lgk-3 mb-3">
                                <div className="col-12">
                                    <BackgroundImgDiv style={{ backgroundImage: `url(${BangloreImg})` }}>
                                        <LocationNameDiv>
                                            <LocationName>Banglore</LocationName>
                                            <Jobs>2,500 Jobs</Jobs>
                                        </LocationNameDiv>
                                    </BackgroundImgDiv>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 d-lg-flex align-items-lg-stretch mb-3">
                            <BackgroundImgDiv style={{ backgroundImage: `url(${HyderabadImg})` }}>
                                <LocationNameDiv>
                                    <LocationName>Hyderabad</LocationName>
                                    <Jobs>2,500 Jobs</Jobs>
                                </LocationNameDiv>
                            </BackgroundImgDiv>
                        </div>
                    </div>

                </JSJobLocationCart>


            </Container>
        </div>
    )
}

export default JobLocation
