import React from 'react';
import { Container } from 'react-bootstrap';
import Header from '../Header/header'
import { ContactUs, ContactUsBox, FormBox, FormTitle, TextDiv, TextBox, Textarea, ButtonBG, ThemeCancelButton } from './Contact.style'
import { Dropdown } from 'react-dropdown-now';
import '../SignUp/RightSide/First Step/drpdown.css';

const Contact = () => {
    return (
        <div>
            <Header />
            <ContactUs>
                <Container>
                    <div className="row">
                        <ContactUsBox className="mt-5 mb-5 mt-md-5">
                            <div className="mt-3 d-flex flex-wrap">
                                <div className="col col-md-6 m-auto">
                                    <FormBox className=" m-auto text-center">
                                        <FormTitle className="mb-3">Get in touch with us !</FormTitle>
                                        <form>
                                            <TextDiv className="mb-3">
                                                <TextBox type="text" className="form-control" name="name" placeholder="Name" />
                                            </TextDiv>
                                            <TextDiv className="mb-3">
                                                <TextBox type="email" className="form-control" name="name" placeholder="Email Id" />
                                            </TextDiv>
                                            <TextDiv className="mb-3">
                                                <Dropdown
                                                    placeholder="Please Select Nature Of Your Enquiry"
                                                    className="my-className text-start"
                                                    options={['Looking for a new job', 'Basic member enquiry', 'Gold member enquiry', 'Platinum member enquiry', 'Recruiters inquiry', 'Employers enquiry other issues']}
                                                    value="Looking for a new job"
                                                    onChange={(value) => console.log('change!', value)}
                                                    onSelect={(value) => console.log('selected!', value)} // always fires once a selection happens even if there is no change
                                                    onClose={(closedBySelection) => console.log('closedBySelection?:', closedBySelection)}
                                                    onOpen={() => console.log('open!')}
                                                    />
                                            </TextDiv>
                                            <TextDiv className="mb-3">
                                                <Textarea className="form-control" name="message" placeholder="Message" />
                                            </TextDiv>

                                            <ButtonBG className="mt-5">
                                            <ThemeCancelButton Cv to="#">
                                                Submit
                                            </ThemeCancelButton>
                                        </ButtonBG>
                                        </form>
                                    </FormBox>
                                </div>
                            </div>
                        </ContactUsBox>
                    </div>
                </Container>
            </ContactUs>
        </div>
    )
}

export default Contact
