import React, { useState } from "react";
import UserHeader from '../Header/UserHeader/userheader'
import { Container } from "react-bootstrap";
import {
    DeskMenuSection,
    TabsContainer,
    TabsContainerLogOut,
    TabIcon,
    TabName,
    ContentSection,
    ContentSectionTabName,
    ProfilePhotoSection,
    BDProfilePhotoBG,
    ProfileImage,
    UpladBtnImg,
    BDWhiteBox,
    BDPackageTextTitle,
    PackageText,
    PackageButton,
    BDDesktopTable,
    BDMobileTable,
    ButtonBG,
    ThemeCancelButton,
    PlatinumButton,
    InformationSection,
    BasicDetailText,
    MrCheckBoxDiv,
    MrCheckBoxMobileDiv,
    Checkbox,
    Checkspan,
    TextDiv,
    TextBox,
    ThemeButton,
    SubHeading,
    InputTitle,
    InputFile,
    RadioSpan,
    RadioButton,
    MobileBottomTab,
    Menus,
    Chpasswidth,
    InpBox,
    InfoContainer,
    PriceBoxContainer,
    PriceBoxTitleAlignment,
    PriceBoxTitle,
    InfoBoxContainer,
    InfoBoxText,
    BtnContainer,
    ModalHead,
    RedBorder,
    PlanExpireText,
    ContinunRedButton

} from './PlatinumUserDashboard.style';
import ProfileIcon from '../../assets/header/drpdown-icon/Group 2134.svg';
import SavedJobsIcon from '../../assets/header/drpdown-icon/Group 2143.svg';
import JobStatistics from '../../assets/header/drpdown-icon/Group 2142.svg';
import CVDevelopment from '../../assets/header/drpdown-icon/Group 2138.svg';
import ChangePassIcon from '../../assets/header/drpdown-icon/Group 2141.svg';
import LogoutIcon from '../../assets/header/drpdown-icon/Group 2139.svg';
import Avatar from '../../assets/body/basic-profile-landing/basic-profile-img.png'
import UploadBtn from '../../assets/body/contact/Group 2144.svg'
import DTable from '../basicLandingPage/Table/table';
import MTable from '../basicLandingPage/Mobiletable/MobileTable'
import { Dropdown } from 'react-dropdown-now';
import ReactTagInput from "@pathofdev/react-tag-input";
import "../basicUserDashboard/tabs.css";
import { PasswordChangeType } from '../../redux/SignIn/SignIn.action';
import { useDispatch, useSelector } from 'react-redux';
import { IsPasswordType } from '../../redux/SignIn/SignIn.selector'
import { Eye, EyeOff, X } from "react-feather";
import DeskJobStatistics from './Job Statistics/Desktop/DeskJobStatistics'
import MobJobStatistics from './Job Statistics/Mobile/MobJobStatistics'
import { Modal } from "react-bootstrap";


function Tabs() {
    const [toggleState, setToggleState] = useState(1);

    const toggleTab = (index) => {
        setToggleState(index);
    };
    const [jobtitletags, setjobtitleTags] = React.useState([])

    const [industrytags, setindustryTags] = React.useState([])

    const [joblocationtags, setjoblocationTags] = React.useState([])

    const [checkBox, setcheckBox] = useState(false)

    const dispatch = useDispatch()

    const PassType = useSelector(IsPasswordType)

    //modal delete account
    const [show, setShow] = useState(false);

    const Close = () => setShow(false);
    const Show = () => setShow(true);

     //modal cancel plan
     const [cancelplan, setCancelPlan] = useState(false);

     const CloseCancel = () => setCancelPlan(false);
     const ShowCancel = () => setCancelPlan(true);

    //cancel button
    const [opencancelButton, setopencancelButton] = useState(false);


    const CancelButton = () => {
        // console.log('hiii');
        setopencancelButton(!opencancelButton)
    }

    return (
        <>
            <UserHeader />
            <Container>
                <div className="row">
                    <DeskMenuSection className="col-md-2">
                        <TabsContainer className={toggleState === 1 ? "tabs active-tabs" : "tabs"} onClick={() => toggleTab(1)}>
                            <TabIcon src={ProfileIcon} alt="" />
                            <TabName>Profile</TabName>
                        </TabsContainer>

                        <TabsContainer className={toggleState === 2 ? "tabs active-tabs" : "tabs"} onClick={() => toggleTab(2)}>
                            <TabIcon src={SavedJobsIcon} alt="" />
                            <TabName>Saved Jobs</TabName>
                        </TabsContainer>

                        <TabsContainer className={toggleState === 3 ? "tabs active-tabs" : "tabs"} onClick={() => toggleTab(3)}>
                            <TabIcon src={JobStatistics} alt="" />
                            <TabName>Job Statistics</TabName>
                        </TabsContainer>

                        <TabsContainer className={toggleState === 4 ? "tabs active-tabs" : "tabs"} onClick={() => toggleTab(4)}>
                            <TabIcon src={CVDevelopment} alt="" />
                            <TabName>CV Development</TabName>
                        </TabsContainer>

                        <TabsContainer className={toggleState === 5 ? "tabs active-tabs" : "tabs"} onClick={() => toggleTab(5)}>
                            <TabIcon src={ChangePassIcon} alt="" />
                            <TabName>Change Password</TabName>
                        </TabsContainer>

                        <TabsContainerLogOut className={toggleState === 6 ? "tabs active-tabs" : "tabs"} to="/signin">
                            <TabIcon src={LogoutIcon} alt="" />
                            <TabName>Logout</TabName>
                        </TabsContainerLogOut>
                    </DeskMenuSection>


                    <ContentSection className="col-md-10" >
                        <div className={toggleState === 1 ? "content  active-content" : "content"}>
                            <div className="border-bottom border-2">
                                <ContentSectionTabName className="p-2 ps-3 mb-0">My Profile</ContentSectionTabName>
                            </div>
                            <Container>
                                <div className="row">
                                    <ProfilePhotoSection className="col-md-4">
                                        <BDProfilePhotoBG className="shadow-sm mt-3 bg-white rounded">
                                            <ProfileImage src={Avatar} alt="" />
                                            <UpladBtnImg src={UploadBtn} slt="" />
                                        </BDProfilePhotoBG>

                                        <BDWhiteBox className="shadow-sm mt-3 p-4 bg-white rounded">
                                            <BDPackageTextTitle>Package</BDPackageTextTitle>
                                            <div className="d-flex pt-3">
                                                <PackageText >Current Membership</PackageText>
                                                <PackageText bold className="ps-4">Platinum</PackageText>
                                            </div>
                                            <div className="d-flex pt-3">
                                                <PackageText >Plan Renewal On</PackageText>
                                                <PackageText bold className="ps-4">27th March 2022</PackageText>
                                            </div>

                                            <PackageButton>
                                                <div>
                                                    <ButtonBG className="mt-4 me-3" onClick={CancelButton}>
                                                        <ThemeCancelButton to="#" className="py-2 px-4">
                                                            Cancel Plan
                                                        </ThemeCancelButton>
                                                    </ButtonBG>
                                                    {opencancelButton ? (
                                                        <div>
                                                            <RedBorder></RedBorder>
                                                            <PlanExpireText>Plan Expires On</PlanExpireText>
                                                            <PlanExpireText>10 Dec 2021</PlanExpireText>
                                                            <RedBorder></RedBorder>
                                                            <ButtonBG className="mt-0" onClick={ShowCancel}>
                                                                <ContinunRedButton to="#">
                                                                    Continue
                                                                </ContinunRedButton>
                                                            </ButtonBG>
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </PackageButton>
                                        </BDWhiteBox>

                                        <BDWhiteBox className="shadow-sm mt-3 p-4 bg-white rounded">
                                            <BDPackageTextTitle>Delete My Account</BDPackageTextTitle>
                                            <PackageButton className="pt-3">
                                                <ButtonBG onClick={Show}>
                                                    <PlatinumButton to="#" className="">
                                                        Delete Account
                                                    </PlatinumButton>
                                                </ButtonBG>
                                            </PackageButton>
                                        </BDWhiteBox>

                                        {/* Cancel Plan Modal */}

                                        <Modal
                                            centered className="modalbody" show={cancelplan} onHide={CloseCancel}>
                                            <ModalHead>
                                                <X width="20" color="#fff" strokeWidth="5" onClick={CloseCancel} style={{ cursor: 'pointer' }} />
                                            </ModalHead>
                                            <Modal.Body>
                                                <h6 className="text-center fw-bold">ARE YOU SURE TO CANCEL THIS PLAN ?</h6>

                                            </Modal.Body>
                                            <div className="text-center mb-3">
                                                <ButtonBG onClick={CloseCancel}>
                                                    <ThemeCancelButton Cv to="#" className="py-2 px-4">
                                                        Yes
                                                    </ThemeCancelButton>
                                                </ButtonBG>
                                            </div>
                                        </Modal>

                                        {/* Delete Account Modal */}

                                        <Modal
                                            centered className="modalbody" show={show} onHide={Close}>
                                            <ModalHead>
                                                <X width="20" color="#fff" strokeWidth="5" onClick={Close} style={{ cursor: 'pointer' }} />
                                            </ModalHead>
                                            <Modal.Body>
                                                <h6 className="text-center fw-bold">ARE YOU SURE YOU WANT TO DELETE YOUR ACCOUNT PERMENTLY ?</h6>

                                            </Modal.Body>
                                            <div className="text-center mb-3">
                                                <ButtonBG onClick={Close}>
                                                    <ThemeCancelButton Cv to="#" className="py-2 px-4">
                                                        Yes
                                                    </ThemeCancelButton>
                                                </ButtonBG>
                                            </div>
                                        </Modal>

                                    </ProfilePhotoSection>

                                    <InformationSection className="col-md-8">
                                        <BDWhiteBox className="shadow-sm mt-3 p-md-4 p-2 bg-white rounded">
                                            <div className="border-bottom border-2">
                                                <BasicDetailText className=" mb-1" >Basic Details</BasicDetailText>
                                            </div>

                                            <MrCheckBoxDiv className="d-flex flex-wrap justify-content-between align-items-center mt-4 mb-3">
                                                <div className="d-flex justify-content-center align-items-center">
                                                    <Checkbox type="checkbox" /><Checkspan>Mr</Checkspan>
                                                </div>
                                                <div className="d-flex justify-content-center align-items-center">
                                                    <Checkbox type="checkbox" /><Checkspan>Mrs</Checkspan>
                                                </div>
                                                <div className="d-flex justify-content-center align-items-center">
                                                    <Checkbox type="checkbox" /><Checkspan>Miss</Checkspan>
                                                </div>
                                                <div className="d-flex justify-content-center align-items-center">
                                                    <Checkbox type="checkbox" /><Checkspan>Ms</Checkspan>
                                                </div>
                                                <div className="d-flex justify-content-center align-items-center">
                                                    <Checkbox type="checkbox" /><Checkspan>Dr</Checkspan>
                                                </div>
                                                <div className="d-flex justify-content-center align-items-center">
                                                    <Checkbox type="checkbox" /><Checkspan>Other</Checkspan>
                                                </div>
                                            </MrCheckBoxDiv>

                                            <MrCheckBoxMobileDiv className="mt-4 mb-3">
                                                <Dropdown
                                                    placeholder="Select an option"
                                                    className="my-className"
                                                    options={['Mr', 'Mrs', 'Miss', 'Ms', 'Dr', 'Other']}
                                                    value="Mr"
                                                    onChange={(value) => console.log('change!', value)}
                                                    onSelect={(value) => console.log('selected!', value)} // always fires once a selection happens even if there is no change
                                                    onClose={(closedBySelection) => console.log('closedBySelection?:', closedBySelection)}
                                                    onOpen={() => console.log('open!')}
                                                />
                                            </MrCheckBoxMobileDiv>

                                            <div className="row">
                                                <TextDiv className="col-md-6 col-12">
                                                    <TextBox type="text" className="form-control" name="name" placeholder="Name" />
                                                </TextDiv>
                                                <TextDiv className="col-md-6 col-12">
                                                    <TextBox type="email" className="form-control" name="name" placeholder="Email Id" />
                                                </TextDiv>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-6 col-12">
                                                    <div className="d-flex col-12">
                                                        <TextDiv className="col-4" >
                                                            <Dropdown
                                                                placeholder="Select an option"
                                                                className="my-className"
                                                                options={['91+', '54+', '61+', '43+']}
                                                                value="91+"
                                                                onChange={(value) => console.log('change!', value)}
                                                                onSelect={(value) => console.log('selected!', value)} // always fires once a selection happens even if there is no change
                                                                onClose={(closedBySelection) => console.log('closedBySelection?:', closedBySelection)}
                                                                onOpen={() => console.log('open!')}
                                                            />
                                                        </TextDiv>
                                                        <TextDiv className="col-8" style={{ borderLeft: '1px solid #ced4da', }}>
                                                            <TextBox type="number" className="form-control" name="name" placeholder="Phone" />
                                                        </TextDiv>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-12">
                                                    <TextDiv>
                                                        <TextBox
                                                            type="text"
                                                            onFocus={
                                                                (e) => {
                                                                    e.currentTarget.type = "date";
                                                                }
                                                            }
                                                            placeholder="Birth Date"
                                                        />
                                                    </TextDiv>
                                                </div>
                                            </div>

                                            <TextDiv>
                                                <TextBox type="text" className="form-control" name="address" placeholder="Address" />
                                            </TextDiv>

                                            <ButtonBG>
                                                <ThemeButton to="#" className="py-2 px-4">
                                                    Update
                                                </ThemeButton>
                                            </ButtonBG>

                                            <div className="mt-4 pt-2">
                                                <BasicDetailText className=" mb-4" >Desired Profile Details</BasicDetailText>
                                            </div>

                                            <div className="col inline-block mb-4 mr-2">
                                                <SubHeading className=" pb-2">Desired Job Title</SubHeading>
                                                <TextDiv>
                                                    <ReactTagInput
                                                        tags={jobtitletags}
                                                        placeholder="Add New"
                                                        maxTags={10}
                                                        editable={true}
                                                        readOnly={false}
                                                        removeOnBackspace={true}
                                                        onChange={(newTags) => setjobtitleTags(newTags)}
                                                    />
                                                </TextDiv>
                                            </div>

                                            <div className="col inline-block mb-4 mr-2">
                                                <SubHeading className=" pb-2">Desired Industry</SubHeading>
                                                <TextDiv>
                                                    <ReactTagInput
                                                        tags={industrytags}
                                                        placeholder="Add New"
                                                        maxTags={10}
                                                        editable={true}
                                                        readOnly={false}
                                                        removeOnBackspace={true}
                                                        onChange={(newTags) => setindustryTags(newTags)}
                                                    />
                                                </TextDiv>
                                            </div>

                                            <div className="col inline-block mb-4 mr-2">
                                                <SubHeading className=" pb-2">Desired Location</SubHeading>
                                                <TextDiv>
                                                    <ReactTagInput
                                                        tags={joblocationtags}
                                                        placeholder="Add New"
                                                        maxTags={10}
                                                        editable={true}
                                                        readOnly={false}
                                                        removeOnBackspace={true}
                                                        onChange={(newTags) => setjoblocationTags(newTags)}
                                                    />
                                                </TextDiv>
                                            </div>

                                            <Dropdown
                                                placeholder="Desired salary"
                                                className="my-className"
                                                options={['50K - 60K Per Year', '60K - 70K Per Year', '70K - 80K Per Year', '80K - 90K Per Year']}
                                                value="50K - 60K Per Year"
                                                onChange={(value) => console.log('change!', value)}
                                                onSelect={(value) => console.log('selected!', value)} // always fires once a selection happens even if there is no change
                                                onClose={(closedBySelection) => console.log('closedBySelection?:', closedBySelection)}
                                                onOpen={() => console.log('open!')}
                                            />

                                            <div className="mt-4 pt-2">
                                                <BasicDetailText className=" mb-4" >Job Details</BasicDetailText>
                                            </div>

                                            <div className="row">
                                                <TextDiv className="col-md-6 col-12">
                                                    <TextBox type="text" className="form-control" name="uiuxdesign" placeholder="UI UX Designer" />
                                                </TextDiv>
                                                <TextDiv className="col-md-6 col-12">
                                                    <TextBox type="email" className="form-control" name="digital_agency" placeholder="Digital Agency" />
                                                </TextDiv>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-6 col-12 d-flex">
                                                    <TextDiv className="col-4" >
                                                        <Dropdown
                                                            placeholder="Select an option"
                                                            className="my-className"
                                                            options={['USD', 'INR', 'USA', 'RS']}
                                                            value="USD"
                                                            onChange={(value) => console.log('change!', value)}
                                                            onSelect={(value) => console.log('selected!', value)} // always fires once a selection happens even if there is no change
                                                            onClose={(closedBySelection) => console.log('closedBySelection?:', closedBySelection)}
                                                            onOpen={() => console.log('open!')}
                                                        />
                                                    </TextDiv>
                                                    <TextDiv className="col-8" style={{ borderLeft: '1px solid #ced4da', }}>
                                                        <Dropdown
                                                            placeholder="Select an option"
                                                            className="my-className"
                                                            options={['50K - 60K P Y', '60K - 70K P Y', '70K - 80K P Y', '80K - 90K P Y']}
                                                            value="50K - 60K P Y"
                                                            onChange={(value) => console.log('change!', value)}
                                                            onSelect={(value) => console.log('selected!', value)} // always fires once a selection happens even if there is no change
                                                            onClose={(closedBySelection) => console.log('closedBySelection?:', closedBySelection)}
                                                            onOpen={() => console.log('open!')}
                                                        />
                                                    </TextDiv>
                                                </div>
                                                <div className="col-md-6 col-12">
                                                    <div className="d-flex col-12">
                                                        <TextDiv className="col-4" >
                                                            <Dropdown
                                                                placeholder="Select an option"
                                                                className="my-className"
                                                                options={['91+', '54+', '61+', '43+']}
                                                                value="91+"
                                                                onChange={(value) => console.log('change!', value)}
                                                                onSelect={(value) => console.log('selected!', value)} // always fires once a selection happens even if there is no change
                                                                onClose={(closedBySelection) => console.log('closedBySelection?:', closedBySelection)}
                                                                onOpen={() => console.log('open!')}
                                                            />
                                                        </TextDiv>
                                                        <TextDiv className="col-8" style={{ borderLeft: '1px solid #ced4da', }}>
                                                            <TextBox type="number" className="form-control" name="name" placeholder="Phone" />
                                                        </TextDiv>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-6 col-12">
                                                    <TextDiv className="d-flex justify-content-between my-1">
                                                        <RadioSpan>Hide Email id from recruiters</RadioSpan>
                                                        <RadioButton onChange={() => setcheckBox(!checkBox)} type="checkbox" value="Hide Email" name="HideEmail" />
                                                    </TextDiv>
                                                    <TextDiv className="d-flex justify-content-between my-1">
                                                        <RadioSpan>Hide Name from recruiters</RadioSpan>
                                                        <RadioButton onChange={() => setcheckBox(!checkBox)} type="checkbox" value="Hide Email" name="HideEmail" />
                                                    </TextDiv>
                                                </div>
                                                <div className="col-md-6 col-12">
                                                    <TextDiv className="d-flex justify-content-between my-1">
                                                        <RadioSpan>Recruiters view your CV or Not</RadioSpan>
                                                        <RadioButton onChange={() => setcheckBox(!checkBox)} type="checkbox" value="Hide Email" name="HideEmail" />
                                                    </TextDiv>
                                                    <TextDiv className="d-flex justify-content-between my-1">
                                                        <RadioSpan>Receive job alerts via email</RadioSpan>
                                                        <RadioButton onChange={() => setcheckBox(!checkBox)} type="checkbox" value="Hide Email" name="HideEmail" />
                                                    </TextDiv>
                                                </div>
                                            </div>


                                            <div className="mt-4 pt-2">
                                                <BasicDetailText className=" mb-4" >Resume</BasicDetailText>
                                            </div>
                                            <TextDiv className="mb-2" style={{ justifyContent: 'start', borderBottom: '1px solid black' }}>
                                                <InputTitle>Attach Your Resume</InputTitle>
                                            </TextDiv>
                                            <TextDiv className="mb-2" style={{ justifyContent: 'start' }}>
                                                <InputFile type="file" accept="application/pdf,application/vnd.ms-excel" />
                                            </TextDiv>

                                        </BDWhiteBox>
                                    </InformationSection>

                                </div>

                            </Container>
                        </div>

                        <div className={toggleState === 2 ? "content  active-content" : "content"}>
                            <div className="border-bottom border-2">
                                <ContentSectionTabName className="p-2 ps-3 mb-0">Saved Jobs</ContentSectionTabName>
                            </div>
                            <BDDesktopTable className="m-3">
                                <DTable />
                            </BDDesktopTable>
                            <BDMobileTable className="mt-3">
                                <MTable />
                            </BDMobileTable>
                        </div>

                        <div className={toggleState === 3 ? "content  active-content" : "content"}>
                            <div className="border-bottom border-2">
                                <BasicDetailText className=" p-2 ps-3 mb-0" >Job Statistics</BasicDetailText>
                            </div>
                            <BDDesktopTable className="mt-3">
                                <DeskJobStatistics />
                            </BDDesktopTable>
                            <BDMobileTable className="mt-3">
                                <MobJobStatistics />
                            </BDMobileTable>
                        </div>

                        <div className={toggleState === 4 ? "content  active-content" : "content"}>
                            <div className="border-bottom border-2">
                                <BasicDetailText className=" p-2 ps-3 mb-0" >Professional CV / Resume writing service</BasicDetailText>
                            </div>
                            <BDWhiteBox className=" m-3 p-md-4 p-2 rounded">
                                <InfoContainer first className="mb-3 py-lg-0 py-2 d-lg-flex d-block">
                                    <PriceBoxContainer first className="px-4 col-lg-2 col-12">
                                        <PriceBoxTitleAlignment className="d-flex align-items-center">
                                            <PriceBoxTitle>Entry Level</PriceBoxTitle>
                                        </PriceBoxTitleAlignment>
                                        <PriceBoxTitle bold className="fw-bold" >$399</PriceBoxTitle>
                                    </PriceBoxContainer>
                                    <InfoBoxContainer first className="col-lg-8 col-12  d-flex align-items-center">
                                        <InfoBoxText className="px-4">For Graduates Or Job Seekers Who Have Less Than 2 Years Of Work Experience.</InfoBoxText>
                                    </InfoBoxContainer>
                                    <BtnContainer first className="col-lg-2 col-12 p-lg-0 px-4 py-2">
                                        <ButtonBG>
                                            <ThemeCancelButton Cv to="#">
                                                Choose This Plan
                                            </ThemeCancelButton>
                                        </ButtonBG>
                                    </BtnContainer>
                                </InfoContainer>

                                <InfoContainer second className="mb-3 py-lg-0 py-2 d-lg-flex d-block">
                                    <PriceBoxContainer second className="px-4 col-lg-2 col-12">
                                        <PriceBoxTitleAlignment className="d-flex align-items-center">
                                            <PriceBoxTitle>Mid-Career Level</PriceBoxTitle>
                                        </PriceBoxTitleAlignment>
                                        <PriceBoxTitle bold className="fw-bold" >$399</PriceBoxTitle>
                                    </PriceBoxContainer>
                                    <InfoBoxContainer second className="col-lg-8 col-12  d-flex align-items-center">
                                        <InfoBoxText className="px-4">Suitable For The Majority Of Job Seekers Who Have Had Over 2 Years Or Work Experience.</InfoBoxText>
                                    </InfoBoxContainer>
                                    <BtnContainer second className="col-lg-2 col-12 p-lg-0 px-4 py-2 ">
                                        <ButtonBG>
                                            <ThemeCancelButton Cv to="#">
                                                Choose This Plan
                                            </ThemeCancelButton>
                                        </ButtonBG>
                                    </BtnContainer>
                                </InfoContainer>

                                <InfoContainer third className="mb-3 py-lg-0 py-2 d-lg-flex d-block">
                                    <PriceBoxContainer third className="px-4 col-lg-2 col-12">
                                        <PriceBoxTitleAlignment className="d-flex align-items-center">
                                            <PriceBoxTitle>Executive & Specialist Level</PriceBoxTitle>
                                        </PriceBoxTitleAlignment>
                                        <PriceBoxTitle bold className="fw-bold" >$399</PriceBoxTitle>
                                    </PriceBoxContainer>
                                    <InfoBoxContainer third className="col-lg-8 col-12  d-flex align-items-center">
                                        <InfoBoxText className="px-4">For Individuals With Specialist Skills, Project Work Or Pursuing A Career In Senior Management Or Equivalent. Working One-To-One With A Career Intelligence Executive Coach. The High-Value Elements Of Your Career History Are Identified And Produced Into A Professional CV.</InfoBoxText>
                                    </InfoBoxContainer>
                                    <BtnContainer third className="col-lg-2 col-12 p-lg-0 px-4 py-2 ">
                                        <ButtonBG>
                                            <ThemeCancelButton Cv to="#">
                                                Choose This Plan
                                            </ThemeCancelButton>
                                        </ButtonBG>
                                    </BtnContainer>
                                </InfoContainer>

                                <InfoContainer fourth className="mb-3 py-lg-0 py-2 d-lg-flex d-block">
                                    <PriceBoxContainer fourth className="px-4 col-lg-2 col-12">
                                        <PriceBoxTitleAlignment className="d-flex align-items-center">
                                            <PriceBoxTitle>Senior Executive VP Director Level</PriceBoxTitle>
                                        </PriceBoxTitleAlignment>
                                        <PriceBoxTitle bold className="fw-bold" >$399</PriceBoxTitle>
                                    </PriceBoxContainer>
                                    <InfoBoxContainer fourth className="col-lg-8 col-12  d-flex align-items-center">
                                        <InfoBoxText className="px-4">The Most Complex And Senior Career Histories Need The Expertise Of Our Most Senior Executive Coaches Working One-To-One With A Director-Level Executive Coach, The High-Value Elements Of Your Career History Are Identified And Produced Into A Professional CV.</InfoBoxText>
                                    </InfoBoxContainer>
                                    <BtnContainer fourth className="col-lg-2 col-12 p-lg-0 px-4 py-2">
                                        <ButtonBG>
                                            <ThemeCancelButton Cv to="#">
                                                Choose This Plan
                                            </ThemeCancelButton>
                                        </ButtonBG>
                                    </BtnContainer>
                                </InfoContainer>

                                <InfoContainer className="mb-3 py-lg-0 py-2 d-lg-flex d-block">
                                    <PriceBoxContainer className="px-4 col-lg-2 col-12">
                                        <PriceBoxTitleAlignment className="d-flex align-items-center">
                                            <PriceBoxTitle>Linkdean Development And Optimization</PriceBoxTitle>
                                        </PriceBoxTitleAlignment>
                                        <PriceBoxTitle bold className="fw-bold" >$399</PriceBoxTitle>
                                    </PriceBoxContainer>
                                    <InfoBoxContainer className="col-lg-8 col-12  d-flex align-items-center">
                                        <InfoBoxText className="px-4">Take Your Linkedin Profile To The Next Level And Turn It Into A POWERFUL Career Tool That Spotlights Your Skills, Experiences, AND Impresses Your Network Of Connections 92% Of Recruiters Use Linkedin To Find High Quality Candidates. You Need To Ensure Your Linkedin Profile Is Found When Users Search For Your Skill Sets And That It Makes An Impact. Setting You Apart From The Competition.</InfoBoxText>
                                    </InfoBoxContainer>
                                    <BtnContainer className="col-lg-2 col-12 p-lg-0 px-4 py-2">
                                        <ButtonBG>
                                            <ThemeCancelButton Cv to="#">
                                                Choose This Plan
                                            </ThemeCancelButton>
                                        </ButtonBG>
                                    </BtnContainer>
                                </InfoContainer>
                            </BDWhiteBox>
                        </div>

                        <div className={toggleState === 5 ? "content  active-content" : "content"}>
                            <div className="border-bottom border-2">
                                <BasicDetailText className=" p-2 ps-3 mb-0" >Change Password</BasicDetailText>
                            </div>
                            <BDWhiteBox className="shadow-sm m-3 p-md-4 p-2 bg-white rounded">
                                <Chpasswidth className="border rounded p-4 col-7 ">
                                    <h6>Current Password</h6>
                                    <div className="d-flex justify-content-between border rounded-pill px-3 mb-3 align-items-center">
                                        <InpBox type={PassType ? 'text' : 'password'} className="form-control p-1" required />
                                        {PassType ? <Eye width="30" color="#0073db" onClick={() => dispatch(PasswordChangeType())} /> : <EyeOff width="30" color="#0073db" onClick={() => dispatch(PasswordChangeType())} />}
                                        {/* {PassType ? <Lock width="30" color="#0073db" onClick={() => changePassType()}/> : <User width="30" color="#0073db" onClick={() => changePassType()}/>} */}
                                    </div>

                                    <h6>New Password</h6>
                                    <div className="d-flex justify-content-between border rounded-pill px-3 mb-3 align-items-center">
                                        <InpBox type={PassType ? 'text' : 'password'} className="form-control p-1" required />
                                        {PassType ? <Eye width="30" color="#0073db" onClick={() => dispatch(PasswordChangeType())} /> : <EyeOff width="30" color="#0073db" onClick={() => dispatch(PasswordChangeType())} />}
                                        {/* {PassType ? <Lock width="30" color="#0073db" onClick={() => changePassType()}/> : <User width="30" color="#0073db" onClick={() => changePassType()}/>} */}
                                    </div>

                                    <h6>Confirm Password</h6>
                                    <div className="d-flex justify-content-between border rounded-pill px-3 mb-3 align-items-center">
                                        <InpBox type={PassType ? 'text' : 'password'} className="form-control p-1" required />
                                        {PassType ? <Eye width="30" color="#0073db" onClick={() => dispatch(PasswordChangeType())} /> : <EyeOff width="30" color="#0073db" onClick={() => dispatch(PasswordChangeType())} />}
                                        {/* {PassType ? <Lock width="30" color="#0073db" onClick={() => changePassType()}/> : <User width="30" color="#0073db" onClick={() => changePassType()}/>} */}
                                    </div>

                                    <ButtonBG>
                                        <ThemeButton to="#" className="py-2 px-4">
                                            Update
                                        </ThemeButton>
                                    </ButtonBG>

                                </Chpasswidth>
                            </BDWhiteBox>
                        </div>

                        <div className={toggleState === 6 ? "content  active-content" : "content"}>
                            <h2>Content 4</h2>
                        </div>

                    </ContentSection>

                    <MobileBottomTab className="py-2 d-lg-none d-md-block">
                        <Menus className="d-flex justify-content-around">
                            <TabIcon src={ProfileIcon} alt="" onClick={() => toggleTab(1)} />
                            <TabIcon src={SavedJobsIcon} alt="" onClick={() => toggleTab(2)} />
                            <TabIcon src={JobStatistics} alt="" onClick={() => toggleTab(3)} />
                            <TabIcon src={CVDevelopment} alt="" onClick={() => toggleTab(4)} />
                            <TabIcon src={ChangePassIcon} alt="" onClick={() => toggleTab(5)} />
                            <TabIcon src={LogoutIcon} alt="" onClick={() => toggleTab(6)} />
                        </Menus>
                    </MobileBottomTab>

                </div>


            </Container>
        </>
    );
}

export default Tabs;