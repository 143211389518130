import styled from "styled-components";
import { color } from "../../styles/variable.styles";

export const BlueBgDiv = styled.div `
  background-color: ${color.theme};
`;

export const WhiteBox = styled.div `
  background-color: ${color.regularfontwhite};
`;

export const ImageBg = styled.div `
  box-shadow: 1px 0px 10px #b1b1b1;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const SignInImage = styled.img `
  width: 100%;
`;

export const FormDiv = styled.div `
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const TitleText = styled.h1 `
  text-transform: capitalize;
  font-size: 30px;
  font-weight: 800;
  text-align: start;
`;

export const SubTitleText = styled.p `
  font-size: 14px;
  font-weight: 500;
  text-align: left ;
`;
export const InpFlex = styled.div `
  border-bottom: 1px solid gray;
  padding: 5px;
  margin-top: 20px;
`;

export const InpBox = styled.input `
  border: none;
  width: 100%;
`;

export const SignInButton = styled.button `
  display: flex;
  border: 1px solid ${color.theme};
  background-color: ${color.theme};
  color: #fff;
  border-radius: 50px;
  text-transform: uppercase;
  padding: 10px 18px;
  font-size: 13px;
  font-weight: 500;
  margin: 20px 0;
  transition: 0.3s;
  &:hover {
    background-color: ${color.regularfontwhite};
    color: ${color.theme};
  }
`;

export const ButtonFlex = styled.div `
display: flex;
@media (max-width:1024px){
    display: block;
}
@media (max-width:768px){
    display: flex;
}
@media (max-width:350px){
    display: block;
}

`;