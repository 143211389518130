import styled from "styled-components";

export const TablePadding = styled.div `
padding: 40px;
@media (max-width: 769px){
  padding: 1%;
}
`;

export const TableTitleContainer = styled.div ``;

export const TableTitleText = styled.h3 `
  font-size: 24px;
  font-weight: 900;
  text-transform: uppercase;
  margin-bottom: 0;
  color:${({ blue }) => (blue ? '#0073db' : '#000')} ;
`;

export const DeskTableDiv = styled.div `
display: block;
@media (max-width: 769px){
  display: none;
}
`;

export const MobileTableDiv = styled.div `
display: none;
@media (max-width: 769px){
  display: block;
}
`;