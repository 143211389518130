import React, { useState } from "react";
import "./header.css";
import { GiHamburgerMenu } from "react-icons/gi";
import { NavLink } from "react-router-dom";
import { NavButton, LogoLink, Logoimg, SignInPngSize } from './header.style';
import Logo from '../../assets/brand/logo.svg';
import SignInPng from '../../assets/header/signin.png';
import SignUpPng from '../../assets/header/signup.png'

const header = () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [showMediaIcons, setShowMediaIcons] = useState(false);
    return (
        <>
            <nav className="main-nav">
                {/* 1st logo part  */}
                <div className="logo">
                    <LogoLink to="/">
                        <Logoimg src={Logo} alt="" />
                    </LogoLink>
                </div>

                {/* 2nd menu part  */}
                <div
                    className={
                        showMediaIcons ? "menu-link mobile-menu-link" : "menu-link"
                    }>
                    <ul>
                        <li>
                            <NavLink to="/" style={{ color: '#0073db' }}>Job Search</NavLink>
                        </li>
                        <li>
                            <NavLink to="/ourservice">Our Services</NavLink>
                        </li>

                    </ul>
                    <ul className="signupmenu">
                        <li>
                            <NavLink to="/signin">Sign In</NavLink>
                        </li>
                        <li>
                            <NavLink to="/signup">Sign Up</NavLink>
                        </li>
                    </ul>
                </div>

                {/* 3rd social media links */}
                <div className="authmenu">
                    <ul className="signupmenudesk">
                        <li>
                            <SignInPngSize src={SignInPng} alt='' />
                            <NavLink to="/signin">Sign In</NavLink>
                        </li>
                        <li>
                            <SignInPngSize src={SignUpPng} alt='' />
                            <NavLink to="/signup">Sign Up</NavLink>
                        </li>
                    </ul>

                    {/* hamburget menu start  */}
                    <div className="hamburger-menu">
                        <NavButton to='/' onClick={() => setShowMediaIcons(!showMediaIcons)}>
                            <GiHamburgerMenu />
                        </NavButton>
                    </div>
                </div>
            </nav>

            {/* hero section  */}
            {/* <section className="hero-section">
        <p>Welcome to </p>
        <h1>Thapa Technical</h1>
      </section> */}
        </>
    );
};

export default header;