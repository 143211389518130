import styled from "styled-components";
import { Link } from "react-router-dom";

export const LogoLink = styled(Link)
``;

export const FooterLogoSize = styled.img `
  width: 100px;
  height: auto;
  padding: 8px 0;
`;

export const FooterMainText = styled.h3 `
  font-size: 15px;
  font-weight: 700;
  text-transform: uppercase;
`;

export const FooterText = styled(Link)
`
  color: ${({ orgcolor }) => (orgcolor ? "#FFA500" : "#6c757d")};
  text-decoration: none;
  font-weight: 500;
`;

export const InputBox = styled.input `
  background-color: transparent;
  border-top-left-radius: 40px;
  border-bottom-left-radius: 40px;
  padding-right: 80px;
  z-index: 99;
  font-size: 12px;
  margin-right: -50px;
  overflow: hidden;
  border: 1px solid rgba(0, 102, 194, 0.6);
  border-right: none !important;
  transition: none;
`;

export const SubmitButton = styled.button `
  border: 0;
  border-right: 1px solid rgba(0, 102, 194, 0.6) !important;
  border-radius: 20px;
  vertical-align: middle;
  background-color: #0066c2;
  height: 100%;
  padding: 0px 12px;
  margin-left: -15px;
  color: #ffffff;
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
  z-index: 99999;
  -webkit-appearance: none;
`;