import styled from 'styled-components'

export const StepsArea = styled.div `
    position: relative;
    text-align: center;
    margin: auto;
    display: flex;
    justify-content: space-between;
    ::before {
    content: "";
    position: absolute;
    top: 15px;
    /* left: 40px; */
    width: 100%;
    height: 0.5px;
    background: #ffffff;
    z-index: 1;
    text-align: center;
    align-self: center;
    margin: auto;
    }
`;

export const Step = styled.span `
 background: ${({active})=> active ? '#fff' : '#0259a8'};
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    color: #000;
    display: inline-block;
    font-weight: 600;
    line-height: 28px;
    /* margin: 0 1em; */
    text-align: center;
    width: 30px;
    height: 30px;
    align-items: center;
    z-index: 99;
    position: relative;
    /*color: #ffffff;*/
    `;

/* .step::before{
      content: '';
      position: absolute;
      top: 12px;
      left: -15px;
      width: 25px;
      height: .2em;
      background: #0073DB;
      z-index: 1;
} */
//   .step:first-child::before {
//     display: none;
//   }
//   .step.active {
//     opacity: 1;
//     background: #ffffff;
//     color: #0073db;
//   }
//   .step.finish {
//     background-color: #4caf50;
//     color: #ffffff;
//   }