import React from 'react'
import { Container } from 'react-bootstrap';
import Header from '../Header/header';
import {AbBackgroundColor, MainContainer, TextContainer, TextDiv, Title, Paragraph, ImgContainer, AbImage} from './AboutUs.style'
import AboutImage from '../../assets/body/about/img1.svg'

const AboutUs = () => {
    return (
        <div>
            <Header />
            <AbBackgroundColor>
                <Container>
                    <div className="row">
                        <MainContainer className="mt-3 d-flex flex-wrap">
                            <TextContainer className="col-12 col-md-5 m-auto">
                                <TextDiv>
                                    <Title className="mt-5 mb-3">About Us</Title>
                                    <Paragraph className="mt-3">We are an organization that comes with an experience in assisting jobseekers to develop their careers by guiding them in using their skills and knowledge that they have gained over a period of time.</Paragraph>
                                    <Paragraph>Understand that for a person to grow and know how well they can progress in their career a third eye is needed hence we use our expertise gained by advising and assisting executive to develop their career.</Paragraph>
                                    <Paragraph className="mb-5">Our team has over decade of years of experience in using consulting approach to assist jobseekers to achieve their goals. We combine technology with the human touch of real advisors, supporting individuals throughout their job hunt process.</Paragraph>
                                </TextDiv>
                            </TextContainer>
                            <ImgContainer className="col-12 col-md-7 m-auto">
                                <AbImage src={AboutImage} alt="" />
                            </ImgContainer>
                        </MainContainer>
                    </div>
                </Container>

            </AbBackgroundColor>
        </div>
    )
}

export default AboutUs
