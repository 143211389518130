import { Link } from "react-router-dom";
import styled from "styled-components";
import { color } from "../../styles/variable.styles";

export const ContactUs = styled.div `
  background-color: ${color.theme};
`;

export const ContactUsBox = styled.div ``;

export const FormBox = styled.div `
  background-color: ${color.regularfontwhite};
  padding: 3rem;
  @media (max-width: 1024px) {
      padding: 2rem;
  }
  @media (max-width: 768px) {
      padding: 1rem;
  }
`;

export const FormTitle = styled.h1 `
  font-size: 30px;
  font-weight: 700;
  text-transform: capitalize;
  margin-bottom: 0;
`;

export const TextDiv = styled.div ``;

export const TextBox = styled.input `
  border: 0;
  border-bottom: 1px solid #ced4da !important;
  border-radius: 0;
  width: 100%;
  font-weight: 500;
  padding: 7px 7px;
  &:focus {
    outline: none !important;
    border-color: #fff;
    box-shadow: none;
  }
`;

export const Textarea = styled.textarea `
 border: 0;
  border-bottom: 1px solid #ced4da !important;
  border-radius: 0;
  width: 100%;
  font-weight: 600;
  padding: 7px 7px;
  &:focus {
    outline: none !important;
    border-color: #fff;
    box-shadow: none;
  }
`;

export const ButtonBG = styled.div `
  background-color: #fff;
  color: #0073db;
  border-radius: 50px;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 500;
  margin: 10px 0;
  transition: 0.3s;
  text-decoration: none !important;
`;

export const ThemeCancelButton = styled(Link)
`
  &:hover {
    background-color: ${({ Cv }) => (Cv ? "#fff" : "#0073db")};;
    color: ${({ Cv }) => (Cv ? "#0073db" : "#fff")};;
  }
  border: 1px solid ${color.theme};
  color: ${({ Cv }) => (Cv ? "#fff" : "#0073db")};
  background-color: ${({ Cv }) => (Cv ? "#0073db" : "#fff")};
  font-weight: 500;
  font-size: 13px;
  padding: 0.5rem 35px;
  cursor: pointer;
  border-radius: 50px;
  text-decoration: none !important;
  word-spacing: 2px;

`;