import React from 'react'
import { MobileFormName, ImageDiv, FormName, StepTwoImage } from './SecondStep.style'
import FormTwoImage from '../../../../assets/body/signup/img2.svg';


const SecondStep = () => {
    return (
        <>
            <MobileFormName>Let us know more about you</MobileFormName>

            <ImageDiv className="">
                <FormName>Let us know more about you</FormName>
                <StepTwoImage src={FormTwoImage} alt="" />
            </ImageDiv>
        </>
    )
}

export default SecondStep
