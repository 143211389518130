/* eslint-disable jsx-a11y/anchor-is-valid */
//rafce to create default function template
//rcc to create default class template

import React from "react";
import {
  
  BlueBg,
  PaddingDiv,
  Form,
  FlexDivForAllBox,
  SectionOfJobTitle,
  SectionOfJobTitleLabel,
  LabelJobTitle,
  JobSearchBox,
  SectionOfJobLocation,
  SectionOfJobLocationLabel,
  LabelJobLocation,
  InputHolderJobTitle,
  InputHolderJobLocation,
  SectionOfJobButton,
  SectionOfButtonLabel,
  LabelOfButton,
  ButtonHolder,
} from "./firstdiv.style";
 import {Container} from 'react-bootstrap';



const Firstdiv = () => {


  return (
    <div>
      <BlueBg>
        <Container>
          <JobSearchBox>
            <Container>
              <PaddingDiv className="py-2">
                  <Form method="POST" action="">
                    <FlexDivForAllBox className="d-md-flex flex-wrap fw-bold text-white justify-content-center text-center">

                      <SectionOfJobTitle className="col">
                        <SectionOfJobTitleLabel className="d-flex justify-content-left">
                          <LabelJobTitle
                            className="txt-left"
                            >
                            Search By Keywords
                          </LabelJobTitle>
                        </SectionOfJobTitleLabel>


                        <InputHolderJobTitle
                          type="text"
                          name="name"
                          className="d-md-flex form-control"
                          placeholder="Manager"
                          value=""
                          defaultValue=""
                          required
                        />
                      </SectionOfJobTitle>

                      <SectionOfJobLocation className="col">
                        <SectionOfJobLocationLabel className="d-flex justify-content-left">
                          <LabelJobLocation
                            className="txt-right "
                            >
                            Job Location
                          </LabelJobLocation>
                        </SectionOfJobLocationLabel>
                        <InputHolderJobLocation
                          type="text"
                          name="name"
                          className="d-md-flex form-control"
                          placeholder="Mumbai"
                          value=""
                          required
                        />
                      </SectionOfJobLocation>

                      <SectionOfJobButton className="">
                        <SectionOfButtonLabel className="d-none d-md-block">
                          <div className="d-flex justify-content-left">
                            <LabelOfButton>&nbsp;</LabelOfButton>
                          </div>
                        </SectionOfButtonLabel>
                        <ButtonHolder
                          type="submit"
                          className="d-md-block my-4 my-md-0">
                          Find Jobs{" "}
                        </ButtonHolder>
                      </SectionOfJobButton>

                    </FlexDivForAllBox>
                  </Form>
                
              </PaddingDiv>
            </Container>
          </JobSearchBox>
        </Container>
      </BlueBg>
      
    </div>
  );
};

export default Firstdiv;
