import React, { useState } from 'react'
import { JSMobileTabsContainer, JSTabs, JSTabsTitle, CountContainer, ContsDiv, JobsCount, MCard, MCompanyName, Mtd } from './MobJobStatistics.style'
import './jsmobiletabs.css'


const MobJobStatistics = () => {
    const [toggleState, setToggleState] = useState(1);

    const toggleTab = (index) => {
        setToggleState(index);
    };
    return (
        <>
            <JSMobileTabsContainer className="container-fluid d-flex justify-content-center align-items-center">
                <JSTabs LeftRadius className={toggleState === 1 ? "jstabs working-tabs" : "jstabs"} onClick={() => toggleTab(1)}>
                    <JSTabsTitle>Approved</JSTabsTitle>
                </JSTabs>
                <JSTabs RightRadius className={toggleState === 2 ? "jstabs working-tabs" : "jstabs"} onClick={() => toggleTab(2)}>
                    <JSTabsTitle>Rejected</JSTabsTitle>
                </JSTabs>
            </JSMobileTabsContainer>
            <CountContainer className="container-fluid d-flex justify-content-center align-items-center mt-3">
                <ContsDiv >
                    <JobsCount theme>1324</JobsCount>
                    <JobsCount>Total Jobs</JobsCount>
                </ContsDiv>
                <ContsDiv className="mx-2">
                    <JobsCount gold >800</JobsCount>
                    <JobsCount>Approved Jobs</JobsCount>
                </ContsDiv>
                <ContsDiv>
                    <JobsCount platinum >524</JobsCount>
                    <JobsCount>Rejected Jobs</JobsCount>
                </ContsDiv>

            </CountContainer>
            <div className="container-fluid bg-white p-3">
                <div className={toggleState === 1 ? "content  active-content" : "content"}>
                    <div className="card" style={{ border: 'none', padding: '0px' }}>
                        <div className="card-body" style={{ padding: '0px' }}>
                            <MCard className="border  border-2 rounded-3 boxwidth mb-3 p-2">
                                <MCompanyName>Beverage Group - Dubai</MCompanyName>
                                <table>
                                    <tbody>
                                        <tr>
                                            <Mtd>Location</Mtd>
                                            <Mtd className="ps-5">Saudi Arebia</Mtd>
                                        </tr>
                                        <tr>
                                            <Mtd>Approved On</Mtd>
                                            <Mtd className="ps-5">14 Dec 2021</Mtd>
                                        </tr>
                                        <tr>
                                            <Mtd>Job Link</Mtd>
                                            <Mtd Link className="ps-5">Get Link</Mtd>
                                        </tr>
                                    </tbody>
                                </table>
                            </MCard>

                            <MCard className="border  border-2 rounded-3 boxwidth mb-3 p-2">
                                <MCompanyName>Beverage Group - Dubai</MCompanyName>
                                <table>
                                    <tbody>
                                        <tr>
                                            <Mtd>Location</Mtd>
                                            <Mtd className="ps-5">Saudi Arebia</Mtd>
                                        </tr>
                                        <tr>
                                            <Mtd>Approved On</Mtd>
                                            <Mtd className="ps-5">14 Dec 2021</Mtd>
                                        </tr>
                                        <tr>
                                            <Mtd>Job Link</Mtd>
                                            <Mtd Link className="ps-5">Get Link</Mtd>
                                        </tr>
                                    </tbody>
                                </table>
                            </MCard>

                            <MCard className="border  border-2 rounded-3 boxwidth mb-3 p-2">
                                <MCompanyName>Beverage Group - Dubai</MCompanyName>
                                <table>
                                    <tbody>
                                        <tr>
                                            <Mtd>Location</Mtd>
                                            <Mtd className="ps-5">Saudi Arebia</Mtd>
                                        </tr>
                                        <tr>
                                            <Mtd>Approved On</Mtd>
                                            <Mtd className="ps-5">14 Dec 2021</Mtd>
                                        </tr>
                                        <tr>
                                            <Mtd>Job Link</Mtd>
                                            <Mtd Link className="ps-5">Get Link</Mtd>
                                        </tr>
                                    </tbody>
                                </table>
                            </MCard>

                            <MCard className="border  border-2 rounded-3 boxwidth mb-3 p-2">
                                <MCompanyName>Beverage Group - Dubai</MCompanyName>
                                <table>
                                    <tbody>
                                        <tr>
                                            <Mtd>Location</Mtd>
                                            <Mtd className="ps-5">Saudi Arebia</Mtd>
                                        </tr>
                                        <tr>
                                            <Mtd>Approved On</Mtd>
                                            <Mtd className="ps-5">14 Dec 2021</Mtd>
                                        </tr>
                                        <tr>
                                            <Mtd>Job Link</Mtd>
                                            <Mtd Link className="ps-5">Get Link</Mtd>
                                        </tr>
                                    </tbody>
                                </table>
                            </MCard>

                            <MCard className="border  border-2 rounded-3 boxwidth mb-3 p-2">
                                <MCompanyName>Beverage Group - Dubai</MCompanyName>
                                <table>
                                    <tbody>
                                        <tr>
                                            <Mtd>Location</Mtd>
                                            <Mtd className="ps-5">Saudi Arebia</Mtd>
                                        </tr>
                                        <tr>
                                            <Mtd>Approved On</Mtd>
                                            <Mtd className="ps-5">14 Dec 2021</Mtd>
                                        </tr>
                                        <tr>
                                            <Mtd>Job Link</Mtd>
                                            <Mtd Link className="ps-5">Get Link</Mtd>
                                        </tr>
                                    </tbody>
                                </table>
                            </MCard>
                        </div>
                    </div>
                </div>

                <div className={toggleState === 2 ? "content  active-content" : "content"}>
                    <div className="card" style={{ border: 'none', padding: '0px' }}>
                        <div className="card-body" style={{ padding: '0px' }}>
                            <MCard className="border  border-2 rounded-3 boxwidth mb-3 p-2">
                                <MCompanyName>Beverage Group - Dubai</MCompanyName>
                                <table>
                                    <tbody>
                                        <tr>
                                            <Mtd>Location</Mtd>
                                            <Mtd className="ps-5">Saudi Arebia</Mtd>
                                        </tr>
                                        <tr>
                                            <Mtd>Reasons</Mtd>
                                            <Mtd Link className="ps-5">View Reason</Mtd>
                                        </tr>
                                        <tr>
                                            <Mtd>Approved On</Mtd>
                                            <Mtd className="ps-5">14 Dec 2021</Mtd>
                                        </tr>
                                        <tr>
                                            <Mtd>Job Link</Mtd>
                                            <Mtd Link className="ps-5">Get Link</Mtd>
                                        </tr>
                                    </tbody>
                                </table>
                            </MCard>

                            <MCard className="border  border-2 rounded-3 boxwidth mb-3 p-2">
                                <MCompanyName>Beverage Group - Dubai</MCompanyName>
                                <table>
                                    <tbody>
                                        <tr>
                                            <Mtd>Location</Mtd>
                                            <Mtd className="ps-5">Saudi Arebia</Mtd>
                                        </tr>
                                        <tr>
                                            <Mtd>Reasons</Mtd>
                                            <Mtd Link className="ps-5">View Reason</Mtd>
                                        </tr>
                                        <tr>
                                            <Mtd>Approved On</Mtd>
                                            <Mtd className="ps-5">14 Dec 2021</Mtd>
                                        </tr>
                                        <tr>
                                            <Mtd>Job Link</Mtd>
                                            <Mtd Link className="ps-5">Get Link</Mtd>
                                        </tr>
                                    </tbody>
                                </table>
                            </MCard>

                            <MCard className="border  border-2 rounded-3 boxwidth mb-3 p-2">
                                <MCompanyName>Beverage Group - Dubai</MCompanyName>
                                <table>
                                    <tbody>
                                        <tr>
                                            <Mtd>Location</Mtd>
                                            <Mtd className="ps-5">Saudi Arebia</Mtd>
                                        </tr>
                                        <tr>
                                            <Mtd>Reasons</Mtd>
                                            <Mtd Link className="ps-5">View Reason</Mtd>
                                        </tr>
                                        <tr>
                                            <Mtd>Approved On</Mtd>
                                            <Mtd className="ps-5">14 Dec 2021</Mtd>
                                        </tr>
                                        <tr>
                                            <Mtd>Job Link</Mtd>
                                            <Mtd Link className="ps-5">Get Link</Mtd>
                                        </tr>
                                    </tbody>
                                </table>
                            </MCard>

                            <MCard className="border  border-2 rounded-3 boxwidth mb-3 p-2">
                                <MCompanyName>Beverage Group - Dubai</MCompanyName>
                                <table>
                                    <tbody>
                                        <tr>
                                            <Mtd>Location</Mtd>
                                            <Mtd className="ps-5">Saudi Arebia</Mtd>
                                        </tr>
                                        <tr>
                                            <Mtd>Reasons</Mtd>
                                            <Mtd Link className="ps-5">View Reason</Mtd>
                                        </tr>
                                        <tr>
                                            <Mtd>Approved On</Mtd>
                                            <Mtd className="ps-5">14 Dec 2021</Mtd>
                                        </tr>
                                        <tr>
                                            <Mtd>Job Link</Mtd>
                                            <Mtd Link className="ps-5">Get Link</Mtd>
                                        </tr>
                                    </tbody>
                                </table>
                            </MCard>

                            <MCard className="border  border-2 rounded-3 boxwidth mb-3 p-2">
                                <MCompanyName>Beverage Group - Dubai</MCompanyName>
                                <table>
                                    <tbody>
                                        <tr>
                                            <Mtd>Location</Mtd>
                                            <Mtd className="ps-5">Saudi Arebia</Mtd>
                                        </tr>
                                        <tr>
                                            <Mtd>Reasons</Mtd>
                                            <Mtd Link className="ps-5">View Reason</Mtd>
                                        </tr>
                                        <tr>
                                            <Mtd>Approved On</Mtd>
                                            <Mtd className="ps-5">14 Dec 2021</Mtd>
                                        </tr>
                                        <tr>
                                            <Mtd>Job Link</Mtd>
                                            <Mtd Link className="ps-5">Get Link</Mtd>
                                        </tr>
                                    </tbody>
                                </table>
                            </MCard>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default MobJobStatistics
