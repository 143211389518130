import React from 'react'
import { StepsArea, Step } from './Dots.style'
import { IsSignUpSlideType } from '../../../../redux/SignIn/SignIn.selector'
import {useSelector } from 'react-redux'

const Dots = () => {
    const currentSignUpState = useSelector(IsSignUpSlideType)

    return (
        <div>
            <div className="m-lg-5 m-3">
                <StepsArea className=" text-center font-lato">
                    {
                        [...Array(+5).keys()].map((item, index) => {
                            return (
                                <Step active={index===currentSignUpState}>{index+1}</Step>)
                        })
                    }

                    {/* <Step>2</Step>
                    <Step>3</Step>
                    <Step>4</Step>
                    <Step>5</Step> */}
                </StepsArea>
            </div>
        </div>
    )
}

export default Dots
