import React, { useState } from "react";
import UserHeader from '../Header/UserHeader/userheader'
import { Container } from "react-bootstrap";
import {
  DeskMenuSection,
  TabsContainer,
  TabsContainerLogOut,
  TabIcon,
  TabName,
  ContentSection,
  ContentSectionTabName,
  ProfilePhotoSection,
  BDProfilePhotoBG,
  ProfileImage,
  UpladBtnImg,
  BDWhiteBox,
  BDPackageTextTitle,
  PackageText,
  PackageButton,
  BDDesktopTable,
  BDMobileTable,
  ButtonBG,
  GoldButton,
  PlatinumButton,
  InformationSection,
  BasicDetailText,
  MrCheckBoxDiv,
  MrCheckBoxMobileDiv,
  Checkbox,
  Checkspan,
  TextDiv,
  TextBox,
  ThemeButton,
  SubHeading,
  InputTitle,
  InputFile,
  RadioSpan,
  RadioButton,
  MobileBottomTab,
  Menus,
  Chpasswidth,
  InpBox,
  ModalHead,
  ThemeCancelButton


} from './BasicUserdashboard.style';
import ProfileIcon from '../../assets/header/drpdown-icon/Group 2134.svg';
import SavedJobsIcon from '../../assets/header/drpdown-icon/Group 2143.svg';
import ChangePassIcon from '../../assets/header/drpdown-icon/Group 2141.svg';
import LogoutIcon from '../../assets/header/drpdown-icon/Group 2139.svg';
import Avatar from '../../assets/body/basic-profile-landing/basic-profile-img.png'
import UploadBtn from '../../assets/body/contact/Group 2144.svg'
import DTable from '../basicLandingPage/Table/table';
import MTable from '../basicLandingPage/Mobiletable/MobileTable'
import { Dropdown } from 'react-dropdown-now';
import ReactTagInput from "@pathofdev/react-tag-input";
import "./tabs.css";
import { PasswordCurrentPass, PasswordNewPass, PasswordConfirmPass } from '../../redux/SignIn/SignIn.action';
import { useDispatch, useSelector } from 'react-redux';
import { IsCurrentPassType, IsNewPassType, IsConfirmPassType } from '../../redux/SignIn/SignIn.selector'
import { Eye, EyeOff, X } from "react-feather";
import {  Modal } from "react-bootstrap";

function Tabs() {


  const [show, setShow] = useState(false);

  const Close = () => setShow(false);
  const Show = () => setShow(true);

  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };
  const [jobtitletags, setjobtitleTags] = React.useState([])

  const [industrytags, setindustryTags] = React.useState([])

  const [joblocationtags, setjoblocationTags] = React.useState([])

  const [checkBox, setcheckBox] = useState(false)

  const dispatch = useDispatch()

  const CurrentPass = useSelector(IsCurrentPassType)
  const NewPass = useSelector(IsNewPassType)
  const ConfirmPass = useSelector(IsConfirmPassType)



  return (
    <>
      <UserHeader />
      <Container>
        <div className="row">
          <DeskMenuSection className="col-md-2">
            <TabsContainer className={toggleState === 1 ? "tabs active-tabs" : "tabs"} onClick={() => toggleTab(1)}>
              <TabIcon src={ProfileIcon} alt="" />
              <TabName>Profile</TabName>
            </TabsContainer>

            <TabsContainer className={toggleState === 2 ? "tabs active-tabs" : "tabs"} onClick={() => toggleTab(2)}>
              <TabIcon src={SavedJobsIcon} alt="" />
              <TabName>Saved Jobs</TabName>
            </TabsContainer>

            <TabsContainer className={toggleState === 3 ? "tabs active-tabs" : "tabs"} onClick={() => toggleTab(3)}>
              <TabIcon src={ChangePassIcon} alt="" />
              <TabName>Change Password</TabName>
            </TabsContainer>

            <TabsContainerLogOut className={toggleState === 4 ? "tabs active-tabs" : "tabs"} to="/signin">
              <TabIcon src={LogoutIcon} alt="" />
              <TabName>Logout</TabName>
            </TabsContainerLogOut>
          </DeskMenuSection>


          <ContentSection className="col-md-10" >
            <div className={toggleState === 1 ? "content  active-content" : "content"}>
              <div className="border-bottom border-2">
                <ContentSectionTabName className="p-2 ps-3 mb-0">My Profile</ContentSectionTabName>
              </div>
              <Container>
                <div className="row">
                  <ProfilePhotoSection className="col-md-4">
                    <BDProfilePhotoBG className="shadow-sm mt-3 bg-white rounded">
                      <ProfileImage src={Avatar} alt="" />
                      <UpladBtnImg src={UploadBtn} slt="" />
                    </BDProfilePhotoBG>

                    <BDWhiteBox className="shadow-sm mt-3 p-4 bg-white rounded">
                      <BDPackageTextTitle>Package</BDPackageTextTitle>
                      <div className="d-flex pt-3">
                        <PackageText >Current Membership</PackageText>
                        <PackageText bold className="ps-4">Basic</PackageText>
                      </div>

                      <PackageButton>
                        <ButtonBG className="mt-4 me-3">
                          <GoldButton to="#">
                            UPGRADE TO GOld
                          </GoldButton>
                        </ButtonBG>
                        <ButtonBG className="mt-4">
                          <PlatinumButton to="#">
                            UPGRADE TO PLATINUM
                          </PlatinumButton>
                        </ButtonBG>
                      </PackageButton>
                    </BDWhiteBox>

                    <BDWhiteBox className="shadow-sm mt-3 p-4 bg-white rounded">
                      <BDPackageTextTitle>Delete My Account</BDPackageTextTitle>
                      <PackageButton className="pt-3">
                        <ButtonBG onClick={Show}>
                          <PlatinumButton to="#" className="">
                            Delete Account
                          </PlatinumButton>
                        </ButtonBG>
                      </PackageButton>
                    </BDWhiteBox>

                    <Modal
                      centered className="modalbody" show={show} onHide={Close}>
                      <ModalHead>
                        <X width="20" color="#fff" strokeWidth="5" onClick={Close} style={{ cursor: 'pointer' }} />
                      </ModalHead>
                      <Modal.Body>
                        <h6 className="text-center fw-bold">ARE YOU SURE YOU WANT TO DELETE YOUR ACCOUNT PERMENTLY ?</h6>

                      </Modal.Body>
                      <div className="text-center mb-3">
                        <ButtonBG onClick={Close}>
                          <ThemeCancelButton CV to="#" className="py-2 px-4">
                            Yes
                          </ThemeCancelButton>
                        </ButtonBG>
                      </div>
                    </Modal>

                  </ProfilePhotoSection>

                  <InformationSection className="col-md-8">
                    <BDWhiteBox className="shadow-sm mt-3 p-md-4 p-2 bg-white rounded">
                      <div className="border-bottom border-2">
                        <BasicDetailText className=" mb-1" >Basic Details</BasicDetailText>
                      </div>

                      <MrCheckBoxDiv className="d-flex flex-wrap justify-content-between align-items-center mt-4 mb-3">
                        <div className="d-flex justify-content-center align-items-center">
                          <Checkbox type="checkbox" /><Checkspan>Mr</Checkspan>
                        </div>
                        <div className="d-flex justify-content-center align-items-center">
                          <Checkbox type="checkbox" /><Checkspan>Mrs</Checkspan>
                        </div>
                        <div className="d-flex justify-content-center align-items-center">
                          <Checkbox type="checkbox" /><Checkspan>Miss</Checkspan>
                        </div>
                        <div className="d-flex justify-content-center align-items-center">
                          <Checkbox type="checkbox" /><Checkspan>Ms</Checkspan>
                        </div>
                        <div className="d-flex justify-content-center align-items-center">
                          <Checkbox type="checkbox" /><Checkspan>Dr</Checkspan>
                        </div>
                        <div className="d-flex justify-content-center align-items-center">
                          <Checkbox type="checkbox" /><Checkspan>Other</Checkspan>
                        </div>
                      </MrCheckBoxDiv>

                      <MrCheckBoxMobileDiv className="mt-4 mb-3">
                        <Dropdown
                          placeholder="Select an option"
                          className="my-className"
                          options={['Mr', 'Mrs', 'Miss', 'Ms', 'Dr', 'Other']}
                          value="Mr"
                          onChange={(value) => console.log('change!', value)}
                          onSelect={(value) => console.log('selected!', value)} // always fires once a selection happens even if there is no change
                          onClose={(closedBySelection) => console.log('closedBySelection?:', closedBySelection)}
                          onOpen={() => console.log('open!')}
                        />
                      </MrCheckBoxMobileDiv>

                      <div className="row">
                        <TextDiv className="col-md-6 col-12">
                          <TextBox type="text" className="form-control" name="name" placeholder="Name" />
                        </TextDiv>
                        <TextDiv className="col-md-6 col-12">
                          <TextBox type="email" className="form-control" name="name" placeholder="Email Id" />
                        </TextDiv>
                      </div>

                      <div className="row">
                        <div className="col-md-6 col-12">
                          <div className="d-flex col-12">
                            <TextDiv className="col-4" >
                              <Dropdown
                                placeholder="Select an option"
                                className="my-className"
                                options={['91+', '54+', '61+', '43+']}
                                value="91+"
                                onChange={(value) => console.log('change!', value)}
                                onSelect={(value) => console.log('selected!', value)} // always fires once a selection happens even if there is no change
                                onClose={(closedBySelection) => console.log('closedBySelection?:', closedBySelection)}
                                onOpen={() => console.log('open!')}
                              />
                            </TextDiv>
                            <TextDiv className="col-8" style={{ borderLeft: '1px solid #ced4da', }}>
                              <TextBox type="number" className="form-control" name="name" placeholder="Phone" />
                            </TextDiv>
                          </div>
                        </div>
                        <div className="col-md-6 col-12">
                          <TextDiv>
                            <TextBox
                              type="text"
                              onFocus={
                                (e) => {
                                  e.currentTarget.type = "date";
                                }
                              }
                              placeholder="Birth Date"
                            />
                          </TextDiv>
                        </div>
                      </div>

                      <TextDiv>
                        <TextBox type="text" className="form-control" name="address" placeholder="Address" />
                      </TextDiv>

                      <ButtonBG>
                        <ThemeButton to="#" className="py-2 px-4">
                          Update
                        </ThemeButton>
                      </ButtonBG>

                      <div className="mt-4 pt-2">
                        <BasicDetailText className=" mb-4" >Desired Profile Details</BasicDetailText>
                      </div>

                      <div className="col inline-block mb-4 mr-2">
                        <SubHeading className=" pb-2">Desired Job Title</SubHeading>
                        <TextDiv>
                          <ReactTagInput
                            tags={jobtitletags}
                            placeholder="Add New"
                            maxTags={10}
                            editable={true}
                            readOnly={false}
                            removeOnBackspace={true}
                            onChange={(newTags) => setjobtitleTags(newTags)}
                          />
                        </TextDiv>
                      </div>

                      <div className="col inline-block mb-4 mr-2">
                        <SubHeading className=" pb-2">Desired Industry</SubHeading>
                        <TextDiv>
                          <ReactTagInput
                            tags={industrytags}
                            placeholder="Add New"
                            maxTags={10}
                            editable={true}
                            readOnly={false}
                            removeOnBackspace={true}
                            onChange={(newTags) => setindustryTags(newTags)}
                          />
                        </TextDiv>
                      </div>

                      <div className="col inline-block mb-4 mr-2">
                        <SubHeading className=" pb-2">Desired Location</SubHeading>
                        <TextDiv>
                          <ReactTagInput
                            tags={joblocationtags}
                            placeholder="Add New"
                            maxTags={10}
                            editable={true}
                            readOnly={false}
                            removeOnBackspace={true}
                            onChange={(newTags) => setjoblocationTags(newTags)}
                          />
                        </TextDiv>
                      </div>

                      <Dropdown
                        placeholder="Desired salary"
                        className="my-className"
                        options={['50K - 60K Per Year', '60K - 70K Per Year', '70K - 80K Per Year', '80K - 90K Per Year']}
                        value="50K - 60K Per Year"
                        onChange={(value) => console.log('change!', value)}
                        onSelect={(value) => console.log('selected!', value)} // always fires once a selection happens even if there is no change
                        onClose={(closedBySelection) => console.log('closedBySelection?:', closedBySelection)}
                        onOpen={() => console.log('open!')}
                      />

                      <div className="mt-4 pt-2">
                        <BasicDetailText className=" mb-4" >Job Details</BasicDetailText>
                      </div>

                      <div className="row">
                        <TextDiv className="col-md-6 col-12">
                          <TextBox type="text" className="form-control" name="uiuxdesign" placeholder="UI UX Designer" />
                        </TextDiv>
                        <TextDiv className="col-md-6 col-12">
                          <TextBox type="email" className="form-control" name="digital_agency" placeholder="Digital Agency" />
                        </TextDiv>
                      </div>

                      <div className="row">
                        <div className="col-md-6 col-12 d-flex">
                          <TextDiv className="col-4" >
                            <Dropdown
                              placeholder="Select an option"
                              className="my-className"
                              options={['USD', 'INR', 'USA', 'RS']}
                              value="USD"
                              onChange={(value) => console.log('change!', value)}
                              onSelect={(value) => console.log('selected!', value)} // always fires once a selection happens even if there is no change
                              onClose={(closedBySelection) => console.log('closedBySelection?:', closedBySelection)}
                              onOpen={() => console.log('open!')}
                            />
                          </TextDiv>
                          <TextDiv className="col-8" style={{ borderLeft: '1px solid #ced4da', }}>
                            <Dropdown
                              placeholder="Select an option"
                              className="my-className"
                              options={['50K - 60K P Y', '60K - 70K P Y', '70K - 80K P Y', '80K - 90K P Y']}
                              value="50K - 60K P Y"
                              onChange={(value) => console.log('change!', value)}
                              onSelect={(value) => console.log('selected!', value)} // always fires once a selection happens even if there is no change
                              onClose={(closedBySelection) => console.log('closedBySelection?:', closedBySelection)}
                              onOpen={() => console.log('open!')}
                            />
                          </TextDiv>
                        </div>
                        <div className="col-md-6 col-12">
                          <div className="d-flex col-12">
                            <TextDiv className="col-4" >
                              <Dropdown
                                placeholder="Select an option"
                                className="my-className"
                                options={['91+', '54+', '61+', '43+']}
                                value="91+"
                                onChange={(value) => console.log('change!', value)}
                                onSelect={(value) => console.log('selected!', value)} // always fires once a selection happens even if there is no change
                                onClose={(closedBySelection) => console.log('closedBySelection?:', closedBySelection)}
                                onOpen={() => console.log('open!')}
                              />
                            </TextDiv>
                            <TextDiv className="col-8" style={{ borderLeft: '1px solid #ced4da', }}>
                              <TextBox type="number" className="form-control" name="name" placeholder="Phone" />
                            </TextDiv>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6 col-12">
                          <TextDiv className="d-flex justify-content-between my-1">
                            <RadioSpan>Hide Email id from recruiters</RadioSpan>
                            <RadioButton onChange={() => setcheckBox(!checkBox)} type="checkbox" value="Hide Email" name="HideEmail" />
                          </TextDiv>
                          <TextDiv className="d-flex justify-content-between my-1">
                            <RadioSpan>Hide Name from recruiters</RadioSpan>
                            <RadioButton onChange={() => setcheckBox(!checkBox)} type="checkbox" value="Hide Email" name="HideEmail" />
                          </TextDiv>
                        </div>
                        <div className="col-md-6 col-12">
                          <TextDiv className="d-flex justify-content-between my-1">
                            <RadioSpan>Recruiters view your CV or Not</RadioSpan>
                            <RadioButton onChange={() => setcheckBox(!checkBox)} type="checkbox" value="Hide Email" name="HideEmail" />
                          </TextDiv>
                          <TextDiv className="d-flex justify-content-between my-1">
                            <RadioSpan>Receive job alerts via email</RadioSpan>
                            <RadioButton onChange={() => setcheckBox(!checkBox)} type="checkbox" value="Hide Email" name="HideEmail" />
                          </TextDiv>
                        </div>
                      </div>


                      <div className="mt-4 pt-2">
                        <BasicDetailText className=" mb-4" >Resume</BasicDetailText>
                      </div>
                      <TextDiv className="mb-2" style={{ justifyContent: 'start', borderBottom: '1px solid black' }}>
                        <InputTitle>Attach Your Resume</InputTitle>
                      </TextDiv>
                      <TextDiv className="mb-2" style={{ justifyContent: 'start' }}>
                        <InputFile type="file" accept="application/pdf,application/vnd.ms-excel" />
                      </TextDiv>

                    </BDWhiteBox>





                  </InformationSection>

                </div>

              </Container>
            </div>

            <div className={toggleState === 2 ? "content  active-content" : "content"}>
              <div className="border-bottom border-2">
                <ContentSectionTabName className="p-2 ps-3 mb-0">Saved Jobs</ContentSectionTabName>
              </div>
              <BDDesktopTable className="m-3">
                <DTable />
              </BDDesktopTable>
              <BDMobileTable className="mt-3">
                <MTable />
              </BDMobileTable>
            </div>

            <div className={toggleState === 3 ? "content  active-content" : "content"}>
              <div className="border-bottom border-2">
                <BasicDetailText className=" p-2 ps-3 mb-0" >Change Password</BasicDetailText>
              </div>
              <BDWhiteBox className="shadow-sm m-3 p-md-4 p-2 bg-white rounded">
                <Chpasswidth className="border rounded p-4 col-7 ">
                  <h6>Current Password</h6>
                  <div className="d-flex justify-content-between border rounded-pill px-3 mb-3 align-items-center">
                    <InpBox type={CurrentPass ? 'text' : 'password'} className="form-control p-1" required />
                    {CurrentPass ? <Eye width="30" color="#0073db" onClick={() => dispatch(PasswordCurrentPass())} /> : <EyeOff width="30" color="#0073db" onClick={() => dispatch(PasswordCurrentPass())} />}
                    {/* {PassType ? <Lock width="30" color="#0073db" onClick={() => changePassType()}/> : <User width="30" color="#0073db" onClick={() => changePassType()}/>} */}
                  </div>

                  <h6>New Password</h6>
                  <div className="d-flex justify-content-between border rounded-pill px-3 mb-3 align-items-center">
                    <InpBox type={NewPass ? 'text' : 'password'} className="form-control p-1" required />
                    {NewPass ? <Eye width="30" color="#0073db" onClick={() => dispatch(PasswordNewPass())} /> : <EyeOff width="30" color="#0073db" onClick={() => dispatch(PasswordNewPass())} />}
                    {/* {PassType ? <Lock width="30" color="#0073db" onClick={() => changePassType()}/> : <User width="30" color="#0073db" onClick={() => changePassType()}/>} */}
                  </div>

                  <h6>Confirm Password</h6>
                  <div className="d-flex justify-content-between border rounded-pill px-3 mb-3 align-items-center">
                    <InpBox type={ConfirmPass ? 'text' : 'password'} className="form-control p-1" required />
                    {ConfirmPass ? <Eye width="30" color="#0073db" onClick={() => dispatch(PasswordConfirmPass())} /> : <EyeOff width="30" color="#0073db" onClick={() => dispatch(PasswordConfirmPass())} />}
                    {/* {PassType ? <Lock width="30" color="#0073db" onClick={() => changePassType()}/> : <User width="30" color="#0073db" onClick={() => changePassType()}/>} */}
                  </div>

                  <ButtonBG className="mt-3">
                    <ThemeButton to="#" className="py-2 px-4">
                      Update
                    </ThemeButton>
                  </ButtonBG>

                </Chpasswidth>
              </BDWhiteBox>
            </div>

            {/* <div className={toggleState === 4 ? "content  active-content" : "content"}>
              <h2>Content 4</h2>
            </div> */}

          </ContentSection>

          <MobileBottomTab className="py-2 d-lg-none d-md-block">
            <Menus className="d-flex justify-content-around">
              <TabIcon src={ProfileIcon} alt="" onClick={() => toggleTab(1)} />
              <TabIcon src={SavedJobsIcon} alt="" onClick={() => toggleTab(2)} />
              <TabIcon src={ChangePassIcon} alt="" onClick={() => toggleTab(3)} />
              <TabIcon src={LogoutIcon} alt="" onClick={() => toggleTab(4)} />
            </Menus>
          </MobileBottomTab>

        </div>


      </Container>
    </>
  );
}

export default Tabs;