import React from 'react'
import { MobileFormName, ImageDiv, FormName, StepThreeImage } from './ThirdStep.style'
import FormThreeImage from '../../../../assets/body/signup/img3.svg';



const ThirdStep = () => {
    return (
        <>
            <MobileFormName>What's your targated profile</MobileFormName>

            <ImageDiv className="">
                <FormName>Let us know more about you</FormName>
                <StepThreeImage src={FormThreeImage} alt="" />
            </ImageDiv>
        </>
    )
}

export default ThirdStep
