import React from 'react'
import { MCard, MCompanyName, Mtd } from './MobileTable.style'

const MobileTable = () => {
    return (
        <>
            <div className="container-fluid bg-white p-0">
                <div className="card" style={{border: 'none', padding: '0px'}}>
                    <div className="card-body" style={{padding: '0px'}}>
                        <MCard className="border  border-2 rounded-3 boxwidth mb-3 p-2">
                            <MCompanyName>Beverage Group - Dubai</MCompanyName>
                            <table>
                                <tbody>
                                    <tr>
                                        <Mtd>Industry</Mtd>
                                        <Mtd className="ps-5">Digital Marketing</Mtd>
                                    </tr>
                                    <tr>
                                        <Mtd>Job Posted On</Mtd>
                                        <Mtd className="ps-5">14 Dec 2021</Mtd>
                                    </tr>
                                    <tr>
                                        <Mtd>Location</Mtd>
                                        <Mtd className="ps-5">Saudi Arebia</Mtd>
                                    </tr>
                                    <tr>
                                        <Mtd>Employee Type</Mtd>
                                        <Mtd className="ps-5">Full Time Permanent</Mtd>
                                    </tr>
                                    <tr>
                                        <Mtd>Salary</Mtd>
                                        <Mtd className="ps-5">Below $80,000/Year</Mtd>
                                    </tr>
                                </tbody>
                            </table>
                        </MCard>

                        <MCard className="border  border-2 rounded-3 boxwidth mb-3 p-2">
                            <MCompanyName>Beverage Group - Dubai</MCompanyName>
                            <table>
                                <tbody>
                                    <tr>
                                        <Mtd>Industry</Mtd>
                                        <Mtd className="ps-5">Digital Marketing</Mtd>
                                    </tr>
                                    <tr>
                                        <Mtd>Job Posted On</Mtd>
                                        <Mtd className="ps-5">14 Dec 2021</Mtd>
                                    </tr>
                                    <tr>
                                        <Mtd>Location</Mtd>
                                        <Mtd className="ps-5">Saudi Arebia</Mtd>
                                    </tr>
                                    <tr>
                                        <Mtd>Employee Type</Mtd>
                                        <Mtd className="ps-5">Full Time Permanent</Mtd>
                                    </tr>
                                    <tr>
                                        <Mtd>Salary</Mtd>
                                        <Mtd className="ps-5">Below $80,000/Year</Mtd>
                                    </tr>
                                </tbody>
                            </table>
                        </MCard>

                        <MCard className="border  border-2 rounded-3 boxwidth mb-3 p-2">
                            <MCompanyName>Beverage Group - Dubai</MCompanyName>
                            <table>
                                <tbody>
                                    <tr>
                                        <Mtd>Industry</Mtd>
                                        <Mtd className="ps-5">Digital Marketing</Mtd>
                                    </tr>
                                    <tr>
                                        <Mtd>Job Posted On</Mtd>
                                        <Mtd className="ps-5">14 Dec 2021</Mtd>
                                    </tr>
                                    <tr>
                                        <Mtd>Location</Mtd>
                                        <Mtd className="ps-5">Saudi Arebia</Mtd>
                                    </tr>
                                    <tr>
                                        <Mtd>Employee Type</Mtd>
                                        <Mtd className="ps-5">Full Time Permanent</Mtd>
                                    </tr>
                                    <tr>
                                        <Mtd>Salary</Mtd>
                                        <Mtd className="ps-5">Below $80,000/Year</Mtd>
                                    </tr>
                                </tbody>
                            </table>
                        </MCard>

                        <MCard className="border  border-2 rounded-3 boxwidth mb-3 p-2">
                            <MCompanyName>Beverage Group - Dubai</MCompanyName>
                            <table>
                                <tbody>
                                    <tr>
                                        <Mtd>Industry</Mtd>
                                        <Mtd className="ps-5">Digital Marketing</Mtd>
                                    </tr>
                                    <tr>
                                        <Mtd>Job Posted On</Mtd>
                                        <Mtd className="ps-5">14 Dec 2021</Mtd>
                                    </tr>
                                    <tr>
                                        <Mtd>Location</Mtd>
                                        <Mtd className="ps-5">Saudi Arebia</Mtd>
                                    </tr>
                                    <tr>
                                        <Mtd>Employee Type</Mtd>
                                        <Mtd className="ps-5">Full Time Permanent</Mtd>
                                    </tr>
                                    <tr>
                                        <Mtd>Salary</Mtd>
                                        <Mtd className="ps-5">Below $80,000/Year</Mtd>
                                    </tr>
                                </tbody>
                            </table>
                        </MCard>

                        <MCard className="border  border-2 rounded-3 boxwidth mb-3 p-2">
                            <MCompanyName>Beverage Group - Dubai</MCompanyName>
                            <table>
                                <tbody>
                                    <tr>
                                        <Mtd>Industry</Mtd>
                                        <Mtd className="ps-5">Digital Marketing</Mtd>
                                    </tr>
                                    <tr>
                                        <Mtd>Job Posted On</Mtd>
                                        <Mtd className="ps-5">14 Dec 2021</Mtd>
                                    </tr>
                                    <tr>
                                        <Mtd>Location</Mtd>
                                        <Mtd className="ps-5">Saudi Arebia</Mtd>
                                    </tr>
                                    <tr>
                                        <Mtd>Employee Type</Mtd>
                                        <Mtd className="ps-5">Full Time Permanent</Mtd>
                                    </tr>
                                    <tr>
                                        <Mtd>Salary</Mtd>
                                        <Mtd className="ps-5">Below $80,000/Year</Mtd>
                                    </tr>
                                </tbody>
                            </table>
                        </MCard>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MobileTable
