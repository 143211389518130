import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Switch, } from "react-router-dom";
import JobSearch from "./component/JobSearch/jobsearch";
import Ourservice from "./component/OurService/Ourservice";
import SignUp from "./component/SignUp/Index/SignUpIndex";
import SignIn from "./component/SignIn/SignIn";
import Forgotpassword from "./component/ForgotPassword/forgotPassword";
import BasicJobDetail from "./component/basicJobDetail/index/basicJobDetail";
import BasicLandingPage from "./component/basicLandingPage/Index/BasicLandingPage";
import BasicUserDashboard from "./component/basicUserDashboard/BasicUserdashboard";
import AboutUs from "./component/About Us/AboutUs";
import ContactUs from "./component/Contact/Contact";
import PrivacyPolicy from "./component/Privacy Policy/PrivacyPolicy";
import TermsAndConditions from "./component/Terms & Condition/TermCondition";
import SearchNotFound from "./component/Search Not Found/SearchNotFound";
import ThankYou from "./component/Thank You/ThankYou";
import Footer from "./component/footer/footer";
import GoldJobDetail from "./component/Gold Job Detail/GoldJobDetail";
import GoldLandingPage from "./component/Gold Landing Page/GoldLandingPage";
import GoldUserDashboard from "./component/Gold User Dashboard/GoldUserDashboard";
import PlatinumLandingPage from "./component/Platinum Landing Page/PlatinumLandingPage";
import PlatinumJobDetail from "./component/Platinum Job Detail/PlatinumJobDetail";
import PlatinumUserDashboard from "./component/Platinum User Dashboard/PlatinumUserDashboard";

function App() {
  return (
    <Router>
      <div className="App">
        <Switch>
          <Route exact path="/" component={JobSearch} /> 
          <Route exact path="/ourservice" component={Ourservice} /> 
          <Route exact path="/signup" component={SignUp} /> 
          <Route exact path="/signin" component={SignIn} /> 
          <Route exact path="/forgotpassword" component={Forgotpassword} /> 
          <Route exact path="/basicjobdetail" component={BasicJobDetail} /> 
          <Route exact path="/basiclandpage" component={BasicLandingPage} /> 
          <Route exact path="/basicuserdashboard" component={BasicUserDashboard} /> 
          <Route exact path="/aboutUs" component={AboutUs} /> 
          <Route exact path="/contactUs" component={ContactUs} /> 
          <Route exact path="/privacypolicy" component={PrivacyPolicy} /> 
          <Route exact path="/temsandcondition" component={TermsAndConditions} /> 
          <Route exact path="/searchnotfound" component={SearchNotFound} /> 
          <Route exact path="/thankyou" component={ThankYou} /> 
          <Route exact path="/goldjobdetail" component={GoldJobDetail} /> 
          <Route exact path="/goldlandingpage" component={GoldLandingPage} /> 
          <Route exact path="/golduserdashboard" component={GoldUserDashboard} /> 
          <Route exact path="/platinumjobdetail" component={PlatinumJobDetail} /> 
          <Route exact path="/platinumlandingpage" component={PlatinumLandingPage} /> 
          <Route exact path="/platinumuserdashboard" component={PlatinumUserDashboard} /> 
        </Switch> 
        <Footer />
      </div> 
    </Router>
  );
}
export default App;
