import React from "react";
import { Container } from "react-bootstrap";
import Header from '../Header/header'
import {
    BlueBgDiv,
    WhiteBox,
    ImageBg,
    SignInImage,
    FormDiv,
    TitleText,
    InpFlex,
    InpBox,
    ForgotPass,
    ForgotPassText,
    RemberMe,
    Checkbox,
    Checkspan,
    SignInButton,
    SignUpButtonDiv,
    SignUpButtonText,
    SignUpButton
} from "./SignIn.style";
import Image from "../../assets/body/signin/img1.svg";
import { User, Lock, Eye, EyeOff } from "react-feather";
import { useHistory } from "react-router-dom";
import { PasswordChangeType } from '../../redux/SignIn/SignIn.action';
import { useDispatch, useSelector} from 'react-redux';
import { IsPasswordType } from '../../redux/SignIn/SignIn.selector'



const signin = () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    let history = useHistory();

    function SignUp() {
        history.push("/signup");
    }

    function SignIn() {
        console.log('hiih');
        history.push("/basiclandpage");
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    // const [passshown, setpassshown] = useState(false)

    // function changePassType() {
    //     setpassshown(!passshown)
    // }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const dispatch = useDispatch()

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const PassType = useSelector(IsPasswordType)

    return (
        <div>
            <Header />
            <BlueBgDiv>
                <Container>
                    <div className="row">
                        <div className="mb-5 mt-5">
                            <WhiteBox className="col col-md-8 m-auto">
                                <div className="m-auto d-flex flex-wrap text-center">
                                    <ImageBg className="col-12 col-md-6 bg-white py-5 px-4">
                                        <SignInImage src={Image} alt="" />
                                    </ImageBg>
                                    <FormDiv className="col-md-6" >
                                        <div className="pt-5 pb-3 px-4">
                                            <form method="POST">
                                            <TitleText>Sign In</TitleText>
                                            <InpFlex className="d-flex">
                                                <User width="30" color="#0073db" />
                                                <InpBox type="text" className="form-control" placeholder="User Name" required/>
                                            </InpFlex>
                                            <InpFlex className="d-flex">
                                                <Lock width="30" color="#0073db" />
                                                <InpBox type={PassType ? 'text' : 'password'} className="form-control" placeholder="Password" required/>
                                                {PassType ? <Eye width="30" color="#0073db" onClick={() => dispatch(PasswordChangeType())}/> : <EyeOff width="30" color="#0073db" onClick={() => dispatch(PasswordChangeType())}/>}
                                                {/* {PassType ? <Lock width="30" color="#0073db" onClick={() => changePassType()}/> : <User width="30" color="#0073db" onClick={() => changePassType()}/>} */}

                                            </InpFlex>
                                            
                                            <ForgotPass className="justify-content-between">
                                                <ForgotPassText to='./forgotpassword'>Forgot Password ?</ForgotPassText>
                                                <RemberMe className="d-flex">
                                                    <Checkbox type="checkbox" />
                                                    <Checkspan>Keep me signed in on this device</Checkspan>
                                                </RemberMe>
                                            </ForgotPass>
                                            <SignInButton onClick={SignIn}>Sign In</SignInButton>
                                            </form>
                                        </div>

                                        <SignUpButtonDiv>
                                            <SignUpButtonText>Not a Member yet? Sign Up</SignUpButtonText>
                                            <SignUpButton onClick={SignUp}>Sign Up</SignUpButton>
                                        </SignUpButtonDiv>
                                    </FormDiv>
                                </div>
                            </WhiteBox>
                        </div>
                    </div>
                </Container>
            </BlueBgDiv>
        </div>
    );
};

export default signin;
