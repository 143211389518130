import styled from "styled-components";
import { color } from "../../../../styles/variable.styles";

export const TextDiv = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`;

export const FormDiv = styled.div `
  padding: 48px;
  @media (max-width: 1024px) {
    padding: 25px 12px;
    width: 100%;
  }
`;
export const InputTitle = styled.p `
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 5px;
`;
export const InputFile = styled.input `
  color: ${color.theme};
  font-size: 16px;
  font-weight: 500;
`;
export const Buttons = styled.button `
  border: 1px solid ${color.theme} !important;
  background-color: ${color.theme};
  color: ${color.regularfontwhite};
  border-radius: 50px;
  text-transform: uppercase;
  padding: 0.5rem 1.5rem;
  font-size: 13px;
  font-weight: 500;
  margin: 10px 0;
  transition: 0.3s;
  border: none;
`;

export const ButtonDiv = styled.div `
  display: flex;
`;