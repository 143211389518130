import React from 'react'
import FirstStep from '../First Step/FirstStep'
import SecondStep from '../Second Step/SecondStep'
import ThirdStep from '../Third Step/ThirdStep'
import FourthStep from '../Fourth Step/FourthStep'
import FifthStep from '../Fifth Step/FifthStep'
import Dots from '../../LeftSide/Dots/Dots'
import { DotContainer } from './Index.style'
import { IsSignUpSlideType } from '../../../../redux/SignIn/SignIn.selector'
import {useSelector } from 'react-redux'

const Index = () => {
    const currentSignUpState = useSelector(IsSignUpSlideType)

    return (
        <div>
            <DotContainer>
            <Dots />
            </DotContainer>
            <div>
                {currentSignUpState === 0 ? (
                    <FirstStep />
                ) : currentSignUpState === 1 ? (
                    <SecondStep />
                ) : currentSignUpState === 2 ? (
                    <ThirdStep />
                ) : currentSignUpState === 3 ? (
                    <FourthStep />
                ) : (
                    <FifthStep />
                )}
            </div>

        </div>
    )
}

export default Index
