import styled from "styled-components";
import { color } from '../../styles/variable.styles';
import { Link } from 'react-router-dom';

export const ThankYouContainer = styled.div `
  background-color: ${color.theme};
  padding: 12% 0;
  @media (max-width: 426px){
      padding: 30% 0;
  }
`;

export const ThankYouImgContainer = styled.img ``;

export const Text = styled.h1 `
  color: ${color.regularfontwhite};
  font-size: 36px;
  font-weight: 900;
`;

export const SubText = styled.p `
    color: ${color.regularfontwhite};
    text-transform: uppercase;
    font-weight: 600;
`;

export const Btn = styled(Link)
`
    background-color: ${color.regularfontwhite};
    border: 1px solid ${color.regularfontwhite};
    color: ${color.theme};
    font-weight: bold;
    cursor: pointer;
    border-radius: 50px;
    text-decoration: none !important;

`;