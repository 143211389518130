import React from "react";
import UserHeader from '../../Header/UserHeader/userheader'
import BluePart from "../FirstProfileInfoSec/BluePart";
import {
  TablePadding,
  TableTitleContainer,
  TableTitleText,
  DeskTableDiv,
  MobileTableDiv
} from './BasicLandingPage.style'
import Table from "../Table/table";
import MobileTable from '../Mobiletable/MobileTable'

const BasicLandingPage = () => {
  return (
    <div>
      <UserHeader />
      <BluePart />
      <TablePadding>
        <div className="container-fluid">
          <TableTitleContainer className="d-flex mb-3 mt-3">
            <TableTitleText>Latest</TableTitleText>
            <TableTitleText blue>Jobs</TableTitleText>
          </TableTitleContainer>
          <DeskTableDiv>
            <Table />
          </DeskTableDiv>
          <MobileTableDiv>
            <MobileTable />
          </MobileTableDiv>
        </div>
      </TablePadding>
    </div>
  );
};

export default BasicLandingPage;
