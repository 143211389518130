import React from 'react'
import { MobileFormName, ImageDiv, FormName, StepFiveImage, } from './FifthStep.style'
import FormFiveImage from '../../../../assets/body/signup/img5.svg';



const FifthStep = () => {
    return (
        <>
                        <MobileFormName>All set to go</MobileFormName>

            <ImageDiv className="">
                <FormName>All set to go</FormName>
                <StepFiveImage src={FormFiveImage} alt="" />
            </ImageDiv>
        </>
    )
}

export default FifthStep
