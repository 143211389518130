import React, { useState } from 'react'
import { JSMobileTabsContainer, JSTabs, JSTabsTitle, CountContainer, ContsDiv, JobsCount, TableHead, TableHeadTitle, TableContent } from './DeskJobStatistics.style'
import '../Mobile/jsmobiletabs.css';

const DeskJobStatistics = () => {
    const [toggleState, setToggleState] = useState(1);

    const toggleTab = (index) => {
        setToggleState(index);
    };
    return (
        <div className="container-fluid row">
            <JSMobileTabsContainer className=" col-4 d-flex">
                <JSTabs LeftRadius className={toggleState === 1 ? "jstabs working-tabs" : "jstabs"} onClick={() => toggleTab(1)}>
                    <JSTabsTitle>Approved</JSTabsTitle>
                </JSTabs>
                <JSTabs RightRadius className={toggleState === 2 ? "jstabs working-tabs" : "jstabs"} onClick={() => toggleTab(2)}>
                    <JSTabsTitle>Rejected</JSTabsTitle>
                </JSTabs>
            </JSMobileTabsContainer>
            <CountContainer className="col-8 d-flex justify-content-end align-items-center">
                <ContsDiv >
                    <JobsCount theme>1324</JobsCount>
                    <JobsCount>Total Jobs</JobsCount>
                </ContsDiv>
                <ContsDiv className="mx-2">
                    <JobsCount gold >800</JobsCount>
                    <JobsCount>Approved Jobs</JobsCount>
                </ContsDiv>
                <ContsDiv>
                    <JobsCount platinum >524</JobsCount>
                    <JobsCount>Rejected Jobs</JobsCount>
                </ContsDiv>
            </CountContainer>
            <div className="container-fluid bg-white p-3">
                <div className={toggleState === 1 ? "content  active-content" : "content"}>
                    <TableHead className="d-flex col-12">
                        <div className="col-5">
                            <TableHeadTitle className="p-2">Job Title</TableHeadTitle>
                        </div>
                        <div className="col-3">
                            <TableHeadTitle className="p-2">Location</TableHeadTitle>
                        </div>
                        <div className="col-3">
                            <TableHeadTitle className="p-2">Approved On</TableHeadTitle>
                        </div>
                        <div className="col-1">
                            <TableHeadTitle className="p-2">Job Link</TableHeadTitle>
                        </div>
                    </TableHead>

                    <div className="mt-2 border col-12">
                        <div className="col-12 d-flex border-bottom">
                            <div className="col-5">
                                <TableContent className=" p-2">Head Of Monitoring &amp; Diagnostic Center</TableContent>
                            </div>
                            <div className="col-3">
                                <TableContent orgcolor className=" p-2" >New Mumbai, India</TableContent>
                            </div>
                            <div className="col-3">
                                <TableContent className="p-2">14 Dec 2021</TableContent>
                            </div>
                            <div className="col-1">
                                <TableContent Decoration className=" text-primary p-2">Get Link</TableContent>
                            </div>
                        </div>

                        <div className="col-12 d-flex border-bottom">
                            <div className="col-5">
                                <TableContent className=" p-2">Head Of Monitoring &amp; Diagnostic Center</TableContent>
                            </div>
                            <div className="col-3">
                                <TableContent orgcolor className=" p-2" >New Mumbai, India</TableContent>
                            </div>
                            <div className="col-3">
                                <TableContent className="p-2">14 Dec 2021</TableContent>
                            </div>
                            <div className="col-1">
                                <TableContent Decoration className=" text-primary p-2">Get Link</TableContent>
                            </div>
                        </div> 

                        <div className="col-12 d-flex border-bottom">
                            <div className="col-5">
                                <TableContent className=" p-2">Head Of Monitoring &amp; Diagnostic Center</TableContent>
                            </div>
                            <div className="col-3">
                                <TableContent orgcolor className=" p-2" >New Mumbai, India</TableContent>
                            </div>
                            <div className="col-3">
                                <TableContent className="p-2">14 Dec 2021</TableContent>
                            </div>
                            <div className="col-1">
                                <TableContent Decoration className=" text-primary p-2">Get Link</TableContent>
                            </div>
                        </div> 

                        <div className="col-12 d-flex border-bottom">
                            <div className="col-5">
                                <TableContent className=" p-2">Head Of Monitoring &amp; Diagnostic Center</TableContent>
                            </div>
                            <div className="col-3">
                                <TableContent orgcolor className=" p-2" >New Mumbai, India</TableContent>
                            </div>
                            <div className="col-3">
                                <TableContent className="p-2">14 Dec 2021</TableContent>
                            </div>
                            <div className="col-1">
                                <TableContent Decoration className=" text-primary p-2">Get Link</TableContent>
                            </div>
                        </div> 

                        <div className="col-12 d-flex border-bottom">
                            <div className="col-5">
                                <TableContent className=" p-2">Head Of Monitoring &amp; Diagnostic Center</TableContent>
                            </div>
                            <div className="col-3">
                                <TableContent orgcolor className=" p-2" >New Mumbai, India</TableContent>
                            </div>
                            <div className="col-3">
                                <TableContent className="p-2">14 Dec 2021</TableContent>
                            </div>
                            <div className="col-1">
                                <TableContent Decoration className=" text-primary p-2">Get Link</TableContent>
                            </div>
                        </div> 

                        <div className="col-12 d-flex border-bottom">
                            <div className="col-5">
                                <TableContent className=" p-2">Head Of Monitoring &amp; Diagnostic Center</TableContent>
                            </div>
                            <div className="col-3">
                                <TableContent orgcolor className=" p-2" >New Mumbai, India</TableContent>
                            </div>
                            <div className="col-3">
                                <TableContent className="p-2">14 Dec 2021</TableContent>
                            </div>
                            <div className="col-1">
                                <TableContent Decoration className=" text-primary p-2">Get Link</TableContent>
                            </div>
                        </div> 

                        <div className="col-12 d-flex border-bottom">
                            <div className="col-5">
                                <TableContent className=" p-2">Head Of Monitoring &amp; Diagnostic Center</TableContent>
                            </div>
                            <div className="col-3">
                                <TableContent orgcolor className=" p-2" >New Mumbai, India</TableContent>
                            </div>
                            <div className="col-3">
                                <TableContent className="p-2">14 Dec 2021</TableContent>
                            </div>
                            <div className="col-1">
                                <TableContent Decoration className=" text-primary p-2">Get Link</TableContent>
                            </div>
                        </div>                
                    </div>
                </div>

                <div className={toggleState === 2 ? "content  active-content" : "content"}>
                    <TableHead className="d-flex col-12">
                        <div className="col-4">
                            <TableHeadTitle className="p-2">Job Title</TableHeadTitle>
                        </div>
                        <div className="col-3">
                            <TableHeadTitle className="p-2">Location</TableHeadTitle>
                        </div>
                        <div className="col-2">
                            <TableHeadTitle className="p-2">Reasons</TableHeadTitle>
                        </div>
                        <div className="col-2">
                            <TableHeadTitle className="p-2">Approved On</TableHeadTitle>
                        </div>
                        <div className="col-1">
                            <TableHeadTitle className="p-2">Job Link</TableHeadTitle>
                        </div>
                    </TableHead>

                    <div className="mt-2 border col-12">
                        <div className="col-12 d-flex border-bottom">
                            <div className="col-4">
                                <TableContent className=" p-2">Head Of Monitoring &amp; Diagnostic Center</TableContent>
                            </div>
                            <div className="col-3">
                                <TableContent orgcolor className=" p-2" >New Mumbai, India</TableContent>
                            </div>
                            <div className="col-2">
                                <TableContent Decoration className=" text-primary p-2">View Reason</TableContent>
                            </div>
                            <div className="col-2">
                                <TableContent className="p-2">14 Dec 2021</TableContent>
                            </div>
                            <div className="col-1">
                                <TableContent Decoration className=" text-primary p-2">Get Link</TableContent>
                            </div>
                        </div>

                        <div className="col-12 d-flex border-bottom">
                            <div className="col-4">
                                <TableContent className=" p-2">Head Of Monitoring &amp; Diagnostic Center</TableContent>
                            </div>
                            <div className="col-3">
                                <TableContent orgcolor className=" p-2" >New Mumbai, India</TableContent>
                            </div>
                            <div className="col-2">
                                <TableContent Decoration className=" text-primary p-2">View Reason</TableContent>
                            </div>
                            <div className="col-2">
                                <TableContent className="p-2">14 Dec 2021</TableContent>
                            </div>
                            <div className="col-1">
                                <TableContent Decoration className=" text-primary p-2">Get Link</TableContent>
                            </div>
                        </div>

                        <div className="col-12 d-flex border-bottom">
                            <div className="col-4">
                                <TableContent className=" p-2">Head Of Monitoring &amp; Diagnostic Center</TableContent>
                            </div>
                            <div className="col-3">
                                <TableContent orgcolor className=" p-2" >New Mumbai, India</TableContent>
                            </div>
                            <div className="col-2">
                                <TableContent Decoration className=" text-primary p-2">View Reason</TableContent>
                            </div>
                            <div className="col-2">
                                <TableContent className="p-2">14 Dec 2021</TableContent>
                            </div>
                            <div className="col-1">
                                <TableContent Decoration className=" text-primary p-2">Get Link</TableContent>
                            </div>
                        </div>

                        <div className="col-12 d-flex border-bottom">
                            <div className="col-4">
                                <TableContent className=" p-2">Head Of Monitoring &amp; Diagnostic Center</TableContent>
                            </div>
                            <div className="col-3">
                                <TableContent orgcolor className=" p-2" >New Mumbai, India</TableContent>
                            </div>
                            <div className="col-2">
                                <TableContent Decoration className=" text-primary p-2">View Reason</TableContent>
                            </div>
                            <div className="col-2">
                                <TableContent className="p-2">14 Dec 2021</TableContent>
                            </div>
                            <div className="col-1">
                                <TableContent Decoration className=" text-primary p-2">Get Link</TableContent>
                            </div>
                        </div>

                        <div className="col-12 d-flex border-bottom">
                            <div className="col-4">
                                <TableContent className=" p-2">Head Of Monitoring &amp; Diagnostic Center</TableContent>
                            </div>
                            <div className="col-3">
                                <TableContent orgcolor className=" p-2" >New Mumbai, India</TableContent>
                            </div>
                            <div className="col-2">
                                <TableContent Decoration className=" text-primary p-2">View Reason</TableContent>
                            </div>
                            <div className="col-2">
                                <TableContent className="p-2">14 Dec 2021</TableContent>
                            </div>
                            <div className="col-1">
                                <TableContent Decoration className=" text-primary p-2">Get Link</TableContent>
                            </div>
                        </div>

                        <div className="col-12 d-flex border-bottom">
                            <div className="col-4">
                                <TableContent className=" p-2">Head Of Monitoring &amp; Diagnostic Center</TableContent>
                            </div>
                            <div className="col-3">
                                <TableContent orgcolor className=" p-2" >New Mumbai, India</TableContent>
                            </div>
                            <div className="col-2">
                                <TableContent Decoration className=" text-primary p-2">View Reason</TableContent>
                            </div>
                            <div className="col-2">
                                <TableContent className="p-2">14 Dec 2021</TableContent>
                            </div>
                            <div className="col-1">
                                <TableContent Decoration className=" text-primary p-2">Get Link</TableContent>
                            </div>
                        </div>

                        <div className="col-12 d-flex border-bottom">
                            <div className="col-4">
                                <TableContent className=" p-2">Head Of Monitoring &amp; Diagnostic Center</TableContent>
                            </div>
                            <div className="col-3">
                                <TableContent orgcolor className=" p-2" >New Mumbai, India</TableContent>
                            </div>
                            <div className="col-2">
                                <TableContent Decoration className=" text-primary p-2">View Reason</TableContent>
                            </div>
                            <div className="col-2">
                                <TableContent className="p-2">14 Dec 2021</TableContent>
                            </div>
                            <div className="col-1">
                                <TableContent Decoration className=" text-primary p-2">Get Link</TableContent>
                            </div>
                        </div>

                        <div className="col-12 d-flex border-bottom">
                            <div className="col-4">
                                <TableContent className=" p-2">Head Of Monitoring &amp; Diagnostic Center</TableContent>
                            </div>
                            <div className="col-3">
                                <TableContent orgcolor className=" p-2" >New Mumbai, India</TableContent>
                            </div>
                            <div className="col-2">
                                <TableContent Decoration className=" text-primary p-2">View Reason</TableContent>
                            </div>
                            <div className="col-2">
                                <TableContent className="p-2">14 Dec 2021</TableContent>
                            </div>
                            <div className="col-1">
                                <TableContent Decoration className=" text-primary p-2">Get Link</TableContent>
                            </div>
                        </div>

                        <div className="col-12 d-flex border-bottom">
                            <div className="col-4">
                                <TableContent className=" p-2">Head Of Monitoring &amp; Diagnostic Center</TableContent>
                            </div>
                            <div className="col-3">
                                <TableContent orgcolor className=" p-2" >New Mumbai, India</TableContent>
                            </div>
                            <div className="col-2">
                                <TableContent Decoration className=" text-primary p-2">View Reason</TableContent>
                            </div>
                            <div className="col-2">
                                <TableContent className="p-2">14 Dec 2021</TableContent>
                            </div>
                            <div className="col-1">
                                <TableContent Decoration className=" text-primary p-2">Get Link</TableContent>
                            </div>
                        </div>           
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DeskJobStatistics
