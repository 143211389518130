import styled from "styled-components";
import { Link } from "react-router-dom";
import { color } from "../../../styles/variable.styles";

export const JSJobLocationDiv = styled.div ``;

export const JSJobLocationTitle = styled.h3 `
  font-size: 24px;
  font-weight: 900;
  text-transform: uppercase;
  margin-bottom: 0;
`;

export const JSJobLocationViewAll = styled(Link)
`
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 0;
  text-decoration: none;
  color: ${color.theme};
`;

export const JSJobLocationCart = styled.div ``;

export const BackgroundImgDiv = styled.div `
  min-height: 200px;
  border-radius: 20px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  width: 100%;
  -webkit-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
  :hover {
    background-size: 150%;

  }
  ::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: #2967bc;
    opacity: 0.7;
    border-radius: 20px;
  }
`;

export const LocationNameDiv = styled.div `
  padding: 100px;
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 1;
  color: #fff;
  text-align: center;
`;

export const LocationName = styled.h3 `
  font-size: 24px;
  font-weight: 800;
  margin-bottom: 0 !important;
  text-transform: uppercase;
`;

export const Jobs = styled.h4 `
  font-size: 17px;
  font-weight: 700;
  margin-bottom: 0 !important;
  text-transform: uppercase;
`;