import styled from "styled-components";
import { color } from "../../styles/variable.styles";

export const OurserviceBgImg = styled.div `
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top center;
`;
export const OurServiceTitle = styled.h1 `
  font-size: 24px;
  font-weight: 900;
  text-transform: uppercase;
`;

export const OurServiceBody = styled.div `
  flex-flow: row wrap;
  justify-content: center;
  margin: 5px 0;
  padding-bottom: 15px;
`;

export const OurServices = styled.div `
  flex: 0 0 auto;
  background-color: rgba(223, 238, 252, 0.95);
  margin: 0 15px;
  padding: 20px;
  border-radius: 20px;
  border: 1px solid #0073db;
`;

export const BasicServiceImgContainer = styled.div ``;

export const BasicServiceImgBox = styled.img `
  width: 120px;
`;

export const TextContainer = styled.div ``;

export const ServiceText = styled.p `
  font-size: 12px;
  color: black;
  font-weight: 600;
  text-align: justify;
`;

export const OurServiceSubscribeButton = styled.button `
  border: 1px solid ${color.theme};
  background-color: ${color.theme};
  color: ${color.regularfontwhite};
  border-radius: 50px;
  text-transform: uppercase;
  padding: 10px 18px;
  font-size: 13px;
  font-weight: 500;
  margin: 10px 0;
  transition: 0.3s;
  &:hover {
    background-color: ${color.regularfontwhite};
    color: ${color.theme};
  }
`;