import styled from "styled-components";

export const DotContainer = styled.div `
display: none;
background: #0073db;
padding: 1px;
margin-top: 25px;
@media (max-width: 426px){
    display: block;
}
`;