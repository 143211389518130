import { SigninActionTypes } from './Sign.types'

export const PasswordChangeType = () => ({
    type: SigninActionTypes.PASSWORD_SHOW_HIDE
})

export const PasswordCurrentPass = () => ({
    type: SigninActionTypes.PASSWORD_CURRENT_PASS
})

export const PasswordNewPass = () => ({
    type: SigninActionTypes.PASSWORD_NEW_PASS
})

export const PasswordConfirmPass = () => ({
    type: SigninActionTypes.PASSWORD_CONFIRM_PASS
})

export const SignUpSlideIncrease = (data) => ({
    type: SigninActionTypes.SIGNUP_SLIDE_INCREASE,
    payload: data
})

export const SignUpSlideDecrease = (data) => ({
    type: SigninActionTypes.SIGNUP_SLIDE_DECREASE,
    payload: data
})