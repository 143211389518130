import React from 'react'
import UserHeader from '../Header/UserHeader/userheader';
import BluePart from './FirstProfileInfoSec/BluePart';
import {
    TablePadding,
    TableTitleContainer,
    TableTitleText,
    DeskTableDiv,
    MobileTableDiv
} from './PlatinumLandingPage.style'
import Table from "../basicLandingPage/Table/table";
import MobileTable from '../basicLandingPage/Mobiletable/MobileTable'

const PlatinumLandingPage = () => {
    return (
        <>
          <UserHeader />
            <BluePart />
            <TablePadding>
                <div className="container-fluid">
                    <TableTitleContainer className="d-flex mb-3 mt-3">
                        <TableTitleText>Latest</TableTitleText>
                        <TableTitleText blue>Jobs</TableTitleText>
                    </TableTitleContainer>
                    <DeskTableDiv>
                        <Table />
                    </DeskTableDiv>
                    <MobileTableDiv>
                        <MobileTable />
                    </MobileTableDiv>
                </div>
            </TablePadding>  
        </>
    )
}

export default PlatinumLandingPage
