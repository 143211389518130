import React from 'react';
import Header from '../Header/header'
import OurServiceImg from '../../assets/body/services/bg.svg'
import { Container } from 'react-bootstrap';
import { OurserviceBgImg, OurServiceTitle, OurServiceBody, OurServices, BasicServiceImgContainer, BasicServiceImgBox, TextContainer, ServiceText, OurServiceSubscribeButton } from './OurService.style'
import BasicServiceImg from '../../assets/body/services/basic.svg';
import GoldServiceImg from '../../assets/body/services/gold.svg';
import PlatinumServiceImg from '../../assets/body/services/platinum.svg';


const Ourservice = () => {
    return (
        <div>
            <Header />
            <div style={{ backgroundColor: '#e7f3fe', paddingBottom: '60px' }}>
                <OurserviceBgImg style={{ backgroundImage: `url(${OurServiceImg})` }}>
                    <Container>
                        <div className="row">
                            <OurServiceTitle className="mt-5 mb-3">OUR SERVICES</OurServiceTitle>
                            <div className="row">
                                <OurServiceBody className="col d-md-flex text-center">
                                    <OurServices className="my-3  col-12 col-lg-3">
                                        <BasicServiceImgContainer className="my-4">
                                            <BasicServiceImgBox src={BasicServiceImg} alt="" />
                                        </BasicServiceImgContainer>
                                        <TextContainer className="pb-1">
                                            <ServiceText>Gold Membership is designed to assist jobseekers with their job search by reducing the number of hours they put on different job sites to identify the right jobs for themselves. Take advantage of our teams effort in sourcing the best jobs to reduce the time that you put on different job site.</ServiceText>
                                            <ServiceText>The key part of this membership is to connect you directly with the top head-hunters/recruiters in the area so you can develop your network and access job that were never advertised. You can create your own profile that will enable recruiters and head-hunters/recruiters to identify you.</ServiceText>
                                        </TextContainer>
                                        <OurServiceSubscribeButton>Subscribe</OurServiceSubscribeButton>
                                    </OurServices>
                                    <OurServices style={{ borderColor: '#db7c00' }} className="my-3  col-12 col-lg-3">
                                        <BasicServiceImgContainer className="my-4">
                                            <BasicServiceImgBox src={GoldServiceImg} alt="" />
                                        </BasicServiceImgContainer>
                                        <TextContainer className="pb-1">
                                            <ServiceText>Gold Membership is designed to assist jobseekers with their job search by reducing the number of hours they put on different job sites to identify the right jobs for themselves. Take advantage of our teams effort in sourcing the best jobs to reduce the time that you put on different job site.</ServiceText>
                                            <ServiceText>The key part of this membership is to connect you directly with the top head-hunters/recruiters in the area so you can develop your network and access job that were never advertised. You can create your own profile that will enable recruiters and head-hunters/recruiters to identify you.</ServiceText>
                                        </TextContainer>
                                        <OurServiceSubscribeButton>Subscribe</OurServiceSubscribeButton>
                                    </OurServices>
                                    <OurServices style={{ borderColor: '#db007c' }} className="my-3  col-12 col-lg-3">
                                        <BasicServiceImgContainer className="my-4">
                                            <BasicServiceImgBox src={PlatinumServiceImg} alt="" />
                                        </BasicServiceImgContainer>
                                        <TextContainer className="pb-1">
                                            <ServiceText>Gold Membership is designed to assist jobseekers with their job search by reducing the number of hours they put on different job sites to identify the right jobs for themselves. Take advantage of our teams effort in sourcing the best jobs to reduce the time that you put on different job site.</ServiceText>
                                            <ServiceText>The key part of this membership is to connect you directly with the top head-hunters/recruiters in the area so you can develop your network and access job that were never advertised. You can create your own profile that will enable recruiters and head-hunters/recruiters to identify you.</ServiceText>
                                        </TextContainer>
                                        <OurServiceSubscribeButton>Subscribe</OurServiceSubscribeButton>
                                    </OurServices>
                                </OurServiceBody>

                            </div>
                        </div>
                    </Container>
                </OurserviceBgImg>
            </div>

        </div>
    )
}

export default Ourservice
