import React, { useState } from 'react'
import UserHeader from '../Header/UserHeader/userheader';
import BluePartForm from '../basicJobDetail/firstdivflex/firstdiv'
import { Container } from 'react-bootstrap';
import {
    Row,
    Col,
    MainFlex,
    MainTitleDiv,
    FlexForExperience,
    ExperienceDiv,
    ExpIconSpan,
    ExpIconTextSpan,
    LocationDiv,
    LocationIconSpan,
    LocationTextSpan,
    BorderBottom,
    ThreeButton,
    DisplayFlex,
    BtnItem,
    BtnZero,
    BtnTwo,
    JobText,
    Icons,
    PaddingAllSide,
    TitleTextMain,
    JobResponsibilitiesList,
    KeySkillsPreferredList,
    TitleText,
    TableTd,
    CarouselTitle,
    ModalHead,
    ButtonBG,
    ThemeCancelButton,
    UpImgCont
} from './GoldJobDetail.style'

import Portfolio from "../../assets/body/job-details/portfolio.svg";
import GpsIcon from "../../assets/body/job-details/gps-icon.svg";
import { Heart, Share2, X } from "react-feather";
import { Modal } from "react-bootstrap";
import UpPlatImg from '../../assets/body/services/platinum.svg'

import Carousel from '../carousel/carousel'

const GoldJobDetail = () => {

    // apply now modal
    const [show, setShow] = useState(false);

    const Close = () => setShow(false);
    const Show = () => setShow(true);

    // upgrade to platinum modal
    const [uppshow, setUpphow] = useState(false);

    const UppClose = () => setUpphow(false);
    const UppShow = () => setUpphow(true);

    return (
        <>
            <UserHeader />
            <BluePartForm />
            <Container>
                <Row className="row">
                    <Col className="col pt-5">
                        <MainFlex className="d-lg-flex align-items-start justify-content-between">
                            <div className="mb-4 mb-lg-0">
                                <MainTitleDiv className="mb-2">
                                    <TitleTextMain>Graphic Designer</TitleTextMain>
                                </MainTitleDiv>
                                <FlexForExperience className="d-xs-block d-sm-flex align-items-center mb-4 sub-title">
                                    <ExperienceDiv className="d-flex align-items-center">
                                        <ExpIconSpan className="d-block">
                                            <Icons src={Portfolio} alt="" />
                                        </ExpIconSpan>
                                        <ExpIconTextSpan className="d-block">2 to 6 Yrs</ExpIconTextSpan>
                                    </ExperienceDiv>
                                    <LocationDiv className="d-flex align-items-center">
                                        <LocationIconSpan className="ms-sm-4 ms-1 d-block">
                                            <Icons src={GpsIcon} alt="" />
                                        </LocationIconSpan>
                                        <LocationTextSpan className="d-block">Navi Mumbai, Mumbai</LocationTextSpan>
                                    </LocationDiv>
                                </FlexForExperience>
                                <div className="align-items-center mb-4">
                                    <DisplayFlex>
                                        <BtnItem>
                                            <ThreeButton onClick={Show}>
                                                <BtnZero
                                                    className=" py-2 px-4"
                                                    to="#">
                                                    Apply Now
                                                </BtnZero>
                                            </ThreeButton>
                                        </BtnItem>

                                        <BtnItem>
                                            <ThreeButton onClick={UppShow}>
                                                <BtnTwo
                                                    className="py-2 px-4"
                                                    to="#" >
                                                    UPGRADE TO PLATINUM
                                                </BtnTwo>
                                            </ThreeButton>
                                        </BtnItem>
                                    </DisplayFlex>
                                </div>
                            </div>

                            {/* Apply Now Modal */}

                            <Modal
                                size="lg"
                                centered className="modalbody" show={show} onHide={Close}>
                                <ModalHead>
                                    <X width="20" color="#fff" strokeWidth="5" onClick={Close} style={{ cursor: 'pointer' }} />
                                </ModalHead>
                                <Modal.Body>
                                    <h6 className="text-center fw-bold">ARE YOU SURE YOU WANT TO APPLY FOR THIS JOB ?</h6>
                                </Modal.Body>
                                <div className="text-center mb-3">
                                    <ButtonBG onClick={Close}>
                                        <ThemeCancelButton CV to="#">
                                            Yes
                                        </ThemeCancelButton>
                                    </ButtonBG>
                                </div>
                            </Modal>


                            {/* Upgrade To Platinum Modal */}

                            <Modal
                                size="lg"
                                centered className="modalbody" show={uppshow} onHide={UppClose}>
                                <ModalHead>
                                    <X width="20" color="#fff" strokeWidth="5" onClick={UppClose} style={{ cursor: 'pointer' }} />
                                </ModalHead>
                                <Modal.Body>
                                    <div className="text-center my-3">
                                        <UpImgCont src={UpPlatImg} slt="" />
                                    </div>
                                    <h6 className="text-center fw-bold text-uppercase">Upgrade To Platinum</h6>
                                    <div className="p-2">
                                        <p className="text-center">Gold Membership is designed to assist jobseekers with their job search by reducing the number of hours they put on different job sites to identify the right jobs for themselves. Take advantage of our teams effort in sourcing the best jobs to reduce the time that you put on different job site.</p>
                                        <p className="text-center mb-0">The key part of this membership is to connect you directly with the top head-hunters/recruiters in the area so you can develop your network and access job that were never advertised. You can create your own profile that will enable recruiters and head-hunters/recruiters to identify you.</p>
                                    </div>
                                </Modal.Body>
                                <div className="text-center mb-3">
                                    <ButtonBG onClick={UppClose}>
                                        <ThemeCancelButton CV to="#">
                                            Subscribe
                                        </ThemeCancelButton>
                                    </ButtonBG>
                                </div>
                            </Modal>

                            <div className="top-right-area pt-lg-1">
                                <div className="d-flex flex-column justify-content-stretch">
                                    <div className="d-flex align-items-center mb-lg-5">
                                        <JobText>
                                            <p>Job posted on </p>
                                        </JobText>
                                        <p>5 March 2020</p>
                                    </div>
                                    <div className="d-flex">
                                        <div className="d-flex">
                                            <Heart width="30" />
                                            <JobText>
                                                <p> SAVE JOB</p>
                                            </JobText>
                                        </div>
                                        <div className="d-flex">
                                            <Share2 />
                                            <p>SHARE</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </MainFlex>

                        <BorderBottom>
                            <PaddingAllSide>
                                <TitleText>Job Description</TitleText>
                                <p>
                                    UI design has been essential to the growth of the
                                    Company and this exciting position is looking for a
                                    dynamic professional to further enhance the UI/UX
                                    interface. UI/UX Designer needs to be a self-motivated
                                    performer with high levels of ethics, trustworthiness
                                    and attention to detail. An ideal candidate will require
                                    experience and talent in shaping user-centric
                                    experiences to translate the user journey into a smooth
                                    and intuitive experience. The position will require the
                                    candidate to perform in a fast paced environment.
                                </p>
                            </PaddingAllSide>
                        </BorderBottom>
                        <BorderBottom>
                            <PaddingAllSide>
                                <TitleText>Job Responsibilities</TitleText>
                                <JobResponsibilitiesList>
                                    <li>
                                        Evaluate user requirements in collaboration with
                                        development teams.
                                    </li>
                                    <li>
                                        Create and improve wireframe, prototypes, style
                                        guides, user flows.
                                    </li>
                                    <li>
                                        Illustrate design ideas using storyboards, process
                                        flows, and sitemaps.
                                    </li>
                                    <li>
                                        Designing graphical user interface elements like
                                        menus, tabs among others.
                                    </li>
                                    <li>
                                        Visualize and develop UI prototypes that clearly
                                        illustrate how the application works.
                                    </li>
                                    <li>Communicate design decisions.</li>
                                    <li>
                                        Identify and troubleshoot UI problems like
                                        responsiveness.
                                    </li>
                                </JobResponsibilitiesList>
                            </PaddingAllSide>
                        </BorderBottom>
                        <BorderBottom>
                            <PaddingAllSide>
                                <TitleText>Key Skills Preferred</TitleText>
                                <KeySkillsPreferredList>
                                    <li>
                                        Evaluate user requirements in collaboration with
                                        development teams.
                                    </li>
                                    <li>
                                        Create and improve wireframe, prototypes, style
                                        guides, user flows.
                                    </li>
                                    <li>
                                        Illustrate design ideas using storyboards, process
                                        flows, and sitemaps.
                                    </li>
                                    <li>
                                        Designing graphical user interface elements like
                                        menus, tabs among others.
                                    </li>
                                    <li>
                                        Visualize and develop UI prototypes that clearly
                                        illustrate how the application works.
                                    </li>
                                    <li>Communicate design decisions.</li>
                                    <li>
                                        Identify and troubleshoot UI problems like
                                        responsiveness.
                                    </li>
                                </KeySkillsPreferredList>
                            </PaddingAllSide>
                        </BorderBottom>
                        <BorderBottom>
                            <PaddingAllSide>
                                <table>
                                    <tr>
                                        <TableTd> Job Title</TableTd>
                                        <td>Graphic/Web Designer</td>
                                    </tr>
                                    <tr>
                                        <TableTd> Industry </TableTd>
                                        <td>IT-Software, Software Services</td>
                                    </tr>
                                    <tr>
                                        <TableTd> Location </TableTd>
                                        <td>Navi Mumbai, Mumbai City</td>
                                    </tr>
                                    <tr>
                                        <TableTd> Industry </TableTd>
                                        <td>B.Tech/B.E. in Any Specialization</td>
                                    </tr>
                                    <tr>
                                        <TableTd> Employment Type </TableTd>
                                        <td>Full Time, Permanent</td>
                                    </tr>
                                </table>
                            </PaddingAllSide>
                        </BorderBottom>

                    </Col>

                </Row>
            </Container>
            <Container className="position-relative">
                <div>
                    <div className="row my-3 align-items-center justify-content-between">
                        <div className="col">
                            <CarouselTitle>Similar Job</CarouselTitle>
                        </div>
                    </div>
                    <Carousel />
                </div>
            </Container>
        </>
    )
}

export default GoldJobDetail
