import styled from "styled-components";
import { color } from '../../../styles/variable.styles'

export const LeftContainer = styled.div `
  background-color: ${color.theme};
  @media (max-width: 426px) {
    display: none !important;
  }
`;
export const WhiteBgDiv = styled.div `
  margin-bottom: 60px !important;
  width: 80%;
  @media (max-width: 426px) {
    width: 100%;
  }
`;

export const WhiteBoxDiv = styled.div `
  box-shadow: 0px 0px 10px #b1b1b1;
  @media (max-width: 426px) {
    box-shadow: none;
  }
`;
export const RighSideContainer = styled.div `
 @media (max-width: 426px) {
    width: 100%;
  }
`;