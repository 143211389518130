import { Link } from "react-router-dom";
import styled from "styled-components";
import { color } from "../../../styles/variable.styles";

export const Background = styled.div `
  border: 10px solid #eeeeee;
  background: linear-gradient(70deg, #fafeff 50%, #0d6dc3 50%);
  @media (max-width: 768px) {
    background: none;
  }
`;

export const MarginTopBottom = styled.div `
  margin-top: 25px !important;
  margin-bottom: 25px;
  display: flex;
`;

export const EditIconSize = styled.img `
  height: 20px;
  width: 20px;
  margin-right: 5px;
`;

export const EditBtn = styled.button `
  background-color: #ffffff;
  border: 1px solid ${color.theme};
  color: #0073db;
  border-radius: 50px;
  text-transform: none;
  padding: 7px 15px;
  font-size: 14px;
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  margin: 10px 65px 0 0;
  transition: 0.3s;
  text-decoration: none;
  @media (max-width: 768px) {
    margin: 10px 20px 0 0;
  }
  @media (max-width: 426px) {
    display: none;
  }
`;

export const HelpDeskBtn = styled.button `
  background-color: #ffffff;
  border: 1px solid ${color.theme};
  color: #0073db;
  border-radius: 50px;
  text-transform: none;
  padding: 7px 15px;
  font-size: 14px;
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  margin: 0px 20px 0 0;
  transition: 0.3s;
  text-decoration: none;
  @media (max-width: 768px) {
    margin: 10px 20px 0 0;
  }
`;

export const EditBtnMobi = styled.div `
  border: 1px solid #0073db;
  border-radius: 50%;
  display: inline-block;
`;

export const ProfileImgSize = styled.img `
  border-radius: 50%;
  width: 180px;
  height: 180px;
`;

export const BoldTableData = styled.td `
  font-weight: 700;
  padding-left: 10px;
  padding-top: 7px;
  padding-bottom: 7px;
  font-family: ${({ lato }) => (lato ? "inherit" : '"Raleway", sans-serif')};
`;


export const InputHolderJobTitle = styled.input `
  border-top-left-radius: 40px;
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
  border-right: 1px solid #0066c2;
  outline: none !important;
  color: #0066c2;
  font-size: 16px;
  font-weight: 600;
  text-transform: lowercase;
  margin-bottom: 5px;
  padding: 5px 15px;
  &:focus {
    box-shadow: none;
    border-bottom: 5px solid ${color.goldButton};
    margin-bottom: 0px;
    color: ${color.theme} !important;
  }
  @media (max-width: 426px) {
    border-bottom-right-radius: 40px;
    border-top-right-radius: 40px;
  }
`;

export const InputHolderJobLocation = styled.input `
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-right: 1px solid #0066c2;
  outline: none !important;
  color: #0066c2;
  font-size: 16px;
  font-weight: 600;
  text-transform: lowercase;
  margin-bottom: 5px;
  padding: 5px 15px;
  &:focus {
    box-shadow: none;
    border-bottom: 5px solid ${color.goldButton};
    margin-bottom: 0px;
    color: ${color.theme} !important;
  }
  @media (max-width: 426px) {
    border-bottom-right-radius: 40px;
    border-top-right-radius: 40px;
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
  }
`;

export const ButtonHolder = styled.button `
  color: ${color.theme};
  background-color: #fff;
  font-size: 16px;
  padding: 6px 20px;
  border-bottom-right-radius: 40px;
  border-top-right-radius: 40px;
  border: none;
  text-transform: uppercase;
  font-weight: 600;
  &:focus {
    box-shadow: none;
    border-bottom: 5px solid ${color.goldButton};
  }
  @media (max-width: 426px) {
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
  }
`;

export const UpgradeButton = styled.button `
  border-radius: 20px;
  border: 1px solid #fff;
  color: #fff;
  width: max-content;
  padding: 5px 10px;
  font-size: smaller;
  text-transform: uppercase;
  background-color: #0073db;
`;

export const DeskViewProfile = styled.div `
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const DeskViewSearchBox = styled.div `
  @media (max-width: 768px) {
    display: none;
  }
`;
export const MobileViewSerchBox = styled.div `
  // display: none;
`;
export const MobileFullScreen = styled.div `
  width: 100%;
  flex: 0 0 100%;
  background-color: #0073db;
  position: fixed !important;
  top: 0px !important;
  z-index: 999999;
  left: 0px;
  width: 100%;
  height: 100vh;
  margin: auto;
`;
export const UpgradeToGold = styled.button `
  border-radius: 20px;
  border: 1px solid #fff;
  color: #fff;
  width: max-content;
  padding: 5px 10px;
  font-size: smaller;
  text-transform: uppercase;
  background-color: #0073db;
`;

export const MobileEditBtnView = styled.div `
  display: none;
  @media (max-width: 426px) {
    display: block;
  }
`;

export const SerachBarMobile = styled.div `
  display: none;
  @media (max-width: 769px) {
    display: block;
    background-color: ${color.theme};
    padding: 10px 50px;
    position: sticky;
    top: 0;
    z-index: 99;
  }
`;

export const FindJobBtn = styled.div `
  border: none;
  color: ${color.theme};
  font-weight: 600;
  background-color: ${color.regularfontwhite};
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 20px;
`;

export const BasicAlignmentCenter = styled.div `
  margin-top: 23px;
  margin-left: 20px;
  @media (max-width: 1024px) {
    margin-left: 13px;
  }
`;

export const ModalHead = styled.div `
  background-color: ${color.theme};
  text-align: right;
  padding: 5px 10px;
`;

export const TextDiv = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  @media (max-width: 426px) {
    margin-bottom: 10px;
  }
`;

export const TextBox = styled.input `
  border: 0;
  border-bottom: 1px solid #ced4da !important;
  border-radius: 0;
  width: 100%;
  padding: 7px 7px;
  &:focus {
    outline: none !important;
    border-color: #fff;
    box-shadow: none;
  }
`;

export const ButtonBG = styled.div `
  background-color: #fff;
  color: #0073db;
  border-radius: 50px;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 500;
  margin: 10px 0;
  transition: 0.3s;
  text-decoration: none !important;
`;

export const HelpDeskSubmitButton = styled(Link)
`
&:hover {
  background-color: ${({ CV }) => (CV ? "#fff" : "#0073db")};
  color: ${({ CV }) => (CV ? "#0073db" : "#fff")};
}
border: 1px solid ${color.theme};
color: ${({ CV }) => (CV ? "#fff" : "#0073db")};
background-color: ${({ CV }) => (CV ? "#0073db" : "#fff")};
font-weight: 500;
font-size: 13px;
padding: 0.5rem 300x;
cursor: pointer;
border-radius: 50px;
text-decoration: none !important;
`;