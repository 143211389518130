import React from 'react'
import {FormDiv, TextDiv, InputTitle, InputFile, Buttons, ButtonDiv } from './FourthStep.style'
import { SignUpSlideIncrease, SignUpSlideDecrease } from '../../../../redux/SignIn/SignIn.action'
import { IsSignUpSlideType } from '../../../../redux/SignIn/SignIn.selector'
import { useSelector, useDispatch } from 'react-redux'

const FourthStep = () => {

    const currentSignUpState = useSelector(IsSignUpSlideType)
    const dispatch = useDispatch()

    const getCurrentSlide = () => {
        if (currentSignUpState === 3) {
            dispatch(
                SignUpSlideIncrease(currentSignUpState + 1)
            )
        }
    }
    
    const getCurrentSlideDecrease = () => {
        if (currentSignUpState === 3) {
            dispatch(
                SignUpSlideDecrease(currentSignUpState - 1)
            )
        }
    }

    return (
        <>
            <FormDiv className="">
                    <TextDiv className="mb-2" style={{ justifyContent: 'start', borderBottom: '1px solid black' }}>
                        <InputTitle>Attach Your Resume</InputTitle>
                    </TextDiv>
                    <TextDiv className="mb-2" style={{ justifyContent: 'start' }}>
                        <InputFile type="file" accept="application/pdf,application/vnd.ms-excel"/>
                    </TextDiv>

                   

                    <ButtonDiv>
                        <Buttons variant="contained" onClick={() => getCurrentSlideDecrease()} style={{ marginRight: '10px' }}>Back</Buttons>
                        <Buttons variant="contained" onClick={()=> getCurrentSlide()}>Upload Later</Buttons>
                    </ButtonDiv>
                </FormDiv>
        </>
    )
}

export default FourthStep

