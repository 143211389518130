import styled from "styled-components";
import { color } from "../../styles/variable.styles";
import { Link } from "react-router-dom";

export const DeskMenuSection = styled.div `
  border-right: 1px solid #ccc;
  padding: 0px;
  @media (max-width: 769px) {
    display: none;
  }
`;

export const TabsContainer = styled.div `
  display: flex;
  margin-top: 20px;
`;

export const TabsContainerLogOut = styled(Link)
`
text-decoration: none;
color: #000;
display: flex;
margin-top: 20px;
`;

export const TabIcon = styled.img `
  height: 40px;
  width: 40px;
`;

export const TabName = styled.h4 `
  align-self: center;
  margin-bottom: 0px;
  padding-left: 5px;
  font-size: medium;
  font-weight: 600;
  cursor: pointer;
`;

export const ContentSection = styled.div `
  background-color: #fbfbfb;
  padding: 0px;
  @media (max-width: 769px) {
    width: 100%;
  }
`;

export const ContentSectionTabName = styled.h5 `
  font-weight: 600;
`;

export const ProfilePhotoSection = styled.div `
  @media (max-width: 769px) {
    width: 100%;
  }
`;

export const BDProfilePhotoBG = styled.div `
  padding: 3rem;
  @media (max-width: 1024px) {
    padding: 2rem;
  }
  @media (max-width: 768px) {
    padding: 3rem;
  }
`;

export const ProfileImage = styled.img `
  border-radius: 50%;
  width: 180px;
  height: 180px;
  display: block;
  margin: 0 auto;
  @media (max-width: 1024px) {
    width: 170px;
    height: 170px;
  }
  @media (max-width: 768px) {
    width: 180px;
    height: 180px;
  }
`;

export const UpladBtnImg = styled.img `
  position: relative;
  margin-top: -61px;
  margin-left: 49px;
  width: 141px;
  @media (max-width: 1367px) {
    margin-top: -62px;
    margin-left: 23px;
  }
  @media (max-width: 1280px) {
    margin-top: -62px;
    margin-left: 23px;
  }
  @media (max-width: 1024px) {
    margin-top: -62px;
    margin-left: 18px;
    width: 133px;
  }
  @media (max-width: 768px) {
    margin-top: -62px;
    margin-left: 218px;
    width: 140px;
  }
  @media (max-width: 540px) {
    margin-top: -62px;
    margin-left: 127px;
  }
  @media (max-width: 480px) {
    margin-top: -62px;
    margin-left: 70px;
  }
  @media (max-width: 425px) {
    margin-top: -62px;
    margin-left: 69px;
  }
  @media (max-width: 416px) {
    margin-top: -62px;
    margin-left: 63px;
  }
  @media (max-width: 400px) {
    margin-top: -62px;
    margin-left: 54px;
  }
  @media (max-width: 376px) {
    margin-top: -62px;
    margin-left: 45px;
  }
  @media (max-width: 361px) {
    margin-top: -62px;
    margin-left: 37px;
  }
  @media (max-width: 320px) {
    margin-top: -62px;
    margin-left: 19px;
  }
`;

export const BDWhiteBox = styled.div ``;

export const BDPackageTextTitle = styled.div `
  font-size: 18px;
  font-weight: 700;
`;

export const PackageText = styled.h6 `
  font-size: 15px;
  font-weight: ${({ bold }) => (bold ? "600" : "400")};
`;

export const PackageButton = styled.div `
  display: flex;
  @media (max-width: 1400px) {
    display: block;
  }
  @media (max-width: 800px) {
    display: flex;
  }
  @media (max-width: 325px) {
    display: block;
  }
`;

export const ButtonBG = styled.div `
  background-color: #fff;
  color: #0073db;
  border-radius: 50px;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 500;
  margin: 10px 0;
  transition: 0.3s;
  text-decoration: none !important;
`;

export const GoldButton = styled(Link)
`
  &:hover {
    background-color: ${color.goldButton};
    color: ${color.regularfontwhite};
  }

  border: 1px solid ${color.goldButton};
  color: ${color.goldButton};
  font-weight: bold;
  cursor: pointer;
  padding: 0.5rem 12px;
  border-radius: 50px;
  text-decoration: none !important;
`;

export const PlatinumButton = styled(Link)
`
  &:hover {
    background-color: ${color.platinumButton};
    color: ${color.regularfontwhite};
  }
  border: 1px solid ${color.platinumButton};
  color: #db007c;
  font-weight: bold;
  padding: 0.5rem 12px;
  cursor: pointer;
  border-radius: 50px;
  text-decoration: none !important;
`;

export const InformationSection = styled.div `
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const BasicDetailText = styled.h5 `
  /* font-size: 18px; */
  font-weight: 600;
`;

export const BDDesktopTable = styled.div `
  display: block;
  @media (max-width: 769px) {
    display: none;
  }
`;

export const BDMobileTable = styled.div `
  display: none;
  @media (max-width: 769px) {
    display: block;
  }
`;
export const MrCheckBoxDiv = styled.div `
  display: block;
  justify-content: space-around;
  @media (max-width: 450px) {
    display: none !important;
  }
`;

export const MrCheckBoxMobileDiv = styled.div `
  display: none;
  width: 60%;
  @media (max-width: 450px) {
    display: block !important;
  }
`;

export const Checkbox = styled.input `
  height: 17px;
  width: 17px;
  margin-right: 10px;
  @media (max-width: 1024px) {
    margin-right: 5px;
  }
`;
export const Checkspan = styled.span ``;

export const TextDiv = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  @media (max-width: 426px) {
    margin-bottom: 10px;
  }
`;

export const TextBox = styled.input `
  border: 0;
  border-bottom: 1px solid #ced4da !important;
  border-radius: 0;
  width: 100%;
  padding: 7px 7px;
  &:focus {
    outline: none !important;
    border-color: #fff;
    box-shadow: none;
  }
`;

export const ThemeButton = styled(Link)
`
&:hover {
  background-color: ${({ CV }) => (CV ? "#fff" : "#0073db")};;
  color: ${({ CV }) => (CV ? "#0073db" : "#fff")};;
}
border: 1px solid ${color.theme};
color: ${({ CV }) => (CV ? "#fff" : "#0073db")};
background-color: ${({ CV }) => (CV ? "#0073db" : "#fff")};
font-weight: bold;
padding: 0.5rem 10px;
cursor: pointer;
border-radius: 50px;
text-decoration: none !important;
`;

export const SubHeading = styled.p `
  border-bottom: 1px solid #ced4da;
`;

export const RadioSpan = styled.span `
  font-size: 14px;
  font-weight: 400;
`;

export const RadioButton = styled.input ``;

export const InputTitle = styled.p `
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 5px;
`;

export const InputFile = styled.input `
  color: ${color.theme};
  font-size: 16px;
  font-weight: 500;
`;

export const MobileBottomTab = styled.div `
  bottom: 0;
  position: fixed;
  background-color: #fff;
  z-index: 999;
  width: 100%;
  left: 0;
  box-shadow: 0px 1px 15px rgb(0 0 0 / 10%);
`;

export const Menus = styled.div ``;

export const Chpasswidth = styled.div `
  @media (max-width: 480px) {
    width: 100%;
  }
`;

export const InpBox = styled.input `
  width: 100%;
  border: 0;
  border-bottom: none !important;
  border-radius: 0;
  font-weight: 600;
  padding: 0px 7px;
  &:focus {
    outline: none !important;
    border-color: #fff;
    box-shadow: none;
  }
`;


export const ModalHead = styled.div `
background-color: ${color.theme};
text-align: right;
padding: 5px 10px;
`;

export const ThemeCancelButton = styled(Link)
`
&:hover {
  background-color: ${({ CV }) => (CV ? "#fff" : "#0073db")};
  color: ${({ CV }) => (CV ? "#0073db" : "#fff")};
}
border: 1px solid ${color.theme};
color: ${({ CV }) => (CV ? "#fff" : "#0073db")};
background-color: ${({ CV }) => (CV ? "#0073db" : "#fff")};
font-weight: 500;
padding: 0.5rem 10px;
cursor: pointer;
border-radius: 50px;
text-decoration: none !important;
`;