import styled from "styled-components";

export const ArrowBg = styled.div `
  background-color: #b2b2b2;
  :hover {
    background-color: #0073db;
    animation: anni 1s;

    @keyframes anni {
      100% {
        transform: rotate(360deg);
      }
    }
  }
`;