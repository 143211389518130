import styled from "styled-components";
import { Link } from 'react-router-dom';



export const LogoLink = styled(Link)
`

`;

export const Logoimg = styled.img `
    width: 70px;
    height: auto;
    padding: 8px 0;
`;

export const NavButton = styled(Link)
`

`;

export const SignInPngSize = styled.img `
height: 20px;
width: 20px;
margin-right: 15px;
`;