import styled from "styled-components";
import { color } from '../../styles/variable.styles'


export const CardWidth = styled.div `
width: 310px;
@media (max-width:1025px){
  width: 290px;
}
@media (max-width:769px){
  width: 220px;
}
@media (max-width:500px){
  width: 360px;
}
@media (max-width:376px){
  width: 320px;
}
@media (max-width:322px){
  width: 285px;
}
`;
export const Card = styled.div `
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 15px;
  margin: 0 5px;
`;

export const CradBody = styled.div `
  padding: 30px 12px;
  @media (max-width:1025px){
    padding: 20px 12px;
}
@media (max-width:768px){
    padding: 20px 10px;
}
  @media (max-width:500px){
    padding: 20px 10px;
}
@media (max-width:375px){
    padding: 10px 5px;
}
`;

export const CardTitle = styled.h5 `
  font-size: 18px;
  font-weight: 700;
  color: #0066c2;
  text-transform: capitalize;
  margin-bottom: 10px;
`;
export const CardJobData = styled.div `
  padding: 15px 0 0px;
`;

export const CardDataTable = styled.table `
  width: 100%;
`;

export const CardDataTableBody = styled.tbody ``;

export const CardDataTableTr = styled.tr ``;

export const CardDataTableTd = styled.td `
  color: ${({ orgcolor }) => (orgcolor ? "#FFA500" : "#8d8d8d")};
  font-size: 13px;
  font-weight: 600;
`;

export const CardButton = styled.button `
  border: 1px solid ${color.theme} !important;
  background-color: ${color.theme};
  color: ${color.regularfontwhite};
  border-radius: 50px;
  text-transform: uppercase;
  padding: 10px 18px;
  font-size: 13px;
  font-weight: 500;
  margin: 10px 0;
  transition: 0.3s;
`;