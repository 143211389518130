import { SigninActionTypes } from "./Sign.types";

const INITIAL_STATE = {
    passshown: false,
    currentpass: false,
    newpass: false,
    confirmpass: false,

    currentSignupStep: 0
};

const signInReducer = (state = INITIAL_STATE, action) => {
    console.log(state);
    switch (action.type) {
        case SigninActionTypes.PASSWORD_SHOW_HIDE:
            return {
                ...state,
                passshown: !state.passshown,
            };
        case SigninActionTypes.PASSWORD_CURRENT_PASS:
            return {
                ...state,
                currentpass: !state.currentpass,
            };

        case SigninActionTypes.PASSWORD_NEW_PASS:
            return {
                ...state,
                newpass: !state.newpass,
            };

        case SigninActionTypes.PASSWORD_CONFIRM_PASS:
            return {
                ...state,
                confirmpass: !state.confirmpass,
            };

        case SigninActionTypes.SIGNUP_SLIDE_INCREASE:
            return {
                ...state,
                currentSignupStep: action.payload,
            };

        case SigninActionTypes.SIGNUP_SLIDE_DECREASE:
            return {
                ...state,
                currentSignupStep: action.payload,
            };

        default:
            return state;
    }
};

export default signInReducer;