export const color = {
    theme: '#0073db',
    font: '#212529',
    blueButton: '#0073db',
    goldButton: '#db7c00',
    platinumButton: '#db007c',
    hoverButtonFont: '#fff',
    footrtTextHoverColor: '#0073db',
    regularfontwhite: '#fff'
}

export const font = {
    main: '16px', //font-size
    mainFontWeight: '400',
    btn: '13px', //font-size
    btnFontWeight: 'bold',
    mainHeading: '24px', //font-size
    mainHeadingfontWeight: '800',
    contentHeading: '16px', //font-size
    contentHeadingfontWeight: '800',
}