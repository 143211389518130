import React from 'react'
import Header from '../Header/header';
import { Container } from 'react-bootstrap'
import { ThankYouContainer, ThankYouImgContainer, Text, SubText, Btn } from './ThankYou.style';
import ThankYouImg from '../../assets/body/contact/thankyou.svg'

const ThankYou = () => {
    return (
        <>
            <Header />
            <ThankYouContainer>
                <Container>
                    <div className="row">
                        <div className="col col-md-8 m-auto my-4">
                            <div className="m-auto d-flex flex-wrap text-center ">
                                <div>
                                    <ThankYouImgContainer src={ThankYouImg} alt="" />
                                    <Text className="my-3">Thanks For Filling out our form!</Text>
                                    <SubText>We will get in touch with you soon!</SubText>
                                    <Btn className="py-2 px-4" to="/"> Back To Home </Btn>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </ThankYouContainer>

        </>
    )
}

export default ThankYou
