import styled from "styled-components";
import { color } from "../../../../styles/variable.styles";



export const MobileFormName = styled.h1 `
  display: none;
  @media (max-width: 426px) {
    display: block;
    font-weight: 600;
    color: ${color.theme};
    font-size: 23px;
    margin: 0px 12px;
  }
`;
export const ImageDiv = styled.div `
  background-color: ${color.theme};
  @media (max-width: 426px) {
    display: none !important;
  }
`;

export const FormName = styled.h1 `
  font-weight: 600;
  color: #ffffff;
  font-size: 28px;
  margin: 0px 0px 48px 0px;
`;

export const StepThreeImage = styled.img `
  width: 100%;
`;