/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import FooterLogo from "../../assets/brand/logo.svg";
import { LogoLink, FooterLogoSize, FooterMainText, FooterText, InputBox, SubmitButton } from "./footer.style";

const Footer = () => {
  return (
    <div>
      <div style={{background: '#fff'}}>
        <div className="container pt-4 pt-md-5">
          <div className="row">
            <div className="col col-md-12 col-lg-12 m-auto">
              <div className="d-md-flex">
                <div className="col-md-2 pb-5">
                  <LogoLink to="/">
                    <FooterLogoSize className="footer-logo" src={FooterLogo} alt="logo" />
                  </LogoLink>
                </div>
                <div className="col-md-2 pb-4">
                  <FooterMainText className="footer-title">Quick Links</FooterMainText>
                  <ul className="list-unstyled text-small link-list">
                    <li>
                      <FooterText to="/aboutUs">About Us</FooterText>
                    </li>
                    <li>
                      <FooterText to="/ourservice">
                        Our Services
                      </FooterText>
                    </li>
                    <li>
                      <FooterText to="#">Blogs</FooterText>
                    </li>
                    <li>
                      <FooterText to="/contactUs">Contact Us</FooterText>
                    </li>
                  </ul>
                </div>
                <div className="col-md-2 pb-4">
                  <FooterMainText>Job Search By</FooterMainText>
                  <ul className="list-unstyled text-small link-list">
                    <li>
                      <FooterText to="#">Location</FooterText>
                    </li>
                    <li>
                      <FooterText to="#">Industry</FooterText>
                    </li>
                    <li>
                      <FooterText to="#">Functions</FooterText>
                    </li>
                    <li>
                      <FooterText to="#">Career Levels</FooterText>
                    </li>
                  </ul>
                </div>
                <div className="col-md-2 pb-4">
                  <FooterMainText >Social Media</FooterMainText>
                  <ul className="list-unstyled text-small link-list">
                    <li>
                      <FooterText orgcolor to="#">Coming Soon</FooterText>
                    </li>
                  </ul>
                </div>
                <div className="col pb-4">
                  <FooterMainText >Newsletter</FooterMainText>
                  <div className="input-group mb-3" style={{zIndex: '0'}}>
                    <InputBox
                      type="text"
                      className="form-control"
                      placeholder="Enter Your Email-Id"
                      aria-label="Enter Your Email-Id"
                      aria-describedby="basic-addon2"
                    />
                    <div className="input-group-append">
                      <SubmitButton  type="button">
                        Subscribe
                      </SubmitButton>
                    </div>
                  </div>
                </div>
                <div className="d-block d-md-none col pb-4">
                  <h3 >Policy</h3>
                  <ul className="list-unstyled text-small link-list">
                    <li>
                      <FooterText to="./privacypolicy">
                        Privacy Policy
                      </FooterText>
                    </li>
                    <li>
                      <FooterText to="./temsandcondition">
                        Terms & Condition
                      </FooterText>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="border-top py-2">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-12 m-auto d-md-flex justify-content-between align-items-center text-center">
              <div className="col-md-4 col text-md-start pb-2 pb-md-0">
                <small className="mb-3 mt-3 text-muted  font-lato">
                  2021 &copy; Job Enhancer, All Rights Reserved.
                </small>
              </div>
              <div className="d-none d-md-block col-md-4 col align-items-center pb-2 pb-md-0">
                <div className="col-10 justify-content-center m-auto">
                  <div className="row">
                    <small className="col text-md-right text-muted">
                      <FooterText to="./privacypolicy">
                        Privacy Policy
                      </FooterText>
                    </small>
                    <small className="col text-md-left text-muted ">
                      <FooterText to="./temsandcondition">
                        Terms & Condition
                      </FooterText>
                    </small>
                  </div>
                </div>
              </div>

              <div className="col-md-4 col text-md-end">
                <small className="mb-3 mt-3 text-muted credit-text">
                  Made by iDigitalise™ Consultancy Pvt. Ltd.
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
