import React, { useRef } from 'react'
import { Container } from 'react-bootstrap';
import { ArrowBg } from './carousel.style';
import Carousel from 'react-elastic-carousel'
import './carousel.css'
import Card from './card'
import { ChevronLeft, ChevronRight } from 'react-feather'


const Carousels = () => {
    const Slider = useRef(null);
    const BreakPoints = [
        { width: 400, itemsToShow: 1 },
        { width: 500, itemsToShow: 2 },
        { width: 620, itemsToShow: 3 },
        { width: 1024, itemsToShow: 4 },

    ]
    return (
        <div>
            <Container>
                <div className="nav-btn-box d-flex col justify-content-end  my-lg-0 align-items-center position-absolute" style={{top: '0', right: '0'}}>
                    <ArrowBg  className="rounded-circle" onClick={() => Slider?.current.slidePrev()}>
                        <ChevronLeft color="#fff" size={35} strokeWidth="4" />
                    </ArrowBg>
                    <ArrowBg  className="rounded-circle mx-2" onClick={() => Slider?.current.slideNext()}>
                        <ChevronRight color="#fff" size={35} strokeWidth="4" />
                    </ArrowBg>
                </div>
                <div>
                    <Carousel breakPoints={BreakPoints} showArrows={false} ref={Slider}>
                        <Card />
                        <Card />
                        <Card />
                        <Card />
                        <Card />
                        <Card />
                        <Card />
                        <Card />
                    </Carousel>


                </div>
            </Container>
        </div>
    )
}

export default Carousels
