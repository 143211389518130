import React from 'react';
import { FormDiv, TextDiv, InputTitle, Buttons, ButtonDiv } from './ThirdStep.style'
import { Dropdown } from 'react-dropdown-now';
import '../First Step/drpdown.css';
import ReactTagInput from "@pathofdev/react-tag-input";
import "@pathofdev/react-tag-input/build/index.css";
import './taginp.css'
import { SignUpSlideIncrease, SignUpSlideDecrease} from '../../../../redux/SignIn/SignIn.action'
import { IsSignUpSlideType } from '../../../../redux/SignIn/SignIn.selector'
import { useSelector, useDispatch } from 'react-redux'

const ThirdStep = () => {


    const [jobtitletags, setjobtitleTags] = React.useState([])

    const [industrytags, setindustryTags] = React.useState([])

    const [joblocationtags, setjoblocationTags] = React.useState([])

    const [jobfieldtags, setfieldTags] = React.useState([])

    // const [checkBox, setcheckBox] = useState(false)

    const currentSignUpState = useSelector(IsSignUpSlideType)
    const dispatch = useDispatch()

    const getCurrentSlide = () => {
        if (currentSignUpState === 2) {
            dispatch(
                SignUpSlideIncrease(currentSignUpState + 1)
            )
        }
    }

    const getCurrentSlideDecrease = () => {
        if (currentSignUpState === 2) {
            dispatch(
                SignUpSlideDecrease(currentSignUpState - 1)
            )
        }
    }

    return (
        <>
            <FormDiv className="">
                <TextDiv className="mb-2" style={{ justifyContent: 'start', borderBottom: '1px solid black' }}>
                    <InputTitle>Desired Job Title</InputTitle>
                </TextDiv>

                <TextDiv>
                    <ReactTagInput
                        tags={jobtitletags}
                        placeholder="Add New"
                        maxTags={10}
                        editable={true}
                        readOnly={false}
                        removeOnBackspace={true}
                        onChange={(newTags) => setjobtitleTags(newTags)}
                    />

                </TextDiv>

                <TextDiv className="mb-2" style={{ justifyContent: 'start', borderBottom: '1px solid black' }}>
                    <InputTitle>Desired Industry</InputTitle>
                </TextDiv>

                <TextDiv>
                    <ReactTagInput
                        tags={industrytags}
                        placeholder="Add New"
                        maxTags={10}
                        editable={true}
                        readOnly={false}
                        removeOnBackspace={true}
                        onChange={(newTags) => setindustryTags(newTags)}
                    />

                </TextDiv>

                <TextDiv className="mb-2" style={{ justifyContent: 'start', borderBottom: '1px solid black' }}>
                    <InputTitle>Desired Location</InputTitle>
                </TextDiv>

                <TextDiv>
                    <ReactTagInput
                        tags={joblocationtags}
                        placeholder="Add New"
                        maxTags={10}
                        editable={true}
                        readOnly={false}
                        removeOnBackspace={true}
                        onChange={(newTags) => setjoblocationTags(newTags)}
                    />

                </TextDiv>

                <TextDiv className="mb-2" style={{ justifyContent: 'start', borderBottom: '1px solid black' }}>
                    <InputTitle>Desired Job Field</InputTitle>
                </TextDiv>

                <TextDiv>
                    <ReactTagInput
                        tags={jobfieldtags}
                        placeholder="Add New"
                        maxTags={10}
                        editable={true}
                        readOnly={false}
                        removeOnBackspace={true}
                        onChange={(newTags) => setfieldTags(newTags)}

                    />

                </TextDiv>

                <Dropdown
                    placeholder="Desired salary"
                    className="my-className"
                    options={['50K - 60K P Y', '60K - 70K P Y', '70K - 80K P Y', '80K - 90K P Y']}
                    value="50K - 60K P Y"
                    onChange={(value) => console.log('change!', value)}
                    onSelect={(value) => console.log('selected!', value)} // always fires once a selection happens even if there is no change
                    onClose={(closedBySelection) => console.log('closedBySelection?:', closedBySelection)}
                    onOpen={() => console.log('open!')}
                />

                <ButtonDiv>
                    <Buttons variant="contained" onClick={() => getCurrentSlideDecrease()} style={{ marginRight: '10px' }}>Back</Buttons>
                    <Buttons variant="contained" onClick={()=> getCurrentSlide()}>Continue</Buttons>
                </ButtonDiv>
            </FormDiv>
        </>
    )
}

export default ThirdStep
