/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import {
  Background,
  MarginTopBottom,
  EditBtn,
  EditBtnMobi,
  EditIconSize,
  ProfileImgSize,
  BoldTableData,
  InputHolderJobTitle,
  InputHolderJobLocation,
  ButtonHolder,
  UpgradeButton,
  DeskViewProfile,
  DeskViewSearchBox,
  MobileViewSerchBox,
  MobileFullScreen,
  MobileEditBtnView,
  HelpDeskBtn,
  PlatinumAlignmentCenter,
  SerachBarMobile,
  FindJobBtn,
  ModalHead,
  ButtonBG,
  HelpDeskSubmitButton,
  TextDiv,
  TextBox,

} from "./BluePart.style";
import EditIcon from "../../../assets/header/dropdown-icon/edit-icon.png";
import ProfileImg from "../../../assets/body/basic-profile-landing/basic-profile-img.png";
import { X } from "react-feather";
import { Dropdown } from 'react-dropdown-now';
import { Modal } from "react-bootstrap";




const BluePart = () => {

  const [opensearchBox, setopensearchBox] = useState(false);

  const changeBtn = () => {
    // console.log('hiii');
    setopensearchBox(!opensearchBox)
  }

  //modal Help Desk

  const [show, setShow] = useState(false);

  const Close = () => setShow(false);
  const Show = () => setShow(true);


  return (

    <div>
      <SerachBarMobile>
        <div
          className="p-0"
          id="mobile"
          onClick={changeBtn}
        >
          <div>
            <FindJobBtn>Find Jobs</FindJobBtn>
          </div>
        </div>
        {opensearchBox ? (
          <MobileViewSerchBox>
            <MobileFullScreen className="col-md-6">
              <div className="d-md-flex flex-column jutsity-content-center align-items-center text-white contentright">
                <div className="col-md p-4">

                  <div id="cross" style={{ textAlign: 'right', float: 'none', color: 'white', paddingRight: '10px' }}>
                    <X onClick={changeBtn} width="35" height="35" />
                  </div>

                  <div className="text-center text-search-box pt-3">
                    <h4 id="header-main-text" style={{ marginBottom: '0px', fontWeight: '800' }}> Search & Find the Most
                      Senior Roles</h4>
                    <h3 id="headerh2" style={{ fontWeight: '800' }}>in Emerging Market</h3>
                  </div>

                  <div className="m-4">
                    <div>
                      <form method="POST" action="">
                        <div className="d-md-flex flex-wrap justify-content-center text-center">
                          <div className="col">
                            <div className="d-flex justify-content-left">
                              <label
                                className="fw-bold txt-left mb-1"
                              >
                                Search By Keywords
                              </label>
                            </div>

                            <InputHolderJobTitle
                              type="text"
                              className="d-md-flex form-control"
                              placeholder="Manager"
                              required
                            />
                          </div>

                          <div className="col">
                            <div className="d-flex justify-content-left">
                              <label
                                className="txt-right fw-bold mb-1"
                              >
                                Job Location
                              </label>
                            </div>
                            <InputHolderJobLocation
                              type="text"
                              className="d-md-flex form-control"
                              placeholder="Mumbai"
                              required
                            />
                          </div>
                          <div className="">
                            <div className="d-none d-md-block">
                              <div className="d-flex justify-content-left mb-1">
                                <label >&nbsp;</label>
                              </div>
                            </div>
                            <ButtonHolder
                              type="submit"
                              className="d-md-block my-4 my-md-0 job-search-btn job-btn-padding bar-find-btn basic-padding">
                              Find Jobs
                            </ButtonHolder>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>



                </div>
              </div>
            </MobileFullScreen>
          </MobileViewSerchBox>
        ) : null}

      </SerachBarMobile>



      <Background>
        <div className="container-fluid">
          <MarginTopBottom>
            <DeskViewProfile className="col-md-6">
              <div className="d-md-flex justify-content-end">
                <EditBtn >
                  <span>
                    <EditIconSize src={EditIcon} alt="" />
                    Edit Profile
                  </span>
                </EditBtn>
              </div>

              <div className="row align-items-center">
                <div className="col-md-4 d-flex justify-content-center p-0">
                  <ProfileImgSize src={ProfileImg} alt="" />
                </div>

                <MobileEditBtnView className="mt-3 text-center">
                  <EditBtnMobi>
                    <img src={EditIcon} alt="" style={{ padding: "10px" }} />
                  </EditBtnMobi>
                </MobileEditBtnView>

                <div className="col-md-8">
                  <div className="pt-4">
                    <h5 className="fw-bold">Subrsmanium Radhakrishnan</h5>
                    <table>
                      <tbody>
                        <tr>
                          <td> Profile</td>
                          <BoldTableData> :</BoldTableData>
                          <BoldTableData> Executive</BoldTableData>
                        </tr>
                        <tr>
                          <td> Desired Location </td>
                          <BoldTableData> :</BoldTableData>
                          <BoldTableData>USA</BoldTableData>
                        </tr>
                        <tr>
                          <td> Desired Industries </td>
                          <BoldTableData> :</BoldTableData>
                          <BoldTableData>iDigitalise</BoldTableData>
                        </tr>
                        <tr>
                          <td> Salary Range </td>
                          <BoldTableData> :</BoldTableData>
                          <BoldTableData lato>
                            Below $80,000/Year
                          </BoldTableData>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div className=" mt-3">
                    <HelpDeskBtn onClick={Show}>
                      Help Desk
                    </HelpDeskBtn>
                    <HelpDeskBtn >
                      CV Writing
                    </HelpDeskBtn>
                  </div>

                  {/* Help Desk Modal */}

                  <Modal
                    centered className="modalbody" show={show} onHide={Close}>
                    <ModalHead>
                      <X width="20" color="#fff" strokeWidth="5" onClick={Close} style={{ cursor: 'pointer' }} />
                    </ModalHead>
                    <Modal.Body>
                      <h5 className="text-center fw-bold">Help Desk</h5>
                      <TextDiv>
                        <TextBox type="text" className="form-control" name="name" placeholder="Name" />
                      </TextDiv>
                      <TextDiv>
                        <TextBox type="email" className="form-control" name="name" placeholder="Email Id" />
                      </TextDiv>
                      <TextDiv>
                        <TextBox type="number" className="form-control" name="name" placeholder="Phone" />
                      </TextDiv>
                      <Dropdown
                        placeholder="Select an option"
                        className="my-className"
                        options={['91+', '54+', '61+', '43+']}
                        value="91+"
                        onChange={(value) => console.log('change!', value)}
                        onSelect={(value) => console.log('selected!', value)} // always fires once a selection happens even if there is no change
                        onClose={(closedBySelection) => console.log('closedBySelection?:', closedBySelection)}
                      />
                      <TextDiv>
                        <TextBox type="textarea" className="form-control" name="name" placeholder="Message" />
                      </TextDiv>
                    </Modal.Body>
                    <div className="text-center mb-3">
                      <ButtonBG onClick={Close}>
                        <HelpDeskSubmitButton CV to="#" className="py-2 px-4">
                          Submit
                        </HelpDeskSubmitButton>
                      </ButtonBG>
                    </div>
                  </Modal>

                </div>
              </div>
            </DeskViewProfile>




            <DeskViewSearchBox className="col-md-6">
              <PlatinumAlignmentCenter className="d-md-flex flex-column jutsity-content-center align-items-center text-white">
                <div className="col-md">
                  <div className="text-center text-search-box">
                    <h4 className="mb-0 fw-bolder">
                      Search & Find the Most Senior Roles
                    </h4>
                    <h3 className="fw-bolder">in Emerging Market</h3>
                  </div>

                  <div className="my-4">
                    <div>
                      <form method="POST" action="">
                        <div className="d-md-flex flex-wrap justify-content-center text-center">
                          <div className="col">
                            <div className="d-flex justify-content-left">
                              <label
                                className="fw-bold txt-left mb-1"
                              >
                                Search By Keywords
                              </label>
                            </div>

                            <InputHolderJobTitle
                              type="text"
                              className="d-md-flex form-control"
                              placeholder="Manager"
                              required
                            />
                          </div>

                          <div className="col">
                            <div className="d-flex justify-content-left">
                              <label
                                className="txt-right fw-bold mb-1"
                              >
                                Job Location
                              </label>
                            </div>
                            <InputHolderJobLocation
                              type="text"
                              className="d-md-flex form-control"
                              placeholder="Mumbai"
                              required
                            />
                          </div>
                          <div className="">
                            <div className="d-none d-md-block">
                              <div className="d-flex justify-content-left mb-1">
                                <label >&nbsp;</label>
                              </div>
                            </div>
                            <ButtonHolder
                              type="submit"
                              className="d-md-block my-4 my-md-0 job-search-btn job-btn-padding bar-find-btn basic-padding">
                              Find Jobs
                            </ButtonHolder>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </PlatinumAlignmentCenter>


            </DeskViewSearchBox>
          </MarginTopBottom>
        </div>
      </Background>




    </div>
  );
};

export default BluePart;
