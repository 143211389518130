import styled from "styled-components";
import { color } from "../../../styles/variable.styles";

export const MCard = styled.div `
  @media (max-width: 769px) {
  width: 48%;
  float: left;
  margin-right: 10px;
  }
  @media (max-width: 426px) {
  width: 100%;
  margin-right: 0px;
  }
`;

export const MCompanyName = styled.p `
  color: ${color.theme};
  font-weight: 700;
  padding-left: 10px;
`;

export const Mtd = styled.td `
  font-weight: 600;
  padding-left: 10px;
  padding-top: 7px;
  padding-bottom: 7px;
`;