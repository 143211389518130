import React from 'react'
import Header from '../Header/header'
import { Container } from 'react-bootstrap'
import { Title, SubTitle } from './TermCondition.style'

const TermCondition = () => {
    return (
        <>
            <Header />
            <Container>
                <div className="row">
                    <div className="mt-5 mt-md-5">
                        <div className="col m-auto">
                            <Title className="mb-4">TERM AND CONDITIONS</Title>
                            <SubTitle>
                                <strong>STANDARD TERMS AND CONDITIONS FOR USE OF THIS WEBSITE</strong>
                                <br></br>
                                The below document states the Terms and Conditions (the “Terms of Use”) under which you may use JobEnhancer.com (“The Web Site”). If you do not accept the Terms & Conditions stated below then we advise that you do not use this Web Site and service. By using the web site you are indicating your acceptance to be bound by the provisions of these Terms of Use.
                                <br></br>
                                The Terms of Use include JobEnhancer.com's privacy policy, which can be found on this site. You are agreeing to use this site at your own risk.
                                <br></br>
                                JobEnhancer.com may revise these Terms and Conditions at any time by updating this page. You should visit this page periodically to review the Terms and Conditions as they are binding. The terms “You” and “User”, as used herein, refer to all individuals and/or entities accessing this web site for any reason.
                                <br></br>
                                By Visiting JobEnhancer.com you indicate and accept that you have the right, authority and capacity to agree to and abide by these Terms of Use. You also indicate and accept that you will use the Web Site in a manner that is wholly legal and does not infringe or break any laws and/or regulations.
                            </SubTitle>
                            <SubTitle>
                                <strong>USE OF JobEnhancer.com</strong>
                                <br></br>
                                You are allowed to view and download content from JobEnhancer.com for the purpose it was intended by the author. The content may not be used in any other form or context.
                                <br></br>
                                The content (the “Material”) of this Web Site and all associated websites under the company's control are protected by copyright under Hong Kong and foreign laws. All Material on the Web Site is the property of the company or its content suppliers or clients. The compilation and design of the content and the Web Site as a whole are protected by copyright under Hong Kong and foreign laws. Unauthorised use of all Material and content may result in copyright, trademark and other law violations. You must retain all copyright, trademark, servicemark and other proprietary notices contained in the original Material on any copy you make of the Material. You may not sell or modify the Material or reproduce, display, publicly perform, distribute, or otherwise use the Material in any way for any public or commercial purpose. The use of the Material on any other web site or in a networked computer environment for any purpose is prohibited.
                                <br></br>
                                You may not copy any part of this Web Site or adapt it in anyway; this includes the HTML, images, content and site design which is protected by the Company's Copyright.
                                <br></br>
                                You agree not to resell any Material or information obtained from the Web Site, assign your rights or obligations under these Terms of Use, or make any unauthorised commercial use of JobEnhancer.com.
                                <br></br>
                                In the event of any permitted copying, redistribution or publication of copyright material, no changes in or deletion of author attribution, trademark legend or copyright notice shall be made. You acknowledge that you do not acquire any ownership rights by downloading copyright material.
                            </SubTitle>
                            <SubTitle>
                                <strong>ACCEPTABLE SITE USE</strong>
                                <br></br>
                                General Rules: The job posting and curriculum vitae/resume database features of the Web Site can only be used for lawful purposes by individuals seeking employment and career information and employers seeking employees. Users may not use the Web Site in order to transmit, distribute, store or destroy material (a) in violation of any applicable law or regulation, (b) in a manner that will infringe the copyright, trademark, trade secret or other intellectual property rights of others or violate the privacy, publicity or other personal rights of others, or (c) that is defamatory, obscene, threatening, abusive or hateful.
                                <br></br>
                            </SubTitle>
                            <SubTitle>
                                <strong>SPECIFIC PROHIBITED USES</strong>
                                <br></br>
                                The Web Site may be used only for lawful purposes by individuals seeking employment and career information and employers seeking employees. The Company specifically prohibits any use of the Web Site, and all users agree not to use the Web Site, for any of the following:
                                <ol >
                                    <li>1. Post any jobs on any JobEnhancer.com site for any competitor of JobEnhancer.com or posting jobs or other content that contains links to any site competitive with JobEnhancer.com</li>
                                    <li>2. Post jobs or content on any JobEnhancer.com site that contain any hyperlinks, email addresses, HTML Tags. "Hidden" keywords or any keywords that are irrelevant to the job or are otherwise misleading contained in a job posting are prohibited</li>
                                    <li>3. Use any JobEnhancer.com site CV/resume database for any purpose other than as an employer seeking employees, including but not limited to using the information in the Curriculum Vitae/Resume Database to sell or promote any products or services</li>
                                    <li>4. Post or submit to any JobEnhancer.com site any incomplete, false or inaccurate biographical information or information which is not your own</li>
                                    <li>5. Post on any JobEnhancer.com site any franchise, pyramid scheme, "club membership", distributorship or sales representative agency arrangement or other business opportunity which requires an up front or periodic payment, pays commissions only (except for postings that make clear that the available job pays commission only and clearly describes the product or service that the job seeker would be selling, in which case such postings are permissible), requires recruitment of other members, sub-distributors or sub-agents</li>
                                    <li>6. Send unsolicited mail or e-mail, making unsolicited phone calls or send unsolicited faxes regarding promotions and/or advertising of products or services to a user of any CareerIntelligence.com site</li>
                                    <li>7. Delete or revise any material posted by any other person or entity</li>
                                    <li>8. Take any action that imposes an unreasonable or disproportionately large load on any JobEnhancer.com site's infrastructure</li>
                                    <li>9. Notwithstanding anything to the contrary contained herein, use or attempt to use any engine, software, tool, agent or other device or mechanism (including without limitation browsers, spiders, robots, avatars or intelligent agents) to navigate or search JobEnhancer.com site other than the search engine and search agents available from the Company on such JobEnhancer.com site and other than generally available third party web browsers (e.g., Netscape Navigator, Microsoft Explorer)</li>
                                    <li>10. Attempt to decipher, decompile, disassemble or reverse engineer any of the software comprising or in any way making up a part of any JobEnhancer.com site</li>
                                    <li>11. Aggregate, copy or duplicate in any manner any of the JobEnhancer.com Content or information available from any JobEnhancer.com site.</li>
                                    <li>12. Frame or link to any of JobEnhancer.com Content or information available from any JobEnhancer.com site.</li>
                                </ol>
                                <br></br>
                                The Company specifically prohibits any use / insertion of any of the User's URL, weblink, e mail address and website while posting any job on www. JobEnhancer.com and all users agree not to use / insert any of the User's URL, weblink, e mail address and website while posting any job on www. JobEnhancer.com.
                            </SubTitle>
                            <SubTitle>
                                <strong>1. GENERAL</strong>
                                <br></br>
                                1.1  All postal enquires can be made to JobEnhancer.com and can be sent to JobEnhancer.com, Level 19, Two International Finance Centre, 8 Finance Street, Central, Hong Kong.
                                1.2 JobEnhancer.com does not make any representation whatsoever about any advertisement which is accessed either on, by virtue of or by way of any links from this website, nor about any of the advertisers who appear in any section on this website because, in compiling the same, JobEnhancer.com relies on information provided to it by third parties. Accordingly, it is a matter for you to satisfy yourself as to the suitability of any agency, employer or job found directly or indirectly through this website.
                                1.3 You acknowledge that the Internet or JobEnhancer.com systems, services and equipment may from time to time be inoperative in full or in part as a consequence of, but not limited to, mechanical breakdown, maintenance, hardware or software upgrades, telecommunication connectivity problems or other facts beyond the control of JobEnhancer.com and you acknowledge that JobEnhancer.com will not be held liable for any failure or inability to provide continuous, error free, uninterrupted services under these or any other circumstances.
                                1.4 JobEnhancer.com is operated by Chain Limited, company number: 69106364 registered at 9 Floor, Amtel Building, 148 Des Voeux Road, Central, Hong Kong.
                                1.5 For training and quality assurance purposes, telephone and video-conferencing calls may be recorded.
                                <br></br>
                            </SubTitle>
                            <SubTitle>
                                <strong>2. SERVICE</strong>
                                <br></br>
                                If you activate JobEnhancer.com, you agree and acknowledge that:
                                <br></br>
                                2.1. JobEnhancer.com may adapt, modify and reword in such a way and to such extent as JobEnhancer.com considers appropriate the information contained in order to produce in the style and format JobEnhancer.com sends.
                                2.2. JobEnhancer.com shall have no liability to you as a consequence of, or arising out of, any adaption, modification or rewording referred to in Clause 2.1 above.
                                <br></br>
                            </SubTitle>
                            <SubTitle>
                                <strong>3. CHANGES</strong>
                                <br></br>
                                3.1. JobEnhancer.com may make changes in or withdraw any of the services described in this website and at any time without notice.
                                3.2. JobEnhancer.com shall not be obliged to maintain or continue any services offered on this website. Without prejudice to the generality of the foregoing JobEnhancer.com reserves the right to suspend or cancel the provision of any service to any particular user for any reason whatsoever.
                                3.3. JobEnhancer.com reserves the right to change these terms and conditions of use by changing them on this website.
                                <br></br>
                            </SubTitle>
                            <SubTitle>
                                <strong>4. INFORMATION AND EMAILS SENT OR SUBMITTED TO THE WEB SITE</strong>
                                <br></br>
                                4.1. You should ensure that information you send to us or any other website user is accurate and does not breach anyone else's rights, such as copyright, or is libellous, obscene, menacing, threatening, offensive, abusive, fraudulent, criminal or infringes the rights of other people or is in any way illegal.
                                4.2. JobEnhancer.com accepts no responsibility or liability for the use of any third party which comes to your attention directly or indirectly through your use of this website.
                                4.3. If you use JobEnhancer.com you consent to JobEnhancer.com monitoring the contents of any information or message sent by virtue of the use of the website.
                                4.4. JobEnhancer.com does not represent or guarantee the truthfulness, accuracy, or reliability of communications posted by users, nor endorse any opinions expressed by users. You acknowledge that any reliance on material posted by other users will be at your own risk.
                                <br></br>
                            </SubTitle>
                            <SubTitle>
                                <strong>5. REGISTRATION AND PASSWORD</strong>
                                <br></br>
                                5.1. You are solely responsible for maintaining the confidentiality of your information and password. You shall be responsible for all uses of your registration, whether or not authorised by you. You agree to immediately notify the Company of any unauthorised use of your registration or password.
                                <br></br>
                            </SubTitle>
                            <SubTitle>
                                <strong>6. EXPRESS CONSENT AND ACKNOWLEDGEMENT</strong>
                                <br></br>
                                6.1. You acknowledge, consent and agree that when submitting your curriculum vitae/resume for storage in our curriculum vitae/resume database, your curriculum vitae/resume is automatically searchable by users who pay for access to our curriculum vitae/resume database. The Company is not responsible for the use made of curriculum vitae/resumes by users who access your curriculum vitae/resume whilst it resides in our database. You acknowledge that when your curriculum vitae/resume is displayed in our database, personal information present within your curriculum vitae/resume (such as your email address), can be collected and used by parties other than the Employers. This may result in unsolicited messages from third parties for which the Company is not responsible. The Company will attempt to limit access to our curriculum vitae/resume database to the Employers, but cannot guarantee that other parties will not gain access to this database. You may remove your curriculum vitae/resume from the Web Site at any time, but the Employers who have gained access to our database may have retained a copy of your curriculum vitae/resume in their own files or databases. The Company is not responsible for the retention, use or privacy of curriculum vitaes/resumes or profiles in these instances.
                                6.2. You acknowledge, consent and agree that if you create automatic job search agents they will automatically email you job opportunities based on your pre-selected search criteria. Your job search agent will search daily for new open positions that match your criteria. You can choose to have those search results emailed to you on a daily, weekly or monthly basis. In addition, you may change the search criteria of your job agent at any time. In relation to the aforementioned, you agree that the Company may use your personal information to contact you and deliver information relating to the job search agent's search results to you, or provide administrative notices or communications applicable to your use of the Web Site.
                                6.3 You acknowledge, consent and agree that the Company can disclose your curriculum vitae/resume and contact details ("Personal Information") to prospective Employers who purchased the Company's job posting packs in order to seek qualified candidates unless you've specifically indicated on the site that you want to keep this information private. The Company uses data about you to determine whether you might be interested in the opportunities made available through such job postings.
                                6.4 In the event that you are an Employer posting job opportunities on the Web Site, you acknowledge and agree that you will use the candidates' Personal Information disclosed to you by the Company pursuant to your purchase of the Company's job posting packs for recruitment purposes only and will not disseminate such Personal Information to any third party or use such Personal Information for any other purposes.
                                <br></br>
                            </SubTitle>
                            <SubTitle>
                                <strong>7. LINKS</strong>
                                <br></br>
                                7.1. JobEnhancer.com does not make any representation as to the security, quality or propriety of any website which may be accessed through this website. Linked websites accessed through CareerIntelligence.com are independent websites over which JobEnhancer.com does not exercise any control, whether financial, editorial or of any other kind and are not, in any way, endorsed by JobEnhancer.com.
                                7.2. Accordingly, JobEnhancer.com has no responsibility or liability to you in respect of any products or services purchased or obtained by you from any such linked website, nor in respect of the use of any personally identifying information by any such linked website or its owner.
                                <br></br>
                            </SubTitle>
                            <SubTitle>
                                <strong>8. NOTIFICATION OF CLAIMED COPYRIGHT INFRINGEMENT</strong>
                                8.1 If you believe that your copyrighted work has been uploaded, posted or copied to JobEnhancer.com and is accessible on the Web Site in a way that constitutes copyright infringement, please notify us at support@ JobEnhancer.com.
                                <br></br>
                            </SubTitle>
                            <SubTitle>
                                <strong>9. VIDEO COVER LETTERS</strong>
                                <br></br>
                                9.1 You may only submit video cover letters to your profile if you are the lawful owner of the video content and do not break or infringe any copyright laws by doing so. The content of the video must not be of an adult or unsuitable nature and JobEnhancer.com reserves the right to remove any content without warning or informing the user. The video cover letter must only contain content that is to be used in the job hunting process. At JobEnhancer.com's discretion, any advertisement for third parties or any unsuitable material deemed by JobEnhancer.com in a video cover letter is prohibited and JobEnhancer.com reserves the right to remove the content and/or terminate the users account and/or ban them from using JobEnhancer.com.
                                9.2. JobEnhancer.com will not be held responsible for the content of users' video cover letters posted on the site. As a user of the site, you accept the fact that we are not responsible for any content contained in the user posted video cover letters. However if you feel a video cover letter is unsuitable, of an adult nature, libelous or breaks any laws including copyright law then please contact support@ JobEnhancer.com informing us and we will investigate and remove the content if we agree.
                                <br></br>
                            </SubTitle>
                            <SubTitle>
                                <strong>10. TERMINATION OF ACCOUNTS</strong>
                                <br></br>
                                10.1. As a condition of your use of JobEnhancer.com, you agree not to use JobEnhancer.com to infringe and/or break any law. We will terminate the accounts of JobEnhancer.com account holder, and block access to JobEnhancer.com of any Users who use JobEnhancer.com to infringe and/or break any laws. We reserve the right to take these actions at any time, in our sole discretion, with or without notice, and without any liability to the account holder who is terminated or to the User whose access is blocked.
                                <br></br>
                            </SubTitle>
                            <SubTitle>
                                <strong>11. VIRUSES ETC</strong>
                                <br></br>
                                11.1 JobEnhancer.com will not be liable to you for any direct, indirect, consequential, special or other damage resulting from the transmission from this website to your computer systems of viruses, worms, trojan horses or other destructive items, however caused, such damage to include, without limitation, loss of profits, interruption to business, loss of the whole or any part of a programme or any data howsoever stored whether saved on a computer system or otherwise.
                                <br></br>
                            </SubTitle>
                            <SubTitle>
                                <strong>12. TRADE NAMES</strong>
                                <br></br>
                                12.1 JobEnhancer.com and its logos on this site are trade names of JobEnhancer.com. You may not use these names or logos without JobEnhancer.com's consent.
                                <br></br>
                            </SubTitle>
                            <SubTitle>
                                <strong>13. HOW JobEnhancer.com PROTECTS AND USES INFORMATION WHICH JobEnhancer.com HOLDS ABOUT YOU</strong>
                                <br></br>
                                13.1 The Internet is not a secure medium. However, JobEnhancer.com will use its reasonable endeavours to keep the personal information it receives about you confidential. Nothing in these Terms and Conditions shall restrict or prohibit JobEnhancer.com disclosing personal information in circumstances where you have expressly, or by implication, given your consent to do so including, for example, where you have submitted such information. JobEnhancer.com uses your information in accordance with its Privacy Policy from time to time and you consent to the use of your information in such way. The Company reserves the right to offer third party services and products to you based on the preferences that you identify in your registration and at any time thereafter; such offers may be made by the Company or by third parties. Without limiting any of the other disclaimers of warranty set forth in these Terms of Use, the Company does not provide or make any representation as to the quality or nature of any of the third party products or services purchased through the Web Site, or any other representation, warranty or guaranty. Any such undertaking, representation, warranty or guaranty would be furnished solely by the provider of the applicable class, or learning material, product or service, under the terms agreed to by the provider.
                                <br></br>
                            </SubTitle>
                            <SubTitle>
                                <strong>14. LOSSES RESULTING FROM UNAUTHORISED ACCESS TO YOUR DATA</strong>
                                <br></br>
                                14.1 You accept the risk that data transmitted electronically via the website, or otherwise, may be intercepted before reaching its intended destination, or accessed by unauthorised third parties and may be exploited unlawfully by such third parties. JobEnhancer.com does not assume responsibility for guarding against the acts of such third parties and shall not be liable for any direct, indirect, consequential, special or other damage resulting from third parties' interception of, or access to, data of whatever nature.
                                <br></br>
                            </SubTitle>
                            <SubTitle>
                                <strong>15. LIABILITY</strong>
                                <br></br>
                                15.1. The information contained in this site may contain technical inaccuracies and typographical errors. All information made available from within this website is provided without warranty of any kind, either express or implied on the part of JobEnhancer.com.
                                15.2. JobEnhancer.com excludes all liability it may have to you to the fullest extent permitted by law (and whether such liability arises in contract, tort or otherwise) as a result of your use of this website, or your accepting of a job vacancy obtained directly or indirectly through the website.
                                15.3. Nothing in these terms and conditions shall exclude JobEnhancer.com's liability for death or personal injury due to its negligence or for fraudulent misrepresentation.
                                <br></br>
                            </SubTitle>
                            <SubTitle>
                                <strong>16. Registration and Subscription</strong>
                                <br></br>
                                16.1. Although you may register as of member of the Service for free, if you wish to use certain other parts of the Service, you must become a subscriber and pay the fees that are set out in the price list located within the Site. This price list is part of your contract with us. We can change the subscription fees for the Service at any time but we will give you reasonable notice of this before we do so. 16.2. The value of any special offer or promotion will be deducted from the cost of membership for the first payment only and any future automatic payments will be performed at the standard rate at the end of the offer period.
                                16.3. For your convenience, we will automatically renew your subscription when your current term comes to an end.
                                16.4 If you activate JobEnhancer.com's Accelerate Your Job Hunt service you agree and acknowledge that
                                16.4.1. To enable you to manage all communications with these jobsites and to provide maximum privacy of your primary email address, we will create a new email account for registering with each jobsite. You will need to check this mailbox on a regular basis to monitor interest from recruiters and messages from the jobsites. For creation of this account, we will be using some personal information from your profile, such as your name, address, or any other information voluntarily submitted to us on this site.
                                16.4.2. JobEnhancer.com accepts no liability for any loss (whether direct or indirect, for loss of business, revenue or profits, wasted expenditure, corruption or destruction of data or for any other indirect or consequential loss whatsoever) arising from your use of the site and Accelerate Your Job Hunt service. We hereby exclude any such liability, whether in contract, tort (including for negligence) or otherwise. We hereby exclude all representations, warranties and conditions relating to this website and your use of it to the maximum extent permitted by law.
                                16.5. The CV writing and career management services available on the JobEnhancer.com website are provided by Chain Limited, company number: 69106364 registered at 9 Floor, Amtel Building, 148 Des Voeux Road, Central, Hong Kong.
                                <br></br>
                            </SubTitle>
                            <SubTitle>
                                <strong>17. REFUND POLICY</strong>
                                <br></br>
                                17.1. JobEnhancer.com offers a number of services, some free and some paid for. For any paid-for services, refunds will be given at the sole discretion of the Company. If the member is eligible for a refund after investigation, then the refunds will be reversed in 30 business days.
                                17.2. Platinum/Gold/Total Career Management/Social Media Management Memberships: This is a subscription service, which will be auto renewed monthly, bi-monthly or quarterly as per your selection at the time of making the payment. If you wish to discontinue the service at any point of time, you can do so by contacting us by raising a "Membership Cancellation" ticket through support on the website, and there will be no charge from the subsequent renewal period. However, the amount charged for the current renewal period will be applicable and cannot be refunded.
                                17.3. Our target is to provide 20-30 hand-picked vacancies per month for each client. However, in some cases of specialized roles, it may not always be possible for us to find 20-30 new vacancies per month in spite of our best efforts. In such cases, we will endeavour to provide you with vacancies based on a revised target, which will be communicated to you.
                                17.4. Accelerate your Job Hunt: In case you have uploaded a blank document or wish to update any information in your CV/resume at the time of signing up for this service, please contact us via support within 24 hours with the correct information. If your updated CV/resume is not received within 10 days, we will complete Accelerate your Job Hunt with the information provided and the service will be considered as fulfilled.
                                17.5. A cancellation comes into effect on the next working day when the cancellation is requested outside normal working hours or on a weekend or a public holiday. Incase of discontinuation of the subscription service, members are advised to send an email 3-4 days prior to the date of renewal based on their purchase date.
                                17.6. All billing is done in GBP and the prices displayed in other currencies are only a rough estimate of the amount. You are advised to check the conversion on the day you make the payment to get the actual amount. The exchange rate is updated every month.
                                17.7. CV Writing Services: If you wish to cancel your contract with JobEnhancer.com, you may receive 90% of your payment made if you cancel before you have received your first questionnaire
                                Following receipt of your core competency questionnaire, if you wish to cancel your contract with JobEnhancer.com, 50% will be deducted from your refund.
                                Following receipt of your First Draft of your CV/resume, if you wish to cancel your contract with JobEnhancer.com, 90% will be deducted from the refund.
                                Following receipt of two amended drafts, 90% will be deducted from the refund
                                If a CV Writing service cancellation is made, any additional services that are ordered at the same time, but have not been delivered, will be refunded at 90% of their purchase price. Any delivered services would be treated fulfilled.
                                17.8. From 1 month after the payment date, if no cancellation request has been received, our services would be considered fulfilled, no matter what stage in the CV writing process has been reached. In this case no refund would be available and further amendments would be at the discretion of the management. It is the customer's responsibility to answer all CV writing related information requests in a timely manner in order for JobEnhancer.com to complete the CV development and writing process.
                                17.9 Our CV Development and Executive Coaching sessions provides one-to-one, coaching sessions which can be cancelled with no penalty up to 2 working days prior to the day on which the session is booked on. If the cancellation falls with 2 working days of the day on which the session is booked a cancellation fee of US$150 may be applied. For CV Development and Executive Coaching sessions, if the session is not confirmed 48 hours before the appointment, your session can be cancelled and rescheduled at JobEnhancer.com’s discretion.
                                17.10 Failure to be available for booked session will result in ta no-show fee of US$150.
                                17.11 Executive Coaching packages made up of a multiple number of booking sessions can be refunded in part once started. The refunded amount is calculated by deducting the full normal fee of a single session at $498 from the package paid price, for each session completed.
                                <br></br>
                            </SubTitle>
                            <SubTitle>
                                <strong>18. DISCLAIMER OF CONSEQUENTIAL DAMAGES</strong>
                                <br></br>
                                18.1. In no event shall the company, its suppliers, or any third parties mentioned on the web site be liable for any damages whatsoever (including, without limitation, incidental and consequential damages, lost profits, or damages resulting from lost data or business interruption) resulting from the use or inability to use the web site and the material, whether based on warranty, contract, tort, or any other legal theory, and whether or not the company is advised of the possibility of such damages.
                                <br></br>
                            </SubTitle>
                            <SubTitle>
                                <strong>19. LIMITATION OF LIABILITY</strong>
                                <br></br>
                                19.1. JobEnhancer.com's maximum liability arising out of, or in connection with, any JobEnhancer.com Web Site or your use of the JobEnhancer.com content, regardless of the cause of action (whether in contract, tort, breach of warranty or otherwise), will not exceed HKD 150.
                                <br></br>
                            </SubTitle>
                            <SubTitle>
                                <strong>20. TERM AND TERMINATION</strong>
                                <br></br>
                                20.1. These Terms of Use will remain in full force and effect while you are a user of the Web Site at any level. The Company reserves the right, at its sole discretion, to pursue all its legal remedies, including but not limited to deletion of your User Content from the Web Site and immediate termination of your registration with your ability to access the Web Site or any other services provided to you by the Company, upon breach by you of these Terms of Use or if the Company is unable to verify or authenticate any information you submit to the Web Site. Even after you are no longer a user of the Web Site, certain provisions of these Terms of Use will remain in effect.
                                <br></br>
                            </SubTitle>
                            <SubTitle>
                                <strong>21. HEADINGS</strong>
                                <br></br>
                                21.1. Any headings in these terms and conditions are for convenience only and shall not affect their construction.
                                <br></br>
                            </SubTitle>
                            <SubTitle>
                                <strong>22. PRIVACY POLICY</strong>
                                <br></br>
                                22.1. I have read and understood the JobEnhancer.com Privacy Policy.
                                <br></br>
                            </SubTitle>
                            <SubTitle>
                                <strong>23. GOVERNING LAW</strong>
                                <br></br>
                                23.1. If not actually the case, the contract under which you use this Web Site shall be deemed to have been made in the United Kingdom and the construction, validity and performance of these terms and conditions of use shall be governed in all respects by United Kingdom law and the United Kingdom courts shall have non-exclusive jurisdiction in respect of any dispute between you and JobEnhancer.com, concerning your use of this website.
                            </SubTitle>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default TermCondition
