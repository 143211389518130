import styled from "styled-components";

export const Title = styled.h1 `
    font-size: 24px;
    font-weight: 900;
    text-transform: uppercase;
    margin-bottom: 0;
`;

export const SubTitle = styled.p `
    font-size: 16px;
    font-weight: 500;
    text-align: justify;
`;