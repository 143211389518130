import React from 'react'
import {  FormDiv,  MrDropDownDiv, MrCheckBoxDiv, TextDiv, Checkbox, Checkspan, TextBox, CheckboxTermCond, CheckboxTermCondspan, Buttons } from './FirstStep.style'
import { Dropdown } from 'react-dropdown-now';
import './drpdown.css';
import {SignUpSlideIncrease } from '../../../../redux/SignIn/SignIn.action'
import { IsSignUpSlideType } from '../../../../redux/SignIn/SignIn.selector'
import {useSelector, useDispatch } from 'react-redux'

const FirstStep = () => {

const currentSignUpState = useSelector(IsSignUpSlideType)
const dispatch = useDispatch()

const getCurrentSlide = () => {
if(currentSignUpState === 0) {
    dispatch(
        SignUpSlideIncrease(currentSignUpState+1)
    )
}
}

    return (
        <>
            <FormDiv className="">

                <TextDiv className="justify-content-start">
                    <MrCheckBoxDiv>
                        <Checkbox type="checkbox" /><Checkspan>Mr</Checkspan>
                        <Checkbox type="checkbox" /><Checkspan>Mrs</Checkspan>
                        <Checkbox type="checkbox" /><Checkspan>Miss</Checkspan>
                        <Checkbox type="checkbox" /><Checkspan>Ms</Checkspan>
                        <Checkbox type="checkbox" /><Checkspan>Dr</Checkspan>
                        <Checkbox type="checkbox" /><Checkspan>Other</Checkspan>
                    </MrCheckBoxDiv>
                    <MrDropDownDiv className="col-6">
                        <Dropdown
                            placeholder="Select an option"
                            className="my-className"
                            options={['Mr', 'Mrs', 'Miss', 'Ms', 'Dr', 'Other']}
                            value="Mr"
                            onChange={(value) => console.log('change!', value)}
                            onSelect={(value) => console.log('selected!', value)} // always fires once a selection happens even if there is no change
                            onClose={(closedBySelection) => console.log('closedBySelection?:', closedBySelection)}
                            onOpen={() => console.log('open!')}
                        />
                    </MrDropDownDiv>


                </TextDiv>
                <TextDiv >
                    <TextBox type="text" className="form-control" name="name" placeholder="Name" />
                </TextDiv>
                <TextDiv>
                    <TextBox type="email" className="form-control" name="name" placeholder="Email Id" />
                </TextDiv>
                <TextDiv>
                    <TextBox type="email" className="form-control" name="name" placeholder="Confirm Email Id" />
                </TextDiv>
                <TextDiv>
                    <Dropdown
                        placeholder="Select an option"
                        className="my-className"
                        options={['INDIA', 'USA', 'UK', 'AUS']}
                        value="INDIA"
                        onChange={(value) => console.log('change!', value)}
                        onSelect={(value) => console.log('selected!', value)} // always fires once a selection happens even if there is no change
                        onClose={(closedBySelection) => console.log('closedBySelection?:', closedBySelection)}
                        onOpen={() => console.log('open!')}
                    />
                </TextDiv>
                <TextDiv>
                    <TextBox type="password" className="form-control" name="name" placeholder="Password" />
                </TextDiv>
                <TextDiv>
                    <TextBox type="password" className="form-control" name="name" placeholder="Confirm Password" />
                </TextDiv>
                <TextDiv >
                    <CheckboxTermCond type="checkbox" /><CheckboxTermCondspan>By joining jobenhancer.com, you agree to jobenhancer.com
                        Terms of Use and Privacy Policy.
                    </CheckboxTermCondspan>
                </TextDiv>
                <div className="mb-3">
                    <Buttons variant="contained" onClick={()=> getCurrentSlide()}>Continue</Buttons>
                </div>
            </FormDiv>
        </>
    )
}

export default FirstStep
