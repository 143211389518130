import styled from "styled-components";
import { color } from "../../../styles/variable.styles";


export const BlueBg = styled.div `
  background-color: ${color.theme};
  position: sticky;
  top: 0;
  z-index: 99;
`;


export const PaddingDiv = styled.div ``;
export const Form = styled.form ``;

export const FlexDivForAllBox = styled.div ``;
export const SectionOfJobTitle = styled.div ``;
export const SectionOfJobTitleLabel = styled.div ``;

export const LabelJobTitle = styled.label ``;
export const JobSearchBox = styled.div `
  width: 60%;
  display: block;
  margin: 0 auto;
  @media (max-width: 768px) {
    width: 90%;
    display: block;
    margin: 0 auto;
  }
`;

export const MainFlex = styled.div `
  display: flex;
  justify-content: space-between;
`;

export const JobDetailFlex = styled.div `
  display: flex;
  justify-content: space-around;
`;

export const TextHolder = styled.span `
  font-weight: ${({ bold }) => (bold ? 600 : 400)};
  font-size: ${({ font }) => (font ? "20px" : "14px")};
`;

export const BoldText = styled.div `
  font-size: 24px;
  font-weight: 800;
`;

export const JobDetails = styled.div `
  font-size: 16px;
  line-height: 24px;
  text-align: justify;
  margin-right: 10px;
`;

export const InputHolderJobTitle = styled.input `
  border-top-left-radius: 40px;
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
  border-right: 1px solid ${color.theme};
  padding: 6px 15px;
  outline: none !important;
  color: #0066c2;
  font-size: 16px;
  font-weight: 600;
  text-transform: lowercase;
  margin-bottom: 5px;
  &:focus {
    box-shadow: none;
    border-bottom: 5px solid ${color.goldButton};
    margin-bottom: 0px;
    color: ${color.theme} !important;
  }
  @media (max-width: 426px) {
    border-bottom-right-radius: 40px;
    border-top-right-radius: 40px;
  }
`;

export const SectionOfJobLocation = styled.div ``;

export const SectionOfJobLocationLabel = styled.div ``;

export const LabelJobLocation = styled.label ``;

export const InputHolderJobLocation = styled.input `
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-right: 1px solid ${color.theme};
  padding: 6px 15px;
  outline: none !important;
  color: #0066c2;
  font-size: 16px;
  font-weight: 600;
  text-transform: lowercase;
  margin-bottom: 5px;
  &:focus {
    box-shadow: none;
    border-bottom: 5px solid ${color.goldButton};
    margin-bottom: 0px;
    color: ${color.theme} !important;
  }
  @media (max-width: 426px) {
    border-bottom-right-radius: 40px;
    border-top-right-radius: 40px;
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
  }
`;

export const SectionOfJobButton = styled.div ``;

export const SectionOfButtonLabel = styled.div ``;

export const LabelOfButton = styled.label ``;

export const ButtonHolder = styled.button `
  color: ${color.theme};
  background-color: #fff;
  font-size: 16px;
  padding: 6.88px 20px;
  border-bottom-right-radius: 40px;
  border-top-right-radius: 40px;
  border: none;
  text-transform: uppercase;
  font-weight: 600;
  &:focus {
    box-shadow: none;
    border-bottom: 5px solid ${color.goldButton};
  }
  @media (max-width: 426px) {
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
  }
`;

export const Icons = styled.img `
  width: auto;
  height: 22px;
  margin-right: 5px;
`;

export const JobText = styled.div `
  margin-right: 20px;
`;
export const BtnItem = styled.div `
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  /* padding: 0.375rem 0.75rem; */
  margin-right: 10px;
  margin-bottom: 20px;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
`;

export const DisplayFlex = styled.div `
  display: flex;
  @media (max-width: 426px) {
    display: block;
  }
`;

export const BorderBottom = styled.div `
  border-bottom: 1px solid #e8e7e7;
`;

export const PaddingAllSide = styled.div `
  padding: 20px 20px 20px;
`;
export const TitleTextMain = styled.h3 `
  font-size: 24px;
  font-weight: 800;
  font-family: "Raleway", sans-serif;
`;
export const TitleText = styled.h4 `
  font-size: 16px;
  font-weight: 800;
  font-family: "Raleway", sans-serif;
`;

export const TableTd = styled.td `
  font-weight: bold;
  padding-right: 20px;
  font-size: 14px;
`;