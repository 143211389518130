import React from 'react'
import {  FormDiv, BtnContainer, BasicButton, Logo, Text, GoldButton, PlatinumButton,  } from './FifthStep.style'
import BasicImage from '../../../../assets/body/services/basic-search.svg'
import GoldImage from '../../../../assets/body/services/gold.svg'
import PlatinumImage from '../../../../assets/body/services/platinum.svg'


const FifthStep = () => {
    return (
        <>
            <FormDiv className="">

                <BtnContainer>
                    <BasicButton className="d-flex justify-content-around text-center" to="/basiclandpage">
                        <Logo src={BasicImage} alt="" />
                        <Text>Start Job Search</Text>
                    </BasicButton>

                    <GoldButton className="d-flex justify-content-around text-center" to="/goldlandingpage">
                        <Logo src={GoldImage} alt="" />
                        <Text>Start Job Search</Text>
                    </GoldButton>

                    <PlatinumButton className="d-flex justify-content-around text-center" to="/platinumlandingpage">
                        <Logo src={PlatinumImage} alt="" />
                        <Text>Start Job Search</Text>
                    </PlatinumButton>
                </BtnContainer>
            </FormDiv>
        </>
    )
}

export default FifthStep
