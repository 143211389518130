import React from 'react'
import { MobileFormName, ImageDiv, FormName, StepOneImage, } from './FirstStep.style'
import FormOneImage from '../../../../assets/body/signup/img1.svg';


const FirstStep = () => {
    return (
        <>
            <MobileFormName>Tell us who you are</MobileFormName>

            <ImageDiv className="">
                <FormName>Tell us who you are</FormName>
                <StepOneImage src={FormOneImage} alt="" />
            </ImageDiv>
        </>
    )
}

export default FirstStep
