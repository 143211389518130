import styled from "styled-components";
import { color } from "../../../../styles/variable.styles";

export const FormDiv = styled.div `
padding: 40px;
@media (max-width: 1024px) {
  padding: 25px 12px;
  width: 100%;
}
`;

export const TextDiv = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`;

export const MrCheckBoxDiv = styled.div `
  display: block;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const MrDropDownDiv = styled.div `
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`;
export const Checkbox = styled.input `
  height: 15px;
  width: 15px;
  margin-right: 10px;
  @media (max-width: 1024px) {
    margin-right: 5px;
  }
`;
export const Checkspan = styled.span `
  margin-right: 20px;
  @media (max-width: 1024px) {
    margin-right: 10px;
  }
`;

export const TextBox = styled.input `
  border: 0;
  border-bottom: 1px solid #ced4da !important;
  border-radius: 0;
  width: 100%;
  font-weight: 600;
  padding: 7px 7px;
  &:focus {
    outline: none !important;
    border-color: #fff;
    box-shadow: none;
  }
`;

export const CheckboxTermCond = styled.input `
  height: 20px;
  width: 25px;
  margin-right: 10px;
  @media (max-width: 768px) {
    height: 16px;
    width: 38px;
  }
`;
export const CheckboxTermCondspan = styled.span `
  font-size: 0.875em;
  font-weight: 600;
`;

export const Buttons = styled.button `
  border: 1px solid ${color.theme} !important;
  background-color: ${color.theme};
  color: ${color.regularfontwhite};
  border-radius: 50px;
  text-transform: uppercase;
  padding: 0.5rem 1.5rem;
  font-size: 13px;
  font-weight: 500;
  margin: 10px 0;
  transition: 0.3s;
  border: none;
`;