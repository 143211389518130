import { Link } from "react-router-dom";
import styled from "styled-components";
import { color } from "../../styles/variable.styles";

export const BlueBgDiv = styled.div `
  background-color: ${color.theme};
`;

export const WhiteBox = styled.div `
  background-color: ${color.regularfontwhite};
`;

export const ImageBg = styled.div `
  box-shadow: 1px 0px 10px #b1b1b1;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const SignInImage = styled.img `
  width: 100%;
`;

export const FormDiv = styled.div `
@media (max-width: 768px) {
    width: 100%;
  }
`;

export const TitleText = styled.h1 `
  text-transform: uppercase;
  font-size: 30px;
  font-weight: 800;
  text-align: start;
`;

export const InpFlex = styled.div `
  border-bottom: 1px solid gray;
  padding: 5px;
  margin-top: 20px;
`;

export const InpBox = styled.input `
  width: 100%;
  border: 0;
  border-bottom: none !important;
  border-radius: 0;
  font-weight: 600;
  padding: 0px 7px;
  &:focus {
    outline: none !important;
    border-color: #fff;
    box-shadow: none;
  }
`;

export const ForgotPass = styled.div `
  margin-top: 20px;
  display: flex;
  @media (max-width: 1024px) {
    display: block;
  }
  @media (max-width: 768px) {
    display: flex;
  }
  @media (max-width: 426px) {
    display: block;
  }
`;

export const ForgotPassText = styled(Link)
`
  font-weight: 400;
  font-size: 15px;
  text-decoration: none;
  color: #000;
  display: flex;
  @media (max-width: 1024px) {
    margin-bottom: 20px;
  }
`;

export const RemberMe = styled.div ``;

export const Checkbox = styled.input `
  height: 15px;
  width: 15px;
  margin-right: 5px;
  margin-top: 3px;
  @media (max-width: 1024px) {
    margin-right: 5px;
  }
`;

export const Checkspan = styled.span `
  font-weight: 400;
  font-size: 15px;
  text-decoration: none;
  color: #000;
`;

export const SignInButton = styled.button `
  display: flex;
  border: 1px solid ${color.theme};
  background-color: ${color.theme};
  color: #fff;
  border-radius: 50px;
  text-transform: uppercase;
  padding: 10px 18px;
  font-size: 13px;
  font-weight: 500;
  margin: 20px 0;
  transition: 0.3s;
  &:hover {
    background-color: ${color.regularfontwhite};
    color: ${color.theme};
  }
`;

export const SignUpButtonDiv = styled.div `
  border-top: 1px solid #ccc;
  padding: 20px 20px 0px 20px;
`;

export const SignUpButtonText = styled.h2 `
  font-size: 18px;
  font-weight: 600;
  text-align: start;
`;

export const SignUpButton = styled.button `
  display: flex;
  border: 1px solid ${color.theme};
  background-color: ${color.regularfontwhite};
  color: #0073db;
  border-radius: 50px;
  text-transform: uppercase;
  padding: 10px 18px;
  font-size: 13px;
  font-weight: 500;
  margin: 20px 0;
  transition: 0.3s;
  &:hover {
    background-color: ${color.theme};
    color: ${color.regularfontwhite};
  }`;