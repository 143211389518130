import React, { useState } from 'react';
import { FormDiv, TextDiv, TextBox, RadioSpan, RadioButton, CheckboxTermCondspan, Buttons, ButtonDiv } from './SecondStep.style'
import { Dropdown } from 'react-dropdown-now';
import '../First Step/drpdown.css';
import { SignUpSlideIncrease, SignUpSlideDecrease } from '../../../../redux/SignIn/SignIn.action'
import { IsSignUpSlideType } from '../../../../redux/SignIn/SignIn.selector'
import { useSelector, useDispatch } from 'react-redux'

const SecondStep = () => {
    const [checkBox, setcheckBox] = useState(false)

    const currentSignUpState = useSelector(IsSignUpSlideType)
    const dispatch = useDispatch()

    const getCurrentSlide = () => {
        if (currentSignUpState === 1) {
            dispatch(
                SignUpSlideIncrease(currentSignUpState + 1),
            )
        }
    }

    const getCurrentSlideDecrease = () => {
        if (currentSignUpState === 1) {
            dispatch(
                SignUpSlideDecrease(currentSignUpState - 1)
            )
        }
    }

    return (
        <>
            <FormDiv className="">

                <TextDiv>
                    <TextBox type="text" className="form-control" name="name" placeholder="Current Job Title" />
                </TextDiv>
                <TextDiv>
                    <TextBox type="email" className="form-control" name="name" placeholder="Current Industry" />
                </TextDiv>
                <div className="d-flex col-12">
                    <TextDiv className="col-4" >
                        <Dropdown
                            placeholder="Select an option"
                            className="my-className"
                            options={['USD', 'USA', 'RS']}
                            value="USD"
                            onChange={(value) => console.log('change!', value)}
                            onSelect={(value) => console.log('selected!', value)} // always fires once a selection happens even if there is no change
                            onClose={(closedBySelection) => console.log('closedBySelection?:', closedBySelection)}
                            onOpen={() => console.log('open!')}
                        />
                    </TextDiv>
                    <TextDiv className="col-8" style={{ borderLeft: '1px solid #ced4da', }}>
                        <Dropdown
                            placeholder="Select an option"
                            className="my-className"
                            options={['50K - 60K P Y', '60K - 70K P Y', '70K - 80K P Y', '80K - 90K P Y']}
                            value="50K - 60K P Y"
                            onChange={(value) => console.log('change!', value)}
                            onSelect={(value) => console.log('selected!', value)} // always fires once a selection happens even if there is no change
                            onClose={(closedBySelection) => console.log('closedBySelection?:', closedBySelection)}
                            onOpen={() => console.log('open!')}
                        />
                    </TextDiv>
                </div>

                <div className="d-flex col-12">
                    <TextDiv className="col-4" >
                        <Dropdown
                            placeholder="Select an option"
                            className="my-className"
                            options={['91+', '54+', '61+', '43+']}
                            value="91+"
                            onChange={(value) => console.log('change!', value)}
                            onSelect={(value) => console.log('selected!', value)} // always fires once a selection happens even if there is no change
                            onClose={(closedBySelection) => console.log('closedBySelection?:', closedBySelection)}
                            onOpen={() => console.log('open!')}
                        />
                    </TextDiv>
                    <TextDiv className="col-8" style={{ borderLeft: '1px solid #ced4da', }}>
                        <TextBox type="number" className="form-control" name="name" placeholder="Phone" />
                    </TextDiv>
                </div>

                <div className="col-12 d-flex justify-content-between">
                    <TextDiv className="col-5 inline-block mb-2 mr-2" style={{ marginRight: '20px' }}>
                        <TextBox type="password" className="form-control" name="name" placeholder="Experience Year" />
                    </TextDiv>
                    <TextDiv className="col-6 inline-block mb-2 mr-2">
                        <TextBox type="password" className="form-control" name="name" placeholder="Experience Month" />
                    </TextDiv>
                </div>

                <div >
                    <TextDiv className="d-flex justify-content-between my-1">
                        <RadioSpan>Hide Email id from recruiters</RadioSpan>
                        <RadioButton onChange={() => setcheckBox(!checkBox)} type="checkbox" value="Hide Email" name="HideEmail" />
                    </TextDiv>
                    <TextDiv className="d-flex justify-content-between my-1">
                        <RadioSpan>Hide Name from recruiters</RadioSpan>
                        <RadioButton onChange={() => setcheckBox(!checkBox)} type="checkbox" value="Hide Email" name="HideEmail" />
                    </TextDiv>
                    <TextDiv className="d-flex justify-content-between my-1">
                        <RadioSpan>Recruiters view your CV or Not</RadioSpan>
                        <RadioButton onChange={() => setcheckBox(!checkBox)} type="checkbox" value="Hide Email" name="HideEmail" />
                    </TextDiv>
                    <TextDiv className="d-flex justify-content-between my-1">
                        <RadioSpan>Receive job alerts via email</RadioSpan>
                        <RadioButton onChange={() => setcheckBox(!checkBox)} type="checkbox" value="Hide Email" name="HideEmail" />
                    </TextDiv>

                </div>

                <TextDiv >
                    <CheckboxTermCondspan>By joining jobenhancer.com, you agree to jobenhancer.com
                        Terms of Use and Privacy Policy.
                    </CheckboxTermCondspan>

                </TextDiv>


                <ButtonDiv>
                    <Buttons variant="contained" onClick={() => getCurrentSlideDecrease()} style={{ marginRight: '10px' }}>Back</Buttons>
                    <Buttons variant="contained" onClick={() => getCurrentSlide()}>Continue</Buttons>
                </ButtonDiv>
            </FormDiv>
        </>
    )
}

export default SecondStep
