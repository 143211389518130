import styled from "styled-components";
import { Link } from "react-router-dom";

export const FlexBox = styled.div `
  justify-content: space-between;
  display: flex;
`;

export const LogoSize = styled.img `
  width: 70px;
  height: auto;
  padding: 8px 0;
`;

export const Logolink = styled(Link)
``;

export const DropdownContent = styled.div `
  display: none;
  position: absolute;
  background-color: #fff;
  min-width: 160px;
  /* box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2); */
  border: 1px solid #000;
  /* margin-top: 5px; */
  z-index: 999;
  border-radius: 8px;
`;

export const Dropdown = styled.div `
  float: left;
  overflow: hidden;
  &:hover ${DropdownContent} {
    display: block;
  }
`;

export const DropBtn = styled.button `
  outline: none;
  color: #000;
  padding: 2px 20px;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
  border: 1px solid #000;
  border-radius: 20px;
  text-transform: uppercase;
  /* font-weight: 500; */
  font-size: 15px;
`;

export const Menus = styled(Link)
`
  float: none;
  color: black;
  padding: 3px 16px;
  border-bottom: ${({ Borderb }) => (Borderb ? "none" : "1px solid #000")}; 
  text-decoration: none;
  display: block;
  text-align: left;
  font-size: 15px;
  text-transform: uppercase;
  :hover {
    background-color: #ddd;
  }
`;

export const Icons = styled.img `
  width: 15px;
  margin-right: 10px;
`;

export const DIcons = styled.img `
  width: 30px;
  margin-right: 10px;
`;