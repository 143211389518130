import React from 'react'
import { MobileFormName, ImageDiv, FormName, StepFourImage } from './FourthStep.style'
import FormFourImage from '../../../../assets/body/signup/img4.svg';

const FourthStep = () => {
    return (
        <>
            <MobileFormName>Share your profile with us</MobileFormName>

            <ImageDiv className="">
                <FormName>Share your profile with us</FormName>
                <StepFourImage src={FormFourImage} alt="" />
            </ImageDiv>
        </>
    )
}

export default FourthStep
