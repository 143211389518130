import styled from 'styled-components';
import { color } from '../../styles/variable.styles';
import { Link } from 'react-router-dom';

export const Row = styled.div `

`;

export const Col = styled.div `

`;

export const MainFlex = styled.div `
padding: 0px 20px;
`;

export const MainTitleDiv = styled.div `

`;

export const FlexForExperience = styled.div `

`;

export const ExperienceDiv = styled.div `

`;

export const ExpIconSpan = styled.span `

`;

export const ExpIconTextSpan = styled.span `
@media (max-width: 426px){
    margin-top: 10px;
}
`;

export const LocationDiv = styled.div `

`;

export const LocationIconSpan = styled.div `
@media (max-width: 426px){
    margin-top: 10px;
}
`;

export const LocationTextSpan = styled.span `

`;
export const BoldText = styled.div `
    font-size: 24px;
    font-weight: 800;
`;

export const JobDetails = styled.div `
    font-size: 16px;
    line-height: 24px;
    text-align: justify;
    margin-right: 10px;
`;

export const InputHolderJobTitle = styled.input `
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
    @media(max-width:426px) {
        border-bottom-right-radius: 40px;
        border-top-right-radius: 40px;
    }
`;

export const InputHolderJobLocation = styled.input `
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    @media(max-width:426px) {
        border-bottom-right-radius: 40px;
        border-top-right-radius: 40px;
        border-top-left-radius: 40px;
        border-bottom-left-radius: 40px;
    }
`;

export const ButtonHolder = styled.button `
    color: ${color.theme};
    background-color: #fff;
    font-size: 16px;
    padding: 6.9px 20px;
    border-bottom-right-radius: 40px;
    border-top-right-radius: 40px;
    border: none;
    text-transform: uppercase;
    font-weight: 600;
    @media(max-width:426px) {
        border-top-left-radius: 40px;
        border-bottom-left-radius: 40px;
    }
`;

export const Icons = styled.img `
    width: auto;
    height: 22px;
    margin-right: 5px;
`;

export const JobText = styled.div `
    margin-right: 20px;
`;
export const BtnItem = styled.div `
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    /* padding: 0.375rem 0.75rem; */
    margin-right: 10px;
    margin-bottom: 20px;
    font-size: 1rem;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
`;

export const ThreeButton = styled.div `
    /* border: 1px solid #0073db; */
    background-color: #fff;
    color: #0073db;
    border-radius: 50px;
    text-transform: uppercase;
    /* padding: 10px 18px; */
    font-size: 13px;
    font-weight: 500;
    margin: 10px 0;
    transition: 0.3s;
    text-decoration: none !important;
    
`;

export const DisplayFlex = styled.div `
    display: flex;
    @media(max-width: 426px) {
        display: block;
    }
`;

export const BtnZero = styled(Link)
`
   &:hover {
    background-color: ${color.theme};
    color: ${color.regularfontwhite};
    }

    border: 1px solid ${color.theme};
    color: #0073db;
    font-weight: bold;
    cursor: pointer;
    border-radius: 50px;
    text-decoration: none !important;


`;


export const BtnTwo = styled(Link)
`
   &:hover {
    background-color: ${color.platinumButton};
    color: ${color.regularfontwhite};
    }
    border: 1px solid ${color.platinumButton};
    color: #db007c;
    font-weight: bold;
    cursor: pointer;
    border-radius: 50px;
    text-decoration: none !important;

`;

export const BorderBottom = styled.div `
 border-bottom: 1px solid #e8e7e7;
`;

export const PaddingAllSide = styled.div `
 padding: 20px 20px 20px;
`;
export const TitleTextMain = styled.h3 `
    font-size: 24px;
    font-weight: 800;
    font-family: "Raleway", sans-serif;
`;

export const JobResponsibilitiesList = styled.ul `
@media (max-width: 426px){
    padding: 0px;
}
`;

export const KeySkillsPreferredList = styled.ul `
@media (max-width: 426px){
    padding: 0px;
}
`;
export const TitleText = styled.h4 `
    font-size: 16px;
    font-weight: 800;
    font-family: "Raleway", sans-serif;
`;


export const TableTd = styled.td `
    font-weight: bold;
    padding-right: 20px;
    font-size: 14px;
`;



export const CarouselTitle = styled.h3 `
  font-size: 24px;
  font-weight: 900;
  text-transform: uppercase;
  margin-bottom: 0;
`;

export const ModalHead = styled.div `
  background-color: ${color.theme};
  text-align: right;
  padding: 5px 10px;
`;

export const ButtonBG = styled.div `
  background-color: #fff;
  color: #0073db;
  border-radius: 50px;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 500;
  margin: 10px 0;
  transition: 0.3s;
  text-decoration: none !important;
`;

export const ThemeCancelButton = styled(Link)
`
  &:hover {
    background-color: ${({ CV }) => (CV ? "#fff" : "#0073db")};
    color: ${({ CV }) => (CV ? "#0073db" : "#fff")};
  }
  border: 1px solid ${color.theme};
  color: ${({ CV }) => (CV ? "#fff" : "#0073db")};
  background-color: ${({ CV }) => (CV ? "#0073db" : "#fff")};
  font-weight: 500;
  font-size: 13px;
  padding: 0.4rem 30px;
  cursor: pointer;
  border-radius: 50px;
  text-decoration: none !important;
`;

export const UpImgCont = styled.img `
  display: block;
  margin: 0 auto;
  height: 90px;
  width: 90px;
`;