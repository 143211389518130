import styled from "styled-components";
import { color } from "../../../../styles/variable.styles";

export const JSMobileTabsContainer = styled.div ``;

export const JSTabs = styled.div `
  /* background-color: #d8d8d8; */
  border: none;
  outline: none;
  cursor: pointer;
  padding: 11px 15px;
  transition: 0.3s;
  width: 45%;
  border-top-left-radius: ${({ LeftRadius }) => (LeftRadius ? "20px" : "0px")};
  border-top-right-radius: ${({ RightRadius }) =>
    RightRadius ? "20px" : "0px"};
`;

export const JSTabsTitle = styled.h5 `
  font-size: 17px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 0px;
`;

export const CountContainer = styled.div ``;

export const ContsDiv = styled.div `
  border: 1px solid #dfdfdf;
  border-radius: 25px;
  padding: 5px 10px;
  width: 150px;
`;

export const JobsCount = styled.p `
text-align: center;
font-size: 14px;
font-weight: 700;
margin-bottom: 0px;
color: ${({ theme }) => (theme ? "#0073db" : "#000")};
`;

export const MCard = styled.div `
  @media (max-width: 769px) {
    width: 48%;
    float: left;
    margin-right: 10px;
  }
  @media (max-width: 426px) {
    width: 100%;
    margin-right: 0px;
  }
`;

export const MCompanyName = styled.p `
  color: ${color.theme};
  font-weight: 700;
  padding-left: 10px;
`;

export const Mtd = styled.td `
  font-weight: 600;
  padding-left: 10px;
  padding-top: 7px;
  padding-bottom: 7px;
  color: ${({ Link }) => (Link ? "#0073db" : "#000")};
  text-decoration: ${({ Link }) => (Link ? "underline" : "none")};
`;