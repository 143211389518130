import React from 'react'
import { Container } from "react-bootstrap";
import Header from '../Header/header'
import {
    BlueBgDiv,
    WhiteBox,
    ImageBg,
    SignInImage,
    FormDiv,
    TitleText,
    SubTitleText,
    InpFlex,
    InpBox,
    SignInButton,
    ButtonFlex
    
} from "./forgotPassword.style";
import Image from "../../assets/body/signin/img2.svg";
import { Mail } from "react-feather";
import { useHistory } from "react-router-dom";

const forgotPassword = () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    let history = useHistory();


    function SignIn() {
        
        history.push("/signin");
    }
    return (
        <div>
            <Header />
            <BlueBgDiv>
                <Container>
                    <div className="row">
                        <div className="mb-5 mt-5">
                            <WhiteBox className="col col-md-8 m-auto">
                                <div className="m-auto d-flex flex-wrap text-center">
                                    <ImageBg className="col-12 col-md-6 bg-white py-5 px-4">
                                        <SignInImage src={Image} alt="" />
                                    </ImageBg>
                                    <FormDiv className="col-md-6" >
                                        <div className="pt-5 pb-3 px-4">
                                            <TitleText>Forgot password?</TitleText>
                                            <SubTitleText>Enter your Email ID to recieve a "Reset Password" link</SubTitleText>

                                            <InpFlex className="d-flex">
                                                <Mail width="30" color="#0073db" />
                                                <InpBox type="email" placeholder="Enter Email Id" />
                                            </InpFlex>

                                            <ButtonFlex >
                                                <SignInButton style={{marginRight: '15px'}}>Reset Password</SignInButton>
                                                <SignInButton onClick={SignIn}>Back To Login</SignInButton>
                                            </ButtonFlex>


                                        </div>

                                    </FormDiv>
                                </div>
                            </WhiteBox>
                        </div>
                    </div>
                </Container>
            </BlueBgDiv>
        </div>
    )
}

export default forgotPassword
