import styled from "styled-components";
import { color } from "../../styles/variable.styles";

export const SerachBarMobile = styled.div `
  display: none;
  @media (max-width: 426px) {
    display: block;
    background-color: ${color.theme};
    padding: 10px 50px;
    position: sticky;
    top: 0;
    z-index: 99;
  }
`;

export const FindJobBtn = styled.div `
  border: none;
  color: ${color.theme};
  font-weight: 600;
  background-color: ${color.regularfontwhite};
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 20px;
`;

export const MobileViewSerchBox = styled.div `
  // display: none;
`;
export const MobileFullScreen = styled.div `
  width: 100%;
  flex: 0 0 100%;
  background-color: #0073db;
  position: fixed !important;
  top: 0px !important;
  z-index: 999999;
  left: 0px;
  width: 100%;
  height: 100vh;
  margin: auto;
`;

export const InputHolderJobTitle = styled.input `
  border-top-left-radius: 40px;
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
  border-right: 1px solid #0066c2;
  outline: none !important;
  color: #0066c2;
  font-size: 16px;
  font-weight: 600;
  text-transform: lowercase;
  margin-bottom: 5px;
  padding: 5px 15px;
  @media (max-width: 426px) {
    border-bottom-right-radius: 40px;
    border-top-right-radius: 40px;
  }
`;

export const InputHolderJobLocation = styled.input `
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-right: 1px solid #0066c2;
  outline: none !important;
  color: #0066c2;
  font-size: 16px;
  font-weight: 600;
  text-transform: lowercase;
  margin-bottom: 5px;
  padding: 5px 15px;
  @media (max-width: 426px) {
    border-bottom-right-radius: 40px;
    border-top-right-radius: 40px;
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
  }
`;

export const ButtonHolder = styled.button `
  color: ${color.theme};
  background-color: #fff;
  font-size: 16px;
  padding: 6px 20px;
  border-bottom-right-radius: 40px;
  border-top-right-radius: 40px;
  border: none;
  text-transform: uppercase;
  font-weight: 600;
  @media (max-width: 426px) {
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
  }
`;

export const DeskSearchBox = styled.div `
  display: block;
  @media (max-width: 426px) {
    display: none;
  }
`;

export const RefineBy = styled.div `
  @media (max-width: 768px) {
    display: none;
  }
`;
export const Categories = styled.div `
  border-right: 1px solid #dfdfdf;
  padding-right: 8px;
`;

export const Title = styled.h6 `
  font-weight: 700;
  color: ${({ blue }) => (blue ? "#0073db" : "black")};
`;

export const SideBar = styled.div `
  width: 100%;
  height: 220px;
  background-color: #f8f8f8;
  padding: 10px;
  line-height: 2;
  overflow-x: hidden;
  overflow-y: scroll;
`;

export const ResultSection = styled.div ``;

export const TitleContainer = styled.div `
  border-bottom: 1px solid #dfdfdf;
`;

export const IMGContainer = styled.div ``;

export const IMG = styled.img ``;

export const SearchNotFoundText = styled.h5 `
  text-align: center;
  font-weight: bold;
`;

export const RefineByMobile = styled.div `
  display: none;
  @media (max-width: 769px) {
    display: block;
  }
`;

export const FilterButton = styled.div `
  background-color: #0073db;
  color: #fff;
  font-size: 15px;
  font-weight: 700;
  bottom: 0;
  margin-bottom: 1px;
  position: fixed;
  width: 100%;
  border-radius: 30px;
  z-index: 99999;
`;

export const FilterButtonText = styled.h6 `
  font-size: 20px;
`;

export const MobileFilter = styled.div `
  display: block;
  height: 100%;
  width: 100%;
  background-color: rgb(255, 255, 255);
  position: fixed;
  top: 0px;
  z-index: 9999;
  margin-top: 0px;
`;

export const FlexForTitle = styled.div `
  border-bottom: 1px solid #dfdfdf;
`;

export const Closesign = styled.p `
  font-size: 40px;
  float: right;
  padding: 0px 20px 0 0px;
  position: relative;
  z-index: 9999;
  color: #0073db;
  line-height: 0.7;
`;

export const TabNameContainer = styled.div `
  background-color: #e4f2f7;
  height: 100vh;
`;

export const TabsContainer = styled.div `
  margin-top: 15px;
`;

export const TabName = styled.h4 `
  align-self: center;
  padding: 10px 0 10px 5px;
  margin-bottom: 0px;
  /* padding-left: 5px; */
  font-size: medium;
  font-weight: 600;
  cursor: pointer;
`;

export const SearchBar = styled.input `
border: none;
width: 100%;
padding-left: 5px;
:focus-visible {
    outline: none;
}
`;