/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';
import { Container } from 'react-bootstrap';
import Header from '../Header/header';
import { JobSearchBlue, JSMainText, JSBottomText, JSFormDiv, JSLabel, JSJobTitleInpBox, JSJobLocInpBox, JSFindJobBtn, CarouselTitle, } from './jobsearch.style'
import Carousel from '../carousel/carousel'
import JobLocation from './JobLocation/JobLocation'
import JobOfferCard from './JobOfferCard/JobOfferCard'


const jobsearch = () => {


    return (
        <div>
            <Header />

            <JobSearchBlue>
                <Container>
                    <div className="row">
                        <div className="col">
                            <div className="text-center my-4">
                                <JSMainText>Find Your Dream Job</JSMainText>
                            </div>
                            <JSFormDiv className="col-md-10 m-auto">
                                <form>
                                    <div className="d-md-flex flex-wrap justify-content-center mx-auto text-center">
                                        <div className="col-md-4 mb-3 mx-2 mx-md-0 text-start">
                                            <JSLabel>Job Title</JSLabel>
                                            <JSJobTitleInpBox type='text' className="form-control" name="name"
                                                placeholder="Job Title, Keywords & Company Name" />
                                        </div>
                                        <div className="col-md-4 mb-3 mx-2 mx-md-0 text-start">
                                            <JSLabel>Job Location</JSLabel>
                                            <JSJobLocInpBox type='text' className="form-control" name="name"
                                                placeholder="City, State or Zipcode" />
                                        </div>
                                        <div className="col-md-2 col-lg-2 mb-3 mx-2 mx-md-0 text-center">
                                            <div className="d-none d-md-block" style={{ marginBottom: '10px' }}>
                                                <label>&nbsp;</label>
                                            </div>
                                            {/* <JSLabel style={{color: "#0073db"}}>Job Location</JSLabel> */}
                                            <JSFindJobBtn type="submit" className="d-md-block my-4 my-md-0">Find Jobs</JSFindJobBtn>
                                        </div>
                                    </div>
                                </form>
                            </JSFormDiv>
                            <div className="text-center my-1">
                                <JSBottomText>2,190 jobs added since 25-FEB-20</JSBottomText>
                            </div>
                        </div>
                    </div>
                </Container>
            </JobSearchBlue>
            <Container className="position-relative">
                <div>
                    <div className="row my-3 align-items-center justify-content-between">
                        <div className="col">
                            <CarouselTitle>Latest Job</CarouselTitle>
                        </div>
                        {/* <div className="nav-btn-box d-flex col justify-content-end  my-lg-0 align-items-center position-absolute" style={{ top: '0', right: '0' }}>
                            <ArrowBg className="rounded-circle" onClick={() => Slider?.current.slidePrev()}>
                                <ChevronLeft color="#fff" size={35} strokeWidth="4" />
                            </ArrowBg>
                            <ArrowBg className="rounded-circle mx-2" onClick={() => Slider?.current.slideNext()}>
                                <ChevronRight color="#fff" size={35} strokeWidth="4" />
                            </ArrowBg>
                        </div> */}
                    </div>
                    <Carousel />
                </div>
            </Container>

            <JobLocation />
            <JobOfferCard />
        </div>
    )
}

export default jobsearch
