import styled from "styled-components";
import { color } from "../../styles/variable.styles";

export const JobSearchBlue = styled.div `
  padding: 30px 0;
  background-color: ${color.theme};
  color: ${color.regularfontwhite};
`;

export const JSMainText = styled.h1 `
  font-size: 30px;
  font-weight: 800;
  color: ${color.regularfontwhite};
  margin-bottom: 20px;
  font-family: "Raleway", sans-serif;
`;

export const JSBottomText = styled.p `
  font-size: 14px;
  font-weight: 500;
`;

export const JSFormDiv = styled.div ``;

export const JSLabel = styled.label `
  margin-left: 25px;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 500;
  font-family: "Raleway", sans-serif;
`;

export const JSJobTitleInpBox = styled.input `
  border: none;
  padding: 15px 25px;
  border-right: 1px solid ${color.theme};
  outline: none !important;
  color: ${color.theme} !important;
  font-size: 16px;
  font-weight: 600;
  text-transform: lowercase;
  margin-bottom: 5px;
  border-top-left-radius: 40px;
  border-bottom-left-radius: 40px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  font-family: "Raleway", sans-serif;
  &:focus {
    box-shadow: none;
    border-bottom: 5px solid ${color.goldButton};
    margin-bottom: 0px;
    color: ${color.theme} !important;
  }
  @media (max-width: 426px) {
    border-top-right-radius: 40px !important;
    border-bottom-right-radius: 40px !important;
  }
`;

export const JSJobLocInpBox = styled.input `
  border: none;
  border-radius: 0;
  padding: 15px 25px;
  border-right: 1px solid ${color.theme};
  outline: none !important;
  color: ${color.theme} !important;
  font-size: 16px;
  font-weight: 600;
  text-transform: lowercase;
  margin-bottom: 5px;
  font-family: "Raleway", sans-serif;
  &:focus {
    box-shadow: none;
    border-bottom: 5px solid ${color.goldButton};
    margin-bottom: 0px;
    color: ${color.theme} !important;
  }
  @media (max-width: 426px) {
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
    border-top-right-radius: 40px !important;
    border-bottom-right-radius: 40px !important;
  }
`;

export const JSFindJobBtn = styled.button `
  border: 0;
  border-top-right-radius: 40px;
  border-bottom-right-radius: 40px;
  vertical-align: middle;
  background-color: ${color.regularfontwhite};
  padding: 15px 30px;
  color: ${color.theme};
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  outline: none;
  margin-bottom: 5px;
  font-family: "Raleway", sans-serif;
  &:focus {
    box-shadow: none;
    border-bottom: 5px solid ${color.goldButton};
  }
  @media (max-width: 1024px) {
    padding: 15px 21px;
  }
  @media (max-width: 768px) {
    padding: 15px 5px;
  }
  @media (max-width: 426px) {
    padding: 15px 30px;
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
  }
`;

export const CarouselTitle = styled.h3 `
  font-size: 24px;
  font-weight: 900;
  text-transform: uppercase;
  margin-bottom: 0;
`;