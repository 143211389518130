import React from 'react';
import Header from '../../Header/header'
import { WhiteBgDiv, WhiteBoxDiv, LeftContainer, RighSideContainer } from './SignUpIndex.style'
import LeftSide from '../LeftSide/Index/Index';
import RightSide from '../RightSide/Index/Index'

const SignUpIndex = () => {
    return (
        <>
            <Header />
            <div className="container">
            <WhiteBgDiv className="col col-md-12 m-auto bg-white" >
                <WhiteBoxDiv className="m-auto d-flex flex-wrap text-center" >
                    <LeftContainer className="col-6 col-md d-flex flex-column align-items-between">
                        <LeftSide />
                    </LeftContainer>
                    <RighSideContainer className="col-6 col-md form-side text-start">
                        <RightSide />
                    </RighSideContainer>
                </WhiteBoxDiv>
            </WhiteBgDiv>

            </div>
        </>
    )
}

export default SignUpIndex
